import { Button, Col, Form, Input, InputNumber, Modal, Row } from "antd";
import { FormComponentProps } from "antd/lib/form";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import "./SaveAndFinishModal.scss";
import { Item } from "../../../../generated/axios";
import { useSelector } from "react-redux";
import { maxQuantitySelector } from "../../../../shared/store/settings/selectors";

interface IProps extends FormComponentProps {
  item: Partial<Item>;
  saveAndFinish: (itemAttributes: Partial<Item>) => void;
  unitDescription?: string;
}

const SaveAndFinishModal: React.FC<IProps> = ({
  form,
  item,
  saveAndFinish,
  unitDescription,
}) => {
  const maxQuantity = useSelector(maxQuantitySelector);
  const { customerReference = "", position = "", quantity = 1 } = item;
  const { getFieldDecorator, validateFields } = form;
  const [visible, setVisible] = useState(false);

  const onOk = useCallback(() => {
    validateFields((err, values) => {
      if (!err) {
        saveAndFinish({
          quantity: values.quantity,
          position: values.position,
          customerReference: values.customerReference,
        });
        setVisible(false);
      }
    });
  }, [saveAndFinish, setVisible, validateFields]);

  return (
    <>
      <Button
        type="primary"
        className="height--40 margin-bottom--20"
        onClick={() => setVisible(true)}
      >
        <FormattedMessage
          id="save and finish"
          defaultMessage="Save and finish"
        />
      </Button>

      <Modal
        title={
          <FormattedMessage
            id="save and finish"
            defaultMessage="Save and finish"
          />
        }
        visible={visible}
        onOk={onOk}
        onCancel={() => setVisible(false)}
      >
        <Form className={"saveAndFinishModal"}>
          <FormattedMessage
            id="save and finish modal desc"
            defaultMessage="Please, do you want to save the Unit: {unitId} as your best choice?"
            values={{ unitId: unitDescription }}
          />
          <Row gutter={16} style={{ marginTop: "1em" }}>
            <Col span={8}>
              <Form.Item
                required={true}
                className="saveAndFinishModal__label"
                label={
                  <FormattedMessage
                    id="modalItemSaveAndFinish.quantity"
                    defaultMessage="Quantity"
                  />
                }
              >
                {getFieldDecorator("quantity", {
                  initialValue: quantity,
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="quantity.required"
                          defaultMessage="A value is required"
                        />
                      ),
                    },
                    {
                      type: "number",
                      min: 1,
                      message: (
                        <FormattedMessage
                          id="quantity.minQuantity"
                          defaultMessage="Min. {min}"
                          values={{ min: 1 }}
                        />
                      ),
                    },
                    {
                      type: "number",
                      max: maxQuantity,
                      message: (
                        <FormattedMessage
                          id="quantity.maxQuantity"
                          defaultMessage="Max. {max}"
                          values={{ max: maxQuantity }}
                        />
                      ),
                    },
                  ],
                })(
                  <InputNumber
                    min={1}
                    max={maxQuantity}
                    step={1}
                    precision={0}
                    required
                  />
                )}
              </Form.Item>

              <Form.Item
                className="saveAndFinishModal__label"
                label={
                  <FormattedMessage
                    id="modalItemSaveAndFinish.position"
                    defaultMessage="Position"
                  />
                }
              >
                {getFieldDecorator("position", { initialValue: position })(
                  <Input maxLength={10} />
                )}
              </Form.Item>

              <Form.Item
                className="saveAndFinishModal__label"
                label={
                  <FormattedMessage
                    id="modalItemSaveAndFinish.customerReference"
                    defaultMessage="Ref. customer"
                  />
                }
              >
                {getFieldDecorator("customerReference", {
                  initialValue: customerReference,
                })(<Input maxLength={12} />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Form.create<IProps>()(SaveAndFinishModal);
