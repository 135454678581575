import { Col, Row } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ApplicationTypeFieldsForm } from "./ApplicationTypeFieldsForm";
import { SelectApplicationType } from "./SelectApplicationType";

interface Props {
  getFieldValue: WrappedFormUtils["getFieldValue"];
  parentExcludedFields?: Record<string, string[]>;
  setFieldsValue: WrappedFormUtils["setFieldsValue"];
}

export function TechnicalSpecificationFields({
  getFieldValue,
  parentExcludedFields,
  setFieldsValue,
}: Readonly<Props>) {
  const [currentApplicationType, setCurrentApplicationType] = React.useState<
    number | undefined
  >(undefined);

  return (
    <>
      <Row>
        <Col
          className="customize-modal__section-title margin-top--20 margin-bottom--20"
          span={24}
        >
          <FormattedMessage
            id={"Fields to display"}
            defaultMessage="Fields to display"
          />
        </Col>
      </Row>

      <Col span={24}>
        <SelectApplicationType setCurrent={setCurrentApplicationType} />
        {currentApplicationType && (
          <ApplicationTypeFieldsForm
            key={currentApplicationType}
            currentApplicationType={currentApplicationType}
            getFieldValue={getFieldValue}
            parentExcludedFields={
              parentExcludedFields?.[currentApplicationType] ?? []
            }
            setFieldsValue={setFieldsValue}
          />
        )}
      </Col>
    </>
  );
}
