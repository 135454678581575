import { Col, Layout, Row } from "antd";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import logoPlair from "../../assets/images/Login/plair-logo.svg";
import luveGroup from "../../assets/luve-group.svg";
import { HOME, LOGIN } from "../../config/paths";
import ProjectTitle from "../../shared/components/ProjectTitle";
import Authenticated from "../auth/Authenticated";
import IconsMenu from "./IconsMenu";
import { LogoLuveHeader } from "./LogoLuveHeader";
import PlatformInfo from "./PlatformInfo";
import "./style.scss";
import UserMenu from "./UserMenu";

interface IProps {
  id: string;
}

const { Header, Footer } = Layout;

const AppFooter = React.memo(() => (
  <Footer className="footer">
    <Row type="flex" justify="start" align="middle" style={{ height: "100%" }}>
      <Col span={5} style={{ paddingLeft: "23px" }}>
        LU-VE GROUP 2018 © PLAIR
      </Col>
      <Col span={18} style={{ paddingRight: "23px", textAlign: "right" }}>
        <PlatformInfo />
        <img src={luveGroup} alt="" className="logoFooter" />
      </Col>
      <Col />
    </Row>
  </Footer>
));

const AppHeader = React.memo(() => (
  <Header className="headerWrapper">
    <div className="headerWrapper__logo" id="logo">
      <Link to={HOME}>
        <ReactSVG src={logoPlair} />
      </Link>
    </div>
    <div className="headerWrapper__title">
      <ProjectTitle />
    </div>
    <IconsMenu />
    <UserMenu />
    <LogoLuveHeader />
  </Header>
));

const AppLayout: FC<IProps> = (props) => (
  <Authenticated orLogin={LOGIN}>
    <Layout className="root-layout" id={props.id}>
      <AppHeader />
      {/* page content - from Route */}
      {props.children}
      <AppFooter />
    </Layout>
  </Authenticated>
);

export default AppLayout;
