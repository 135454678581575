import { Row } from "antd";
import React from "react";
import { ConfiguratorSection } from "../../../../types/configurator";
import { CapabilityCheck, capabilityMap } from "../../../capability";
import AlertMessage from "../AlertMessage";
import BackButton from "../BackButton";
import CalculateButton from "../CalculateButton";
import PriceListInfo from "../PriceListInfo";
import "./configuratorSubNav.scss";

interface IProps {
  section?: ConfiguratorSection;
}

export const ConfiguratorSubNav: React.FC<IProps> = ({ section }) => {
  return (
    <Row className="sub-nav margin-top--8 margin-bottom--8">
      <div className="sub-nav__col--left">
        <BackButton />
      </div>
      <div className="sub-nav__col--center">
        {section === ConfiguratorSection.THERMAL && (
          <CapabilityCheck showIfHasNot={capabilityMap.ITEM_READONLY}>
            <AlertMessage />
          </CapabilityCheck>
        )}
        {section === ConfiguratorSection.RESULTS && (
          <CapabilityCheck showIfHas={capabilityMap.USER_SHOW_PRICES}>
            <PriceListInfo />
          </CapabilityCheck>
        )}
      </div>
      <CapabilityCheck showIfHasNot={capabilityMap.ITEM_READONLY}>
        <div className="sub-nav__col--right">
          <CalculateButton />
        </div>
      </CapabilityCheck>
    </Row>
  );
};
