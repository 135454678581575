import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Switch,
  Tag,
  Upload,
} from "antd";
import { FormComponentProps } from "antd/lib/form";
import { RcCustomRequestOptions } from "antd/lib/upload/interface";
import React, { FormEvent } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { IStore } from "shared/store/types";
import {
  Language,
  TechnicalSpecificationConfig,
  VersionLength,
} from "../../generated/axios";
import { messages } from "../../shared/lib/locales/definedMessages";
import { readBlobInBase64 } from "../../shared/lib/readBlobInBase64";
import { getLanguages } from "../../shared/store/account/actions";
import {
  getItemTechnicalSpecificationSettings,
  updateItemTechnicalSpecificationSettings,
} from "../../shared/store/item/actions";
import { messages as validationMessages } from "../../shared/validate/validationMessages";
import "../../styles/helper.scss";
import { Callback } from "../../types/Callback";
import { CapabilityCheck, capabilityMap } from "../capability";
import "./ItemTechnicalSpecification.scss";
import {
  AddressLabel,
  CertificateLabel,
  ColumnHeaders,
  DiscountLabel,
  DrawingLabel,
  FirstNameLabel,
  ForcedPdfLabel,
  IncludeItemTitleLabel,
  IncludeNotesLabel,
  LuveLogoLabel,
  OtherLogoLabel,
  PercentageLabel,
  PriceLabel,
  SecondNameLabel,
  ShowDiscountValuesLabel,
  ShowSoundPowerLevel,
  SpareLabel,
  UploadLabel,
} from "./staticParts";

const RadioGroup = Radio.Group;
const Option = Select.Option;

interface OwnProps {
  itemId?: number;
}

interface ReduxProps {
  capabilities?: string[];
  config: TechnicalSpecificationConfig;
  languages: Language[];
}

interface DispatchProps {
  getItemTechnicalSpecificationSettings: (itemId: number) => void;
  getLanguages: Callback;
  updateItemTechnicalSpecificationSettings: (
    itemId: number,
    technicalSpecificationConfig: TechnicalSpecificationConfig
  ) => void;
}

interface IProps
  extends OwnProps,
    ReduxProps,
    DispatchProps,
    FormComponentProps,
    InjectedIntlProps {}

interface IState {
  alfaLavalLogo?: boolean;
  discountedPrice?: boolean;
  price?: boolean;
  logoFileName?: string;
}

class ItemTechnicalSpecification extends React.Component<IProps, IState> {
  private readonly itemId: number;

  constructor(props: IProps) {
    super(props);
    this.itemId = this.props.itemId ?? 0;
    this.state = {};
  }

  componentDidMount() {
    // get form config - API /items/{itemId}/technical-specifications/settings
    this.props.getItemTechnicalSpecificationSettings(this.itemId);
    this.props.getLanguages();
  }

  onSubmit = (e: FormEvent) => {
    e.preventDefault();
    this.props.form.validateFields((errors, values) => {
      if (!errors) {
        this.props.updateItemTechnicalSpecificationSettings(
          this.itemId,
          values
        );
      }
    });
  };

  setFlag = (flagName: string) => (value: boolean) => {
    const {
      form: { validateFields },
    } = this.props;
    let fieldToValidate = "";
    if (flagName === "price" || flagName === "discountedPrice") {
      fieldToValidate = "percentage";
    } else if (flagName === "alfaLavalLogo") {
      fieldToValidate = "logoFileName";
    }
    if (fieldToValidate) {
      setTimeout(() => validateFields([fieldToValidate], { force: true }), 0);
    }
    this.setState(() => ({ [flagName]: value }));
  };

  customRequest = ({ file /*, onSuccess */ }: RcCustomRequestOptions) => {
    readBlobInBase64(file)
      .then((uploadLogo: string) => {
        const logoFileName = file.name || undefined;
        this.props.form.setFieldsValue({ uploadLogo, logoFileName });
        this.setState(() => ({ logoFileName }));
      })
      .catch(console.error);
  };

  removeLogoFileName = () => {
    const {
      form: { setFieldsValue },
    } = this.props;
    const logoFileName = "";
    const uploadLogo = "";
    setFieldsValue({ uploadLogo, logoFileName });
    this.setState(() => ({ logoFileName }));
  };

  render() {
    const { capabilities, config, intl, languages } = this.props;
    const { getFieldDecorator } = this.props.form; // this.props.form is been injected by Form.create()

    const {
      discountedPrice = config?.discountedPrice,
      alfaLavalLogo = config?.alfaLavalLogo,
      price = config?.price,
      logoFileName = config?.logoFileName,
    } = this.state;

    // show/hide "Version Length" base on the capability rule
    const showLongVersion =
      !!capabilities &&
      capabilities.includes(capabilityMap.SETTINGS_TECH_SPEC_SHOW_EXT_VERSION);

    if (config === undefined) return null;

    return (
      <div className="customize">
        <Row type="flex">
          <Col span={24} className="customize__content">
            <Row className="customize__header customize__row">
              <ColumnHeaders showLongVersion={showLongVersion} />
            </Row>

            <Row className="customize__row">
              <CapabilityCheck
                showIfHas={capabilityMap.SETTINGS_TECH_SPEC_SHOW_EXT_VERSION}
              >
                <Col span={showLongVersion ? 4 : 6} className="customize__cell">
                  <Row>
                    <Col>
                      <Form.Item>
                        {getFieldDecorator("versionLength", {
                          initialValue: config?.versionLength,
                        })(
                          <RadioGroup className="customize__radio">
                            <Radio value={VersionLength.LONG}>
                              <FormattedMessage
                                id="long"
                                defaultMessage="Extended"
                              />
                            </Radio>
                            <Radio value={VersionLength.SHORT}>
                              <FormattedMessage
                                id="short"
                                defaultMessage="Short"
                              />
                            </Radio>
                          </RadioGroup>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </CapabilityCheck>
              {/* "VERSION LENGTH" COL END */}

              {/* "LANGUAGE" COL START */}
              <Col span={showLongVersion ? 4 : 6} className="customize__cell">
                <Row>
                  <Col>
                    <div className="customize__label">
                      <FormattedMessage id="output" defaultMessage="OUTPUT" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item>
                      {getFieldDecorator("language", {
                        initialValue: config?.language,
                      })(
                        <Select className="customize__select">
                          {languages?.map((language) => (
                            <Option value={language.key} key={language.id}>
                              {language.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {/* "LANGUAGE" COL END */}

              {/* "CLIENT DATA" COL START */}
              <Col span={showLongVersion ? 5 : 6} className="customize__cell">
                <Row>
                  <Col>
                    <FirstNameLabel />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item>
                      {getFieldDecorator("firstName", {
                        initialValue: config?.firstName,
                      })(
                        <Input
                          placeholder={intl.formatMessage(
                            messages.inputInsertNamePlaceholder
                          )}
                          className="customize__input"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SecondNameLabel />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item>
                      {getFieldDecorator("lastName", {
                        initialValue: config?.lastName,
                      })(
                        <Input
                          placeholder={intl.formatMessage(
                            messages.inputInsertLastNamePlaceholder
                          )}
                          className="customize__input"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <AddressLabel />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item>
                      {getFieldDecorator("address", {
                        initialValue: config?.address,
                      })(
                        <Input
                          placeholder={intl.formatMessage(
                            messages.inputInsertAddressPlaceholder
                          )}
                          className="customize__input"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {/* "CLIENT DATA" COL END */}

              {/* "LOGO" COL START */}
              <Col span={showLongVersion ? 5 : 6} className="customize__cell">
                <CapabilityCheck
                  showIfHas={capabilityMap.SETTINGS_TECH_SPEC_CUSTOMIZE_LOGO}
                >
                  <Row>
                    <Col span={18}>
                      <LuveLogoLabel />
                    </Col>
                    <Col span={6}>
                      <Form.Item>
                        {getFieldDecorator("alfaLavalLogo", {
                          initialValue: config?.alfaLavalLogo,
                          valuePropName: "checked",
                        })(<Switch onChange={this.setFlag("alfaLavalLogo")} />)}
                      </Form.Item>
                    </Col>
                  </Row>

                  {logoFileName ? (
                    <Row>
                      <Col>
                        <OtherLogoLabel />
                      </Col>
                    </Row>
                  ) : null}
                  <Row>
                    <Form.Item>
                      {logoFileName ? (
                        <Tag
                          closable
                          onClose={this.removeLogoFileName}
                          className="customize__file-list"
                        >
                          <div className="limit-text-tag ellipsis">
                            {logoFileName}
                          </div>
                        </Tag>
                      ) : (
                        intl.formatMessage(messages.noOtherLogo)
                      )}
                    </Form.Item>
                  </Row>

                  <Row>
                    <Col>
                      <UploadLabel />
                    </Col>
                  </Row>
                  <Row className="text-align--right">
                    <Col>
                      <Upload
                        className="customize__upload"
                        customRequest={this.customRequest}
                        accept=".jpg,.jpeg,.png"
                        disabled={alfaLavalLogo}
                      >
                        <Button
                          type="primary"
                          ghost
                          className="customize__upload-button margin-top--10"
                          disabled={alfaLavalLogo}
                        >
                          Upload file
                        </Button>
                      </Upload>
                      <Form.Item>
                        {getFieldDecorator("logoFileName", {
                          initialValue: config?.logoFileName,
                          rules: [
                            {
                              required: !alfaLavalLogo,
                              message: (
                                <FormattedMessage
                                  id="logo error"
                                  defaultMessage="A logo is required"
                                />
                              ),
                            },
                          ],
                        })(<div />)}
                      </Form.Item>
                      <Form.Item>
                        {getFieldDecorator("uploadLogo", {
                          initialValue: config?.uploadLogo,
                        })(<input type="hidden" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </CapabilityCheck>
                <CapabilityCheck
                  showIfHas={capabilityMap.ITEM_HIDE_EUROVENT_LOGO}
                >
                  <Row className="margin-top--20">
                    <Col span={18}>
                      <CertificateLabel />
                    </Col>
                    <Col span={6}>
                      <Form.Item>
                        {getFieldDecorator("certificateLogo", {
                          initialValue: config?.certificateLogo,
                          valuePropName: "checked",
                        })(<Switch />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </CapabilityCheck>
              </Col>
              {/* "LOGO" COL END */}
              {/* "OTHER INFORMATION" COL START */}
              <Col span={6} className="customize__cell">
                <CapabilityCheck showIfHas={capabilityMap.ITEM_EXP_TECH_SPEC}>
                  <CapabilityCheck
                    showIfHas={capabilityMap.ITEM_EXP_TECH_SPEC_WORD}
                  >
                    {/* "FORCED PDF" COL START */}
                    <Row>
                      <Col span={18}>
                        <ForcedPdfLabel />
                      </Col>
                      <Col span={6}>
                        <Form.Item>
                          {getFieldDecorator("forcedPdf", {
                            initialValue: config?.forcedPdf,
                            valuePropName: "checked",
                          })(<Switch />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* "FORCED PDF" COL END */}
                  </CapabilityCheck>
                </CapabilityCheck>
                <Row className="margin-top--20">
                  <Col span={18}>
                    <IncludeItemTitleLabel />
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      {getFieldDecorator("includeItemTitle", {
                        initialValue: config?.includeItemTitle,
                        valuePropName: "checked",
                      })(<Switch />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="margin-top--20">
                  <Col span={18}>
                    <DrawingLabel />
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      {getFieldDecorator("drawing", {
                        initialValue: config?.drawing,
                        valuePropName: "checked",
                      })(<Switch />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="margin-top--20">
                  <Col span={18}>
                    <IncludeNotesLabel />
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      {getFieldDecorator("displayItemNote", {
                        initialValue: config?.displayItemNote,
                        valuePropName: "checked",
                      })(<Switch />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="margin-top--20">
                  <Col span={18}>
                    <SpareLabel />
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      {getFieldDecorator("spareParts", {
                        initialValue: config?.spareParts,
                        valuePropName: "checked",
                      })(<Switch />)}
                    </Form.Item>
                  </Col>
                </Row>
                <CapabilityCheck showIfHas={capabilityMap.USER_SHOW_PRICES}>
                  <Row className="margin-top--20">
                    <Col span={18}>
                      <PriceLabel />
                    </Col>
                    <Col span={6}>
                      <Form.Item>
                        {getFieldDecorator("price", {
                          initialValue: config?.price,
                          valuePropName: "checked",
                        })(<Switch onChange={this.setFlag("price")} />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="margin-top--20">
                    <Col span={18}>
                      <DiscountLabel />
                    </Col>
                    <Col span={6}>
                      <Form.Item>
                        {getFieldDecorator("discountedPrice", {
                          initialValue: config?.discountedPrice,
                          valuePropName: "checked",
                        })(
                          <Switch
                            onChange={this.setFlag("discountedPrice")}
                            disabled={!price}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <PercentageLabel />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item>
                        {getFieldDecorator("percentage", {
                          rules: [
                            {
                              required: price && discountedPrice,
                              message: intl.formatMessage(
                                validationMessages["validation.required"],
                                {
                                  "1": intl.formatMessage({
                                    id: "percentage",
                                    defaultMessage: "Percentage",
                                  }),
                                }
                              ),
                            },
                            {
                              pattern: /^([1-9]?\d)?(\.\d{1,2})?$/,
                              message: intl.formatMessage(
                                validationMessages["validation.range"],
                                {
                                  "1": "0",
                                  "2": "99.99",
                                }
                              ),
                            },
                          ],
                          initialValue: config?.percentage,
                        })(
                          <InputNumber
                            size="large"
                            placeholder={intl.formatMessage(
                              messages.inputNumberExamplePercPlaceholder
                            )}
                            min={0}
                            max={99}
                            precision={2}
                            disabled={!price || !discountedPrice}
                            className="customize__input-number"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="margin-top--20">
                    <Col span={18}>
                      <ShowDiscountValuesLabel />
                    </Col>
                    <Col span={6}>
                      <Form.Item>
                        {getFieldDecorator("showOnlyDiscountedPrice", {
                          initialValue: config?.showOnlyDiscountedPrice,
                          valuePropName: "checked",
                        })(
                          <Switch
                            onChange={this.setFlag("showOnlyDiscountedPrice")}
                            disabled={!price || !discountedPrice}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </CapabilityCheck>
                <Row className="margin-top--20">
                  <Col span={18}>
                    <ShowSoundPowerLevel />
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      {getFieldDecorator("showSoundPowerLevel", {
                        initialValue: config?.showSoundPowerLevel,
                        valuePropName: "checked",
                      })(
                        <Switch
                          onChange={this.setFlag("showSoundPowerLevel")}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {/* "OTHER INFORMATION" COL END */}
            </Row>
            <Row className="margin-top--20" type="flex" justify="end">
              <Col>
                <Button
                  type="primary"
                  className="customize__print"
                  icon="printer"
                  block
                  onClick={this.onSubmit}
                >
                  <FormattedMessage id="save" defaultMessage="Save" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: IStore): ReduxProps => ({
  capabilities: state.capabilities.list,
  config: state.item.config, // Form initial values
  languages: state.account.languages,
});

const mapDispatchToProps = {
  getItemTechnicalSpecificationSettings,
  getLanguages,
  updateItemTechnicalSpecificationSettings,
};

const WithIntl = injectIntl<IProps>(ItemTechnicalSpecification);

const WithForm = Form.create<
  OwnProps & ReduxProps & DispatchProps & FormComponentProps
>()(WithIntl);

export default connect(mapStateToProps, mapDispatchToProps)(WithForm);
