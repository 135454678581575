import { AxiosResponse } from "axios";
import { get } from "lodash";
import React, { createRef } from "react";
import { connect } from "react-redux";
import {
  BaseField,
  Item,
  OptionLabels,
  TechnicalDocumentation,
} from "../../../../generated/axios";
import { optionLabelsSelector } from "../../../../shared/store/configurator/selectors";
import {
  getItemProject,
  guidedSelling,
  updateItemAttributes,
} from "../../../../shared/store/item/actions";
import { ItemResultExt, ItemState } from "../../../../shared/store/item/types";
import { saveAndFinish } from "../../../../shared/store/items/actions";
import { quoteItemsAndGuidedSelling } from "../../../../shared/store/project/actions";
import { IStore } from "../../../../shared/store/types";
import {
  getConversions,
  IConvert,
} from "../../../../shared/systemOfMeasurement/conversionFunctions";
import { convertCalculationValues } from "../../../../shared/systemOfMeasurement/convertCalculationValues";
import { convertLabels } from "../../../../shared/systemOfMeasurement/convertLabel";
import "../../../../styles/helper.scss";
import { Callback } from "../../../../types/Callback";
import { CapabilityCheck, capabilityMap } from "../../../capability";
import AdditionalDetails from "./AdditionalDetails";
import AdditionalDetailsButton from "./AdditionalDetailsButton";
import DescriptionRow from "./DescriptionRow";
import Details from "./Details";
import "./itemDetails.scss";
import { filterResults } from "./lib";
import SaveAndFinishModal from "./SaveAndFinishModal";

export interface OwnProps {
  additionalResultFieldConfig: TechnicalDocumentation[];
  item: ItemState;
  itemId: number;
  itemResult?: ItemResultExt;
  optionsPricesAreVisible: boolean;
  pricesAreVisible: boolean;
  resultFieldConfig: BaseField[];
  scrollToBottom: boolean;
  unitId: string | undefined;
}

interface StateToProps {
  chosenMetricSystem: string;
  defaultMetricSystem: string;
  itemResult?: ItemResultExt;
  optionLabels: OptionLabels;
  resultFieldConfig: BaseField[];
}

interface DispatchProps {
  getItemProject: (itemId: number) => void;
  guidedSelling: (itemId: number, unitId: string) => Promise<void>;
  quoteItemsAndGuidedSelling: (
    itemIds: number[],
    unitId?: string,
    okCallback?: Callback
  ) => Promise<void | AxiosResponse<string>>;
  saveAndFinish: (itemId: number, unitId: string, callback?: Callback) => void;
  updateItemAttributes: (id: number, itemAttributes: Partial<Item>) => void;
}

export interface Props extends OwnProps, StateToProps, DispatchProps {}

export interface Detail {
  fieldId: string;
  label: string;
  value: string | number;
}

interface State {
  errorImage: boolean;
  scroll: boolean;
  viewAdditionalDetails: boolean;
}

class ItemDetails extends React.Component<Props, State> {
  public state: State = {
    errorImage: false,
    scroll: false,
    viewAdditionalDetails: false,
  };

  private readonly itemDetailRef = createRef<HTMLDivElement>();

  saveAndFinish = (callback?: Callback) => {
    if (!this.props.unitId) {
      console.error("UnitId is not defined");
      return;
    }
    this.props.saveAndFinish(this.props.itemId, this.props.unitId, callback);
  };

  handleModalOk = (itemAttributes: Partial<Item>) => {
    if (!this.props.unitId) {
      console.error("UnitId is not defined");
      return;
    }
    this.props.saveAndFinish(this.props.itemId, this.props.unitId);
    this.props.updateItemAttributes(this.props.itemId, itemAttributes);
  };

  // detail image not available --> set false value boolean var into the state --> re-renders with Ant <Empty> component with "No image" text
  private readonly handleImageError = () => {
    this.setState({ errorImage: true });
  };

  // case when ItemDetails is already visible from before iteration
  // and another TableResults' "View" button get clicked (from second iteration and on)
  componentDidUpdate() {
    if (this.props.scrollToBottom) {
      this.scrollBottom();
    }
  }

  scrollBottom = () => {
    this.itemDetailRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  toggleAdditionalDetails = () => {
    this.setState((prevState) => ({
      viewAdditionalDetails: !prevState.viewAdditionalDetails,
    }));
  };

  guidedSelling = () => {
    if (!this.props.unitId) {
      console.error("UnitId is not defined");
      return Promise.resolve();
    }
    return this.props.guidedSelling(this.props.itemId, this.props.unitId);
  };

  /**
   * Incapsulate quoteItemsAndGuidedSelling function
   * to get "unitId", that is available here
   * and to preserve quoteItems signature in <DescriptionRow>
   */
  quoteItems = (itemIds: number[], okCallback?: Callback) => {
    return this.props.quoteItemsAndGuidedSelling(
      itemIds,
      this.props.unitId,
      okCallback
    );
  };

  render(): React.ReactNode {
    const {
      additionalResultFieldConfig,
      chosenMetricSystem,
      defaultMetricSystem,
      getItemProject,
      item,
      itemResult,
      optionLabels,
      optionsPricesAreVisible,
      pricesAreVisible,
      resultFieldConfig,
      unitId,
    } = this.props;
    const { waitingPrices } = item;
    const { errorImage, viewAdditionalDetails } = this.state;

    if (!itemResult) {
      return null;
    }

    const values = itemResult.calculationValues ?? {};
    const details = filterResults(values, resultFieldConfig);
    const unitDescription = itemResult.calculationValues
      ? itemResult.calculationValues.description
      : unitId;

    return (
      <>
        <div className="item">
          <DescriptionRow
            description={get(itemResult, "calculationValues.description", "")}
            getItemProject={getItemProject}
            guidedSelling={this.guidedSelling}
            item={item}
            quoteItems={this.quoteItems}
            saveAndFinish={this.saveAndFinish}
            unitId={unitId}
          >
            <CapabilityCheck showIfHasNot={capabilityMap.ITEM_READONLY}>
              <SaveAndFinishModal
                item={item}
                saveAndFinish={this.handleModalOk}
                unitDescription={unitDescription}
              />
            </CapabilityCheck>
          </DescriptionRow>

          <Details
            chosenMetricSystem={chosenMetricSystem}
            data={values}
            defaultMetricSystem={defaultMetricSystem}
            details={details}
            errorImage={errorImage}
            handleImageError={this.handleImageError}
            itemResult={itemResult}
            optionLabels={optionLabels}
            optionsPricesAreVisible={optionsPricesAreVisible}
            pricesAreVisible={pricesAreVisible}
            resultFieldConfig={resultFieldConfig}
            waitingPrices={waitingPrices}
          />

          <CapabilityCheck
            showIfHas={capabilityMap.ITEM_SHOW_ADDITIONAL_DETAILS}
          >
            <AdditionalDetailsButton
              handler={this.toggleAdditionalDetails.bind(this)}
            />
          </CapabilityCheck>

          {viewAdditionalDetails && (
            <AdditionalDetails
              additionalDetails={additionalResultFieldConfig}
            />
          )}
        </div>

        {/* the div reference to be scrolled to when rendered */}
        <div ref={this.itemDetailRef}>&nbsp;</div>
      </>
    );
  }
}

const mapStateToProps = (state: IStore, props: OwnProps): StateToProps => {
  const { chosenMetricSystem, conversions, defaultMetricSystem } =
    state.settings;

  const convertedFields: Record<string, IConvert> = getConversions(
    props.resultFieldConfig,
    conversions,
    chosenMetricSystem,
    defaultMetricSystem
  );

  let resultFieldConfig = props.resultFieldConfig;
  let itemResult = props.itemResult;

  if (Object.keys(convertedFields).length) {
    resultFieldConfig = convertLabels(props.resultFieldConfig, convertedFields);

    itemResult = convertCalculationValues(
      props.itemResult ?? {},
      convertedFields
    );
  }

  const optionLabels = optionLabelsSelector(state);

  return {
    chosenMetricSystem,
    defaultMetricSystem,
    itemResult,
    optionLabels,
    resultFieldConfig,
  };
};

export default connect(mapStateToProps, {
  guidedSelling,
  quoteItemsAndGuidedSelling,
  saveAndFinish,
  updateItemAttributes,
  getItemProject,
})(ItemDetails);
