import { Button, Tooltip } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { DutyModeContext } from "../../ConfiguratorRouter";
import classNames from "classnames";

interface IProps {
  className?: string;
  handleLock: (lock: boolean, unitId?: string) => void;
  handleRate: (unitId?: string) => void;
  handleView: (unitId?: string) => void;
  lockChanging?: boolean;
  locked?: boolean;
  unitId?: string;
}

export default class TableCellButtonsList extends React.Component<IProps> {
  lock = (): void => {
    const { unitId, handleLock } = this.props;
    if (unitId) handleLock(true, unitId);
  };

  unlock = (): void => {
    const { unitId, handleLock } = this.props;
    if (unitId) handleLock(false, unitId);
  };

  view = (): void => {
    const { unitId, handleView } = this.props;
    handleView(unitId);
  };

  rateUnit = () => {
    const { unitId, handleRate } = this.props;
    handleRate(unitId);
  };

  render(): React.ReactNode {
    const { className, locked, lockChanging } = this.props;
    return (
      <div
        className={classNames(
          "table-cell-buttons icon-buttons-list",
          className
        )}
      >
        <Tooltip
          overlayClassName="no-arrow"
          title={<FormattedMessage id="view" defaultMessage="View" />}
        >
          <Button
            type="link"
            className="icon-button"
            onClick={this.view}
            icon="eye"
          />
        </Tooltip>

        <DutyModeContext.Consumer>
          {(viewRatingButton) =>
            viewRatingButton && (
              <Tooltip
                overlayClassName="no-arrow"
                title={<FormattedMessage id="rating" defaultMessage="Rating" />}
              >
                <Button
                  type="link"
                  className="icon-button"
                  onClick={this.rateUnit}
                  icon="dashboard"
                />
              </Tooltip>
            )
          }
        </DutyModeContext.Consumer>

        {locked ? (
          <Tooltip
            overlayClassName="no-arrow"
            title={<FormattedMessage id="unlock" defaultMessage="Unlock" />}
          >
            <Button
              type="link"
              className="icon-button highlighted"
              onClick={this.unlock}
              icon="lock"
              disabled={lockChanging}
            />
          </Tooltip>
        ) : (
          <Tooltip
            overlayClassName="no-arrow"
            title={<FormattedMessage id="lock" defaultMessage="Lock" />}
          >
            <Button
              type="link"
              className="icon-button"
              onClick={this.lock}
              icon="unlock"
              disabled={lockChanging}
            />
          </Tooltip>
        )}
      </div>
    );
  }
}
