import { Icon, Tooltip } from "antd";
import React from "react";
import { ApplicationType } from "../../../../generated/axios";

interface Props {
  applicationType?: ApplicationType;
}

export function ApplicationTypeSectionTitle({
  applicationType,
}: Readonly<Props>) {
  return (
    <h4>
      {applicationType?.name}{" "}
      <Tooltip
        overlayClassName="no-arrow"
        title={
          <div
            dangerouslySetInnerHTML={{
              __html: applicationType?.description ?? "",
            }}
          />
        }
        mouseEnterDelay={0.8}
      >
        <Icon type="info-circle" />
      </Tooltip>
    </h4>
  );
}
