// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Alfalaval APS Backend API
 * API for APS frontend
 *
 * OpenAPI spec version: 0.31.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import axios, { AxiosPromise } from 'axios';

const BASE_PATH = "http://localhost:3000/api/public".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface ApplicationType
 */
export interface ApplicationType {
    /**
     * The id of the application type
     * @type {number}
     * @memberof ApplicationType
     */
    id?: number;
    /**
     * The name of the application type
     * @type {string}
     * @memberof ApplicationType
     */
    name?: string;
    /**
     * The description of the application type
     * @type {string}
     * @memberof ApplicationType
     */
    description?: string;
    /**
     * the abbreviation for this application type
     * @type {string}
     * @memberof ApplicationType
     */
    abbreviation?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationType
     */
    type?: ApplicationType.TypeEnum;
    /**
     * The application type icon
     * @type {string}
     * @memberof ApplicationType
     */
    icon?: string;
}

/**
 * @export
 * @namespace ApplicationType
 */
export namespace ApplicationType {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        INDOOR = 'INDOOR',
        OUTDOOR = 'OUTDOOR'
    }
}

/**
 * 
 * @export
 * @interface AuthenticationRequest
 */
export interface AuthenticationRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    password: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationRequest
     */
    rememberMe?: boolean;
}

/**
 * 
 * @export
 * @interface AuthenticationResponse
 */
export interface AuthenticationResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    idToken?: string;
}

/**
 * with translated label, original and converted unit of measure
 * @export
 * @interface BaseField
 */
export interface BaseField {
    /**
     * 
     * @type {string}
     * @memberof BaseField
     */
    fieldId: string;
    /**
     * the transalated label
     * @type {string}
     * @memberof BaseField
     */
    label?: string;
    /**
     * 
     * @type {{ [key: string]: UnitOfMeasure; }}
     * @memberof BaseField
     */
    unitOfMeasures?: { [key: string]: UnitOfMeasure; };
}

/**
 * 
 * @export
 * @interface BoundsConfig
 */
export interface BoundsConfig {
    /**
     * Valori forzati a default
     * @type {{ [key: string]: boolean; }}
     * @memberof BoundsConfig
     */
    forcedDefaults?: { [key: string]: boolean; };
    /**
     * Tolleranza inferiore capacità (unit %/100)
     * @type {{ [key: string]: number; }}
     * @memberof BoundsConfig
     */
    minimumCapacityTolerance?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: UnitOfMeasure; }}
     * @memberof BoundsConfig
     */
    minimumCapacityToleranceUnits?: { [key: string]: UnitOfMeasure; };
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    minimumCapacityToleranceDefault?: number;
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    minimumCapacityToleranceMin?: number;
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    minimumCapacityToleranceMax?: number;
    /**
     * Gli id dell'applicationType per cui il campo è abilitato. Se non impostato il campo è sempre abilitato
     * @type {Array<number>}
     * @memberof BoundsConfig
     */
    minimumCapacityToleranceEnabled?: Array<number>;
    /**
     * Tolleranza superiore capacità (unit %/100)
     * @type {{ [key: string]: number; }}
     * @memberof BoundsConfig
     */
    maximumCapacityTolerance?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: UnitOfMeasure; }}
     * @memberof BoundsConfig
     */
    maximumCapacityToleranceUnits?: { [key: string]: UnitOfMeasure; };
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    maximumCapacityToleranceDefault?: number;
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    maximumCapacityToleranceMin?: number;
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    maximumCapacityToleranceMax?: number;
    /**
     * Gli id dell'applicationType per cui il campo è abilitato. Se non impostato il campo è sempre abilitato
     * @type {Array<number>}
     * @memberof BoundsConfig
     */
    maximumCapacityToleranceEnabled?: Array<number>;
    /**
     * Massimo numero di risultati (unit valore unitario)
     * @type {{ [key: string]: number; }}
     * @memberof BoundsConfig
     */
    maxResults?: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    maxResultsDefault?: number;
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    maxResultsMin?: number;
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    maxResultsMax?: number;
    /**
     * Gli id dell'applicationType per cui il campo è abilitato. Se non impostato il campo è sempre abilitato
     * @type {Array<number>}
     * @memberof BoundsConfig
     */
    maxResultsEnabled?: Array<number>;
    /**
     * Tolleranza sound level (unit dB(A))
     * @type {{ [key: string]: number; }}
     * @memberof BoundsConfig
     */
    soundLevelTolerance?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: UnitOfMeasure; }}
     * @memberof BoundsConfig
     */
    soundLevelToleranceUnits?: { [key: string]: UnitOfMeasure; };
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    soundLevelToleranceDefault?: number;
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    soundLevelToleranceMin?: number;
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    soundLevelToleranceMax?: number;
    /**
     * Gli id dell'applicationType per cui il campo è abilitato. Se non impostato il campo è sempre abilitato
     * @type {Array<number>}
     * @memberof BoundsConfig
     */
    soundLevelToleranceEnabled?: Array<number>;
    /**
     * Minima caduta di pressione del fluido (gas cooler a CO2, unit kPa)
     * @type {{ [key: string]: number; }}
     * @memberof BoundsConfig
     */
    minFluidPressureDrop?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: UnitOfMeasure; }}
     * @memberof BoundsConfig
     */
    minFluidPressureDropUnits?: { [key: string]: UnitOfMeasure; };
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    minFluidPressureDropDefault?: number;
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    minFluidPressureDropMin?: number;
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    minFluidPressureDropMax?: number;
    /**
     * Gli id dell'applicationType per cui il campo è abilitato. Se non impostato il campo è sempre abilitato
     * @type {Array<number>}
     * @memberof BoundsConfig
     */
    minFluidPressureDropEnabled?: Array<number>;
    /**
     * Massima caduta di pressione del fluido (gas cooler a CO2, unit kPa)
     * @type {{ [key: string]: number; }}
     * @memberof BoundsConfig
     */
    maxFluidPressureDrop?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: UnitOfMeasure; }}
     * @memberof BoundsConfig
     */
    maxFluidPressureDropUnits?: { [key: string]: UnitOfMeasure; };
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    maxFluidPressureDropDefault?: number;
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    maxFluidPressureDropMin?: number;
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    maxFluidPressureDropMax?: number;
    /**
     * Gli id dell'applicationType per cui il campo è abilitato. Se non impostato il campo è sempre abilitato
     * @type {Array<number>}
     * @memberof BoundsConfig
     */
    maxFluidPressureDropEnabled?: Array<number>;
    /**
     * Tolleranza sulla temperatura di condensazione (condenser & gas cooler, unit K)
     * @type {{ [key: string]: number; }}
     * @memberof BoundsConfig
     */
    condensingTemperatureTolerance?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: UnitOfMeasure; }}
     * @memberof BoundsConfig
     */
    condensingTemperatureToleranceUnits?: { [key: string]: UnitOfMeasure; };
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    condensingTemperatureToleranceDefault?: number;
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    condensingTemperatureToleranceMin?: number;
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    condensingTemperatureToleranceMax?: number;
    /**
     * Gli id dell'applicationType per cui il campo è abilitato. Se non impostato il campo è sempre abilitato
     * @type {Array<number>}
     * @memberof BoundsConfig
     */
    condensingTemperatureToleranceEnabled?: Array<number>;
    /**
     * Tolleranza sulla temperatura di evaporazione (condenser & gas cooler, unit K)
     * @type {{ [key: string]: number; }}
     * @memberof BoundsConfig
     */
    evaporatingTemperatureTolerance?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: UnitOfMeasure; }}
     * @memberof BoundsConfig
     */
    evaporatingTemperatureToleranceUnits?: { [key: string]: UnitOfMeasure; };
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    evaporatingTemperatureToleranceDefault?: number;
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    evaporatingTemperatureToleranceMin?: number;
    /**
     * 
     * @type {number}
     * @memberof BoundsConfig
     */
    evaporatingTemperatureToleranceMax?: number;
    /**
     * Gli id dell'applicationType per cui il campo è abilitato. Se non impostato il campo è sempre abilitato
     * @type {Array<number>}
     * @memberof BoundsConfig
     */
    evaporatingTemperatureToleranceEnabled?: Array<number>;
}

/**
 * Quote information
 * @export
 * @interface CPQ
 */
export interface CPQ {
    /**
     * Id dell'item in cpq
     * @type {string}
     * @memberof CPQ
     */
    configurationId: string;
    /**
     * 
     * @type {Quote}
     * @memberof CPQ
     */
    quote?: Quote;
}

/**
 * CPQ tables synchronization parameters
 * @export
 * @interface CPQTablesSynchronization
 */
export interface CPQTablesSynchronization {
    /**
     * tableNames to restrict synchronization to
     * @type {Array<string>}
     * @memberof CPQTablesSynchronization
     */
    tableNames?: Array<string>;
    /**
     * email for start/end notification
     * @type {string}
     * @memberof CPQTablesSynchronization
     */
    notificationEmail?: string;
    /**
     * emails for start/end notification
     * @type {Array<string>}
     * @memberof CPQTablesSynchronization
     */
    notificationEmails?: Array<string>;
    /**
     * keep working file after execution
     * @type {boolean}
     * @memberof CPQTablesSynchronization
     */
    omitCleanup?: boolean;
}

/**
 * 
 * @export
 * @interface CalculationMessage
 */
export interface CalculationMessage {
    /**
     * 
     * @type {number}
     * @memberof CalculationMessage
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CalculationMessage
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof CalculationMessage
     */
    type?: CalculationMessage.TypeEnum;
}

/**
 * @export
 * @namespace CalculationMessage
 */
export namespace CalculationMessage {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        WARNING = 'WARNING',
        ERROR = 'ERROR'
    }
}

/**
 * 
 * @export
 * @interface Campaign
 */
export interface Campaign {
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    targetUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    imageUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    duration?: number;
}

/**
 * 
 * @export
 * @interface ConversionFormula
 */
export interface ConversionFormula {
    /**
     * 
     * @type {string}
     * @memberof ConversionFormula
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversionFormula
     */
    to?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversionFormula
     */
    formula?: string;
}

/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    alpha3: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    alpha2: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    deletedDate?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DutyMode {
    DESIGN = 'DESIGN',
    RATING = 'RATING'
}

/**
 * 
 * @export
 * @interface EmailReset
 */
export interface EmailReset {
    /**
     * 
     * @type {string}
     * @memberof EmailReset
     */
    newEmail: string;
    /**
     * 
     * @type {string}
     * @memberof EmailReset
     */
    password: string;
}

/**
 * controls how a field enable state is controlled by another field
 * @export
 * @interface FieldEnable
 */
export interface FieldEnable {
    /**
     * the other field id
     * @type {string}
     * @memberof FieldEnable
     */
    fieldId?: string;
    /**
     * The value that enables the other field
     * @type {any}
     * @memberof FieldEnable
     */
    when?: any;
}

/**
 * 
 * @export
 * @interface FieldExpression
 */
export interface FieldExpression {
    /**
     * the name of the function to invoke (currently only eval is available), requires the corresponding FormField.inputType = expression
     * @type {string}
     * @memberof FieldExpression
     */
    name?: string;
    /**
     * the list of parameters passed to the validation function
     * @type {Array<any>}
     * @memberof FieldExpression
     */
    inputParameters?: Array<any>;
}

/**
 * Deprecated, use ItemAttributeOption instead
 * @export
 * @interface FieldOption
 */
export interface FieldOption {
    /**
     * Used by frontend only. Takes precedence over labelId and value (if present, use this for the label)
     * @type {string}
     * @memberof FieldOption
     */
    label?: string;
    /**
     * The option value. Can be used also as label, in case no associations is found in the field-option-labels
     * @type {any}
     * @memberof FieldOption
     */
    value?: any;
    /**
     * 
     * @type {boolean}
     * @memberof FieldOption
     */
    disabled?: boolean;
}

/**
 * 
 * @export
 * @interface FieldOptionAction
 */
export interface FieldOptionAction {
    /**
     * 
     * @type {string}
     * @memberof FieldOptionAction
     */
    fieldKey?: string;
    /**
     * 
     * @type {Array<FormAction>}
     * @memberof FieldOptionAction
     */
    fieldActions?: Array<FormAction>;
    /**
     * 
     * @type {{ [key: string]: Array<FormAction>; }}
     * @memberof FieldOptionAction
     */
    optionActions?: { [key: string]: Array<FormAction>; };
}

/**
 * 
 * @export
 * @interface FieldValidation
 */
export interface FieldValidation {
    /**
     * 
     * @type {boolean}
     * @memberof FieldValidation
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FieldValidation
     */
    autoSelection?: FieldValidation.AutoSelectionEnum;
    /**
     * 
     * @type {Array<FieldValidationRule>}
     * @memberof FieldValidation
     */
    rules?: Array<FieldValidationRule>;
}

/**
 * @export
 * @namespace FieldValidation
 */
export namespace FieldValidation {
    /**
     * @export
     * @enum {string}
     */
    export enum AutoSelectionEnum {
        NEVER = 'NEVER',
        IFREQUIRED = 'IF_REQUIRED',
        ALWAYS = 'ALWAYS'
    }
}

/**
 * 
 * @export
 * @interface FieldValidationRule
 */
export interface FieldValidationRule {
    /**
     * the name of the function to invoke
     * @type {string}
     * @memberof FieldValidationRule
     */
    name?: string;
    /**
     * the message key to lookup in case the field has a custom error message
     * @type {string}
     * @memberof FieldValidationRule
     */
    messageKey?: string;
    /**
     * the list of parameters passed to the validation function
     * @type {Array<any>}
     * @memberof FieldValidationRule
     */
    inputParameters?: Array<any>;
}

/**
 * determines a form field visibility, calculated executing the `name` function with `inputParameters`
 * @export
 * @interface FieldVisibility
 */
export interface FieldVisibility {
    /**
     * the name of the function to invoke
     * @type {string}
     * @memberof FieldVisibility
     */
    name?: string;
    /**
     * the list of parameters passed to the visibility function
     * @type {Array<any>}
     * @memberof FieldVisibility
     */
    inputParameters?: Array<any>;
}

/**
 * 
 * @export
 * @interface FormAction
 */
export interface FormAction {
    /**
     * 
     * @type {string}
     * @memberof FormAction
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FormAction
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof FormAction
     */
    action?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof FormAction
     */
    params?: { [key: string]: string; };
}

/**
 * the form configuration
 * @export
 * @interface FormConfig
 */
export interface FormConfig extends Array<FormSection> {
}

/**
 * Extends ItemField
 * @export
 * @interface FormField
 */
export interface FormField extends BaseField {
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    description?: string;
    /**
     * js type? validation type?
     * @type {string}
     * @memberof FormField
     */
    type?: string;
    /**
     * html input?
     * @type {string}
     * @memberof FormField
     */
    inputType?: string;
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    placeholder?: string;
    /**
     * è un array di cui va filtrato il primo valore valido
     * @type {Array<string>}
     * @memberof FormField
     */
    _default?: Array<string>;
    /**
     * 
     * @type {FieldExpression}
     * @memberof FormField
     */
    expression?: FieldExpression;
    /**
     * 
     * @type {FieldValidation}
     * @memberof FormField
     */
    validation?: FieldValidation;
    /**
     * 
     * @type {boolean}
     * @memberof FormField
     */
    sortDisabled?: boolean;
    /**
     * 
     * @type {FieldVisibility}
     * @memberof FormField
     */
    visibility?: FieldVisibility;
    /**
     * 
     * @type {FieldEnable}
     * @memberof FormField
     */
    enabledBy?: FieldEnable;
    /**
     * 
     * @type {FieldEnable}
     * @memberof FormField
     */
    enables?: FieldEnable;
}

/**
 * 
 * @export
 * @interface FormSection
 */
export interface FormSection {
    /**
     * 
     * @type {number}
     * @memberof FormSection
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FormSection
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FormSection
     */
    description?: string;
    /**
     * 
     * @type {Array<FormField>}
     * @memberof FormSection
     */
    fields?: Array<FormField>;
}

/**
 * 
 * @export
 * @interface GenericSettings
 */
export interface GenericSettings {
    /**
     * 
     * @type {number}
     * @memberof GenericSettings
     */
    interfaceLanguage?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSettings
     */
    exportLanguage?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSettings
     */
    measurementSystem?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSettings
     */
    defaultPortfolioId?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSettings
     */
    maxItemQuantity?: number;
}

/**
 * 
 * @export
 * @interface Item
 */
export interface Item {
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    name?: string;
    /**
     * the label of the item, can be changed by the user
     * @type {string}
     * @memberof Item
     */
    label?: string;
    /**
     * position, string format. Will be used to sort items using natural order
     * @type {string}
     * @memberof Item
     */
    position?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    quantity?: number;
    /**
     * the applied to the single item/unit (read only field)
     * @type {number}
     * @memberof Item
     */
    discount?: number;
    /**
     * a version number to be used to decorate the item
     * @type {string}
     * @memberof Item
     */
    version?: string;
    /**
     * the description of the unit selected for this item (read only field)
     * @type {string}
     * @memberof Item
     */
    unitDescription?: string;
    /**
     * 
     * @type {Date}
     * @memberof Item
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Item
     */
    lastModifiedDate?: Date;
    /**
     * 
     * @type {DutyMode}
     * @memberof Item
     */
    dutyMode?: DutyMode;
    /**
     * 
     * @type {Array<ItemAttribute>}
     * @memberof Item
     */
    thermalAttributes?: Array<ItemAttribute>;
    /**
     * 
     * @type {Array<ItemAttribute>}
     * @memberof Item
     */
    mechanicalAttributes?: Array<ItemAttribute>;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Item
     */
    thermalData?: { [key: string]: any; };
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Item
     */
    mechanicalData?: { [key: string]: any; };
    /**
     * 
     * @type {Project}
     * @memberof Item
     */
    project?: Project;
    /**
     * 
     * @type {ItemResults}
     * @memberof Item
     */
    results?: ItemResults;
    /**
     * 
     * @type {SimpleUser}
     * @memberof Item
     */
    owner?: SimpleUser;
    /**
     * 
     * @type {Permission}
     * @memberof Item
     */
    permission?: Permission;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    shared?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    hasResultsTable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    portfolioId?: number;
    /**
     * configurationId for CPQ integration
     * @type {string}
     * @memberof Item
     */
    configurationId?: string;
    /**
     * 
     * @type {CPQ}
     * @memberof Item
     */
    cpq?: CPQ;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    customerReference?: string;
}

/**
 * Item attribute values
 * @export
 * @interface ItemAttribute
 */
export interface ItemAttribute {
    /**
     * the field identifier. Can be used to associate this value to the FormField configuration
     * @type {string}
     * @memberof ItemAttribute
     */
    fieldId?: string;
    /**
     * The current value for this field. Can be a string, a number or a array (e.g. product type)
     * @type {any}
     * @memberof ItemAttribute
     */
    value?: any;
    /**
     * 
     * @type {Array<ItemAttributeOption>}
     * @memberof ItemAttribute
     */
    options?: Array<ItemAttributeOption>;
}

/**
 * 
 * @export
 * @interface ItemAttributeOption
 */
export interface ItemAttributeOption {
    /**
     * Used by frontend only. Takes precedence over labelId and value (if present, use this for the label)
     * @type {string}
     * @memberof ItemAttributeOption
     */
    label?: string;
    /**
     * The option value. Can be used also as label, in case no associations is found in the field-option-labels
     * @type {any}
     * @memberof ItemAttributeOption
     */
    value?: any;
    /**
     * 
     * @type {boolean}
     * @memberof ItemAttributeOption
     */
    disabled?: boolean;
}

/**
 * Extends basefield. Deprecated. Use ItemAttribute instead.
 * @export
 * @interface ItemField
 */
export interface ItemField extends BaseField {
    /**
     * The current value for this field. Can be a string, a number or a array (e.g. product type)
     * @type {any}
     * @memberof ItemField
     */
    value?: any;
    /**
     * 
     * @type {Array<FieldOption>}
     * @memberof ItemField
     */
    options?: Array<FieldOption>;
}

/**
 * Type returned from /results and /pricesAndWarnings. Properties will be different depending on which of the two api is invoked (it sucks)
 * @export
 * @interface ItemResult
 */
export interface ItemResult {
    /**
     * the ID of the unit. It identifies uniquely a \"column\" in the results table. To be used to fetch details and to select an item in the results list.
     * @type {string}
     * @memberof ItemResult
     */
    unitId?: string;
    /**
     * the name of the unit, to be shown in the FE page.
     * @type {string}
     * @memberof ItemResult
     */
    unitName?: string;
    /**
     * the item description, composed by unit name, thermal options and mechanical options
     * @type {string}
     * @memberof ItemResult
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResult
     */
    bestChoice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResult
     */
    bestRatio?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResult
     */
    mostSustainable?: boolean;
    /**
     * if the item (column) is selected -> different background color)
     * @type {boolean}
     * @memberof ItemResult
     */
    selected?: boolean;
    /**
     * unit locked by the user
     * @type {boolean}
     * @memberof ItemResult
     */
    locked?: boolean;
    /**
     * This is a field returned by the DLL. Deprecated, use coreCalculationMessages instead
     * @type {any}
     * @memberof ItemResult
     */
    coreCalculationWarnings?: any;
    /**
     * 
     * @type {Array<CalculationMessage>}
     * @memberof ItemResult
     */
    coreCalculationMessages?: Array<CalculationMessage>;
    /**
     * The price of this unit. Sum this value with kitPrice and options price to get the full price of the item.
     * @type {number}
     * @memberof ItemResult
     */
    unitPrice?: number;
    /**
     * The price of the Kit. TODO Nicola cos'e'?
     * @type {number}
     * @memberof ItemResult
     */
    kitPrice?: number;
    /**
     * The HTTP Url of the drawing
     * @type {string}
     * @memberof ItemResult
     */
    drawingUrl?: string;
    /**
     * The HTTP Url of the Dxf drawing
     * @type {string}
     * @memberof ItemResult
     */
    dxfDrawingUrl?: string;
    /**
     * 
     * @type {OptionsData}
     * @memberof ItemResult
     */
    unitData?: OptionsData;
    /**
     * 
     * @type {OptionsData}
     * @memberof ItemResult
     */
    optionsData?: OptionsData;
    /**
     * 
     * @type {OptionsData}
     * @memberof ItemResult
     */
    kitsData?: OptionsData;
    /**
     * the values to be shown in the table rows
     * @type {{ [key: string]: any; }}
     * @memberof ItemResult
     */
    calculationValues?: { [key: string]: any; };
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemResult
     */
    missingOptions?: Array<string>;
    /**
     * 
     * @type {Array<MissingOptionsMap>}
     * @memberof ItemResult
     */
    missingOptionsMap?: Array<MissingOptionsMap>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemResult
     */
    fallbackOptions?: Array<string>;
    /**
     * 
     * @type {Array<TechnicalDocumentation>}
     * @memberof ItemResult
     */
    technicalDocumentation?: Array<TechnicalDocumentation>;
}

/**
 * The results given as output of the calculation operation. These fields are available only after a /calculate operation on the item is called
 * @export
 * @interface ItemResults
 */
export interface ItemResults {
    /**
     * 
     * @type {Date}
     * @memberof ItemResults
     */
    lastModifiedDate: Date;
    /**
     * If true, must call /calculate
     * @type {boolean}
     * @memberof ItemResults
     */
    thermalDataChanged: boolean;
    /**
     * If true, must call /calculate?withMechanicalOptions=true
     * @type {boolean}
     * @memberof ItemResults
     */
    mechanicalDataChanged: boolean;
    /**
     * If true, ask to the user to choose what calculate version to use (original or latest)
     * @type {boolean}
     * @memberof ItemResults
     */
    coreCalculationChanged: boolean;
    /**
     * UNKNOWN if the backend does not know current dll version
     * @type {string}
     * @memberof ItemResults
     */
    coreCalculationStatus: ItemResults.CoreCalculationStatusEnum;
    /**
     * 
     * @type {Array<ItemResult>}
     * @memberof ItemResults
     */
    results?: Array<ItemResult>;
    /**
     * 
     * @type {PriceList}
     * @memberof ItemResults
     */
    priceList?: PriceList;
    /**
     * When over maxResults returns number of missing items
     * @type {number}
     * @memberof ItemResults
     */
    missingResults?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemResults
     */
    drawingsUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemResults
     */
    dxfDrawingsUrl?: string;
}

/**
 * @export
 * @namespace ItemResults
 */
export namespace ItemResults {
    /**
     * @export
     * @enum {string}
     */
    export enum CoreCalculationStatusEnum {
        CHANGED = 'CHANGED',
        UNCHANGED = 'UNCHANGED',
        UNKNOWN = 'UNKNOWN'
    }
}

/**
 * list of results table fields in their position. Deprecated
 * @export
 * @interface ItemResultsConfig
 */
export interface ItemResultsConfig {
    /**
     * 
     * @type {Array<BaseField>}
     * @memberof ItemResultsConfig
     */
    resultsTableFields?: Array<BaseField>;
    /**
     * 
     * @type {Array<BaseField>}
     * @memberof ItemResultsConfig
     */
    itemDetailFields?: Array<BaseField>;
    /**
     * 
     * @type {Array<BaseField>}
     * @memberof ItemResultsConfig
     */
    additionalDetailField?: Array<BaseField>;
}

/**
 * 
 * @export
 * @interface KeyAndPassword
 */
export interface KeyAndPassword {
    /**
     * 
     * @type {string}
     * @memberof KeyAndPassword
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof KeyAndPassword
     */
    newPassword?: string;
}

/**
 * 
 * @export
 * @interface Language
 */
export interface Language {
    /**
     * 
     * @type {number}
     * @memberof Language
     */
    id?: number;
    /**
     * the string locale for the language
     * @type {string}
     * @memberof Language
     */
    key?: string;
    /**
     * the language name
     * @type {string}
     * @memberof Language
     */
    name?: string;
}

/**
 * Object used to send client errors to backend
 * @export
 * @interface LogMessage
 */
export interface LogMessage {
    /**
     * the error text
     * @type {string}
     * @memberof LogMessage
     */
    text: string;
    /**
     * the client user agent
     * @type {string}
     * @memberof LogMessage
     */
    userAgent: string;
    /**
     * 
     * @type {string}
     * @memberof LogMessage
     */
    level: LogMessage.LevelEnum;
    /**
     * The user login, retrieved in the JWT token
     * @type {string}
     * @memberof LogMessage
     */
    userId?: string;
    /**
     * The authentication token
     * @type {string}
     * @memberof LogMessage
     */
    userToken?: string;
}

/**
 * @export
 * @namespace LogMessage
 */
export namespace LogMessage {
    /**
     * @export
     * @enum {string}
     */
    export enum LevelEnum {
        DEBUG = 'DEBUG',
        INFO = 'INFO',
        WARN = 'WARN',
        ERROR = 'ERROR'
    }
}

/**
 * 
 * @export
 * @interface MetricSystem
 */
export interface MetricSystem {
    /**
     * 
     * @type {number}
     * @memberof MetricSystem
     */
    id?: number;
    /**
     * the key for the metric system.
     * @type {string}
     * @memberof MetricSystem
     */
    key?: string;
    /**
     * the translated name of the metric system
     * @type {string}
     * @memberof MetricSystem
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface MissingOptionsMap
 */
export interface MissingOptionsMap {
    /**
     * FieldKey of missingOption from DDL
     * @type {string}
     * @memberof MissingOptionsMap
     */
    fieldKey?: string;
    /**
     * The MissingOption value.
     * @type {Array<string>}
     * @memberof MissingOptionsMap
     */
    missingOptions?: Array<string>;
}

/**
 * A new share
 * @export
 * @interface NewShare
 */
export interface NewShare {
    /**
     * 
     * @type {string}
     * @memberof NewShare
     */
    email: string;
    /**
     * 
     * @type {Permission}
     * @memberof NewShare
     */
    permission: Permission;
}

/**
 * It's a map of FieldOption values and label translations
 * @export
 * @interface OptionLabelValue
 */
export interface OptionLabelValue {
    [key: string]: string;

}

/**
 * It's a map of FormField names, which contains a map of FieldOption labels and translations
 * @export
 * @interface OptionLabels
 */
export interface OptionLabels {
    [key: string]: OptionLabelValue | any;

    /**
     * 
     * @type {string}
     * @memberof OptionLabels
     */
    language?: string;
}

/**
 * Contains the chosen values for that option. Created to handle kits and kit for fan.
 * @export
 * @interface OptionValue
 */
export interface OptionValue {
    /**
     * 
     * @type {string}
     * @memberof OptionValue
     */
    fieldKey?: string;
    /**
     * The value of the option. The label of this option has to be taken from the translated label call /field-option-labels. The key for the label is the fieldKey of the parent object.
     * @type {any}
     * @memberof OptionValue
     */
    value?: any;
    /**
     * 
     * @type {number}
     * @memberof OptionValue
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof OptionValue
     */
    weight?: number;
    /**
     * 
     * @type {string}
     * @memberof OptionValue
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof OptionValue
     */
    quantity?: number;
}

/**
 * Additional information calculated starting from the options list and their availability
 * @export
 * @interface OptionsData
 */
export interface OptionsData {
    /**
     * the ID of the unit. To be used to add this information to the ItemResult
     * @type {string}
     * @memberof OptionsData
     */
    unitId?: string;
    /**
     * delivery time indicator
     * @type {string}
     * @memberof OptionsData
     */
    deliveryTime?: OptionsData.DeliveryTimeEnum;
    /**
     * List of mechanical options not compatible with this item (dict. fieldKey -> labelId/value)
     * @type {Array<FieldOption>}
     * @memberof OptionsData
     */
    missingOptions?: Array<FieldOption>;
    /**
     * 
     * @type {Array<MissingOptionsMap>}
     * @memberof OptionsData
     */
    missingOptionsMap?: Array<MissingOptionsMap>;
    /**
     * total price of the options of this unit
     * @type {number}
     * @memberof OptionsData
     */
    price?: number;
    /**
     * total weight of the options of this unit
     * @type {number}
     * @memberof OptionsData
     */
    weight?: number;
    /**
     * 
     * @type {Array<UnitOption>}
     * @memberof OptionsData
     */
    optionGroups?: Array<UnitOption>;
    /**
     * 
     * @type {Array<OptionsGroup>}
     * @memberof OptionsData
     */
    options?: Array<OptionsGroup>;
}

/**
 * @export
 * @namespace OptionsData
 */
export namespace OptionsData {
    /**
     * @export
     * @enum {string}
     */
    export enum DeliveryTimeEnum {
        LONGTERM = 'LONG_TERM',
        MIDTERM = 'MID_TERM',
        SHORTTERM = 'SHORT_TERM',
        IMMEDIATE = 'IMMEDIATE'
    }
}

/**
 * 
 * @export
 * @interface OptionsGroup
 */
export interface OptionsGroup {
    /**
     * 
     * @type {string}
     * @memberof OptionsGroup
     */
    groupKey?: string;
    /**
     * 
     * @type {number}
     * @memberof OptionsGroup
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof OptionsGroup
     */
    weight?: number;
    /**
     * 
     * @type {Array<OptionsGroup>}
     * @memberof OptionsGroup
     */
    subGroups?: Array<OptionsGroup>;
    /**
     * 
     * @type {Array<OptionValue>}
     * @memberof OptionsGroup
     */
    fields?: Array<OptionValue>;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrientationEnum {
    HORIZONTAL = 'HORIZONTAL',
    VERTICAL = 'VERTICAL'
}

/**
 * 
 * @export
 * @interface PasswordChange
 */
export interface PasswordChange {
    /**
     * 
     * @type {string}
     * @memberof PasswordChange
     */
    currentPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChange
     */
    newPassword?: string;
}

/**
 * 
 * @export
 * @interface PasswordReset
 */
export interface PasswordReset {
    /**
     * 
     * @type {string}
     * @memberof PasswordReset
     */
    email: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum Permission {
    READ = 'READ',
    WRITE = 'WRITE'
}

/**
 * Object containing platform info. This informations are sent by the BE in the Plair-Platform http header
 * @export
 * @interface PlatformInfo
 */
export interface PlatformInfo {
    /**
     * Backend build version of the jhipster web services
     * @type {string}
     * @memberof PlatformInfo
     */
    backendVersion?: string;
    /**
     * API version (the one specified in this yml file)
     * @type {string}
     * @memberof PlatformInfo
     */
    apiVersion?: string;
    /**
     * DLL version
     * @type {string}
     * @memberof PlatformInfo
     */
    coreVersion?: string;
    /**
     * Version of the wrapper exposing dll via web
     * @type {string}
     * @memberof PlatformInfo
     */
    coreWrapperVersion?: string;
}

/**
 * 
 * @export
 * @interface Portfolio
 */
export interface Portfolio {
    /**
     * 
     * @type {number}
     * @memberof Portfolio
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Portfolio
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Portfolio
     */
    descripition?: string;
    /**
     * the static url of the svg logo
     * @type {string}
     * @memberof Portfolio
     */
    logoUrl: string;
    /**
     * the external url of the portfolio. If this field has a value the url has to be opened in a browser tab 
     * @type {string}
     * @memberof Portfolio
     */
    url?: string;
    /**
     * true if the user can use this portfolio, false otherwise
     * @type {boolean}
     * @memberof Portfolio
     */
    available?: boolean;
}

/**
 * 
 * @export
 * @interface PortfolioUtility
 */
export interface PortfolioUtility {
    /**
     * 
     * @type {number}
     * @memberof PortfolioUtility
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioUtility
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioUtility
     */
    abbreviation: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioUtility
     */
    url: string;
}

/**
 * 
 * @export
 * @interface PriceList
 */
export interface PriceList {
    /**
     * 
     * @type {number}
     * @memberof PriceList
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof PriceList
     */
    expired: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PriceList
     */
    releaseDate: Date;
}

/**
 * Backend errors in a problem format. Every exception or error has this format
 * @export
 * @interface Problem
 */
export interface Problem {
    /**
     * An absolute URI that identifies the problem type.  When dereferenced, it SHOULD provide human-readable documentation for the problem type (e.g., using HTML). 
     * @type {string}
     * @memberof Problem
     */
    type: string;
    /**
     * A short, summary of the problem type. Written in english and readable for engineers (usually not suited for non technical stakeholders and not localized); example: Service Unavailable 
     * @type {string}
     * @memberof Problem
     */
    title?: string;
    /**
     * The HTTP status code generated by the origin server for this occurrence of the problem. 
     * @type {number}
     * @memberof Problem
     */
    status: number;
    /**
     * A human readable explanation specific to this occurrence of the problem. 
     * @type {string}
     * @memberof Problem
     */
    detail?: string;
    /**
     * An absolute URI that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced. 
     * @type {string}
     * @memberof Problem
     */
    instance?: string;
    /**
     * A message (meant to be shown)
     * @type {string}
     * @memberof Problem
     */
    message?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Problem
     */
    dllErrors?: Array<number>;
}

/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    user?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    isDefault?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Project
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Project
     */
    lastModifiedDate?: Date;
    /**
     * 
     * @type {Array<Item>}
     * @memberof Project
     */
    items?: Array<Item>;
    /**
     * 
     * @type {SimpleUser}
     * @memberof Project
     */
    owner?: SimpleUser;
    /**
     * 
     * @type {Permission}
     * @memberof Project
     */
    permission?: Permission;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    shared?: boolean;
    /**
     * 
     * @type {CPQ}
     * @memberof Project
     */
    cpq?: CPQ;
}

/**
 * Quote information
 * @export
 * @interface Quote
 */
export interface Quote {
    /**
     * Id of the quote or the the item inside the quote
     * @type {number}
     * @memberof Quote
     */
    id: number;
    /**
     * External url to open the quote
     * @type {string}
     * @memberof Quote
     */
    url?: string;
    /**
     * 
     * @type {Date}
     * @memberof Quote
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof Quote
     */
    lastModifiedDate: Date;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    status?: Quote.StatusEnum;
}

/**
 * @export
 * @namespace Quote
 */
export namespace Quote {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        NORMAL = 'NORMAL',
        READONLY = 'READONLY'
    }
}

/**
 * Quote Input information
 * @export
 * @interface QuoteInput
 */
export interface QuoteInput {
    /**
     * Item id of item
     * @type {number}
     * @memberof QuoteInput
     */
    itemId?: number;
    /**
     * Code of kit
     * @type {string}
     * @memberof QuoteInput
     */
    kitCode?: string;
    /**
     * Item or kit label
     * @type {string}
     * @memberof QuoteInput
     */
    label: string;
    /**
     * Item or kit description
     * @type {string}
     * @memberof QuoteInput
     */
    description?: string;
    /**
     * Item quantity
     * @type {number}
     * @memberof QuoteInput
     */
    itemQuantity?: number;
    /**
     * Kit quantity
     * @type {number}
     * @memberof QuoteInput
     */
    kitQuantity?: number;
    /**
     * Item or kit price
     * @type {number}
     * @memberof QuoteInput
     */
    price: number;
    /**
     * 
     * @type {boolean}
     * @memberof QuoteInput
     */
    isKit?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuoteInput
     */
    customerReference?: string;
    /**
     * 
     * @type {Permission}
     * @memberof QuoteInput
     */
    permission?: Permission;
}

/**
 * 
 * @export
 * @interface RegistrationRequest
 */
export interface RegistrationRequest {
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequest
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof RegistrationRequest
     */
    countryId: number;
}

/**
 * A base field with attributes \"position\" and \"visible\"
 * @export
 * @interface ReportField
 */
export interface ReportField extends BaseField {
}

/**
 * 
 * @export
 * @interface ReportSection
 */
export interface ReportSection {
    /**
     * 
     * @type {number}
     * @memberof ReportSection
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportSection
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportSection
     */
    description?: string;
    /**
     * an alternative logo, supplied as a base 64 image
     * @type {string}
     * @memberof ReportSection
     */
    logo?: string;
    /**
     * 
     * @type {Array<ReportField>}
     * @memberof ReportSection
     */
    fields?: Array<ReportField>;
}

/**
 * 
 * @export
 * @interface ResendEmailRequest
 */
export interface ResendEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof ResendEmailRequest
     */
    email: string;
}

/**
 * A base field with attributes \"position\" and \"visible\"
 * @export
 * @interface ResultField
 */
export interface ResultField extends BaseField {
    /**
     * the sections in which the field has to be displayed
     * @type {Array<string>}
     * @memberof ResultField
     */
    sections?: Array<string>;
}

/**
 * a map of field keys, each one with its position in the results table. Null for hidden fields.
 * @export
 * @interface ResultsTableSettings
 */
export interface ResultsTableSettings {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ResultsTableSettings
     */
    fields?: { [key: string]: number; };
    /**
     * 
     * @type {OrientationEnum}
     * @memberof ResultsTableSettings
     */
    orientation?: OrientationEnum;
}

/**
 * An existing share object
 * @export
 * @interface Share
 */
export interface Share {
    /**
     * 
     * @type {number}
     * @memberof Share
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Share
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Share
     */
    name?: string;
    /**
     * 
     * @type {Permission}
     * @memberof Share
     */
    permission: Permission;
}

/**
 * 
 * @export
 * @interface SimpleUser
 */
export interface SimpleUser {
    /**
     * 
     * @type {string}
     * @memberof SimpleUser
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleUser
     */
    isMe?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SimpleUser
     */
    email: string;
}

/**
 * 
 * @export
 * @interface Subdivision
 */
export interface Subdivision {
    /**
     * 
     * @type {number}
     * @memberof Subdivision
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Subdivision
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Subdivision
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Subdivision
     */
    deletedDate?: string;
}

/**
 * A technical document with name, category and url for use in a list of technical documents for a given item
 * @export
 * @interface TechnicalDocumentation
 */
export interface TechnicalDocumentation {
    /**
     * The name of the document, translated in the ui language if a translation exists
     * @type {string}
     * @memberof TechnicalDocumentation
     */
    name?: string;
    /**
     * The document category, translated in the ui language if a translation exists
     * @type {string}
     * @memberof TechnicalDocumentation
     */
    documentCategory?: string;
    /**
     * The url to view the document
     * @type {string}
     * @memberof TechnicalDocumentation
     */
    url?: string;
}

/**
 * 
 * @export
 * @interface TechnicalSpecificationConfig
 */
export interface TechnicalSpecificationConfig {
    /**
     * 
     * @type {VersionLength}
     * @memberof TechnicalSpecificationConfig
     */
    versionLength?: VersionLength;
    /**
     * pdf language code
     * @type {string}
     * @memberof TechnicalSpecificationConfig
     */
    language?: string;
    /**
     * the client's first name
     * @type {string}
     * @memberof TechnicalSpecificationConfig
     */
    firstName?: string;
    /**
     * the client's last name
     * @type {string}
     * @memberof TechnicalSpecificationConfig
     */
    lastName?: string;
    /**
     * shows the item note in the technical specifications
     * @type {boolean}
     * @memberof TechnicalSpecificationConfig
     */
    displayItemNote?: boolean;
    /**
     * the client's address
     * @type {string}
     * @memberof TechnicalSpecificationConfig
     */
    address?: string;
    /**
     * flag for whether or not the alfalaval logo is present in the pdf
     * @type {boolean}
     * @memberof TechnicalSpecificationConfig
     */
    alfaLavalLogo?: boolean;
    /**
     * an alternative logo, supplied as a base 64 image
     * @type {string}
     * @memberof TechnicalSpecificationConfig
     */
    uploadLogo?: string;
    /**
     * the name of the logo file (property added because name cannot be retrieved from base64
     * @type {string}
     * @memberof TechnicalSpecificationConfig
     */
    logoFileName?: string;
    /**
     * flag for enabling the insertion of a static certificate image
     * @type {boolean}
     * @memberof TechnicalSpecificationConfig
     */
    certificateLogo?: boolean;
    /**
     * flag for inserting drawing(s) in the pdf
     * @type {boolean}
     * @memberof TechnicalSpecificationConfig
     */
    drawing?: boolean;
    /**
     * flag for showing the price
     * @type {boolean}
     * @memberof TechnicalSpecificationConfig
     */
    price?: boolean;
    /**
     * spare parts flag
     * @type {boolean}
     * @memberof TechnicalSpecificationConfig
     */
    spareParts?: boolean;
    /**
     * flag for applying a discount
     * @type {boolean}
     * @memberof TechnicalSpecificationConfig
     */
    discountedPrice?: boolean;
    /**
     * a percentage discount to apply to the base price
     * @type {number}
     * @memberof TechnicalSpecificationConfig
     */
    percentage?: number;
    /**
     * true to show only the discounted price, false to show full price and discounted price (default to false)
     * @type {boolean}
     * @memberof TechnicalSpecificationConfig
     */
    showOnlyDiscountedPrice?: boolean;
    /**
     * 1234-2021
     * @type {string}
     * @memberof TechnicalSpecificationConfig
     */
    offerNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicalSpecificationConfig
     */
    offerDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicalSpecificationConfig
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicalSpecificationConfig
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicalSpecificationConfig
     */
    note?: string;
    /**
     * the base64 of the pdf that must be appended to the tecnnical specification (max 1MB)
     * @type {string}
     * @memberof TechnicalSpecificationConfig
     */
    customPdf?: string;
    /**
     * the name of the pdf file (property added because name cannot be retrieved from base64
     * @type {string}
     * @memberof TechnicalSpecificationConfig
     */
    pdfFileName?: string;
    /**
     * wether to append the custom pdf to the technical specification or not
     * @type {boolean}
     * @memberof TechnicalSpecificationConfig
     */
    appendCustomPdf?: boolean;
    /**
     * the discount to apply to the whole technical specification
     * @type {number}
     * @memberof TechnicalSpecificationConfig
     */
    offerDiscount?: number;
    /**
     * flag for showing the sound power level
     * @type {boolean}
     * @memberof TechnicalSpecificationConfig
     */
    showSoundPowerLevel?: boolean;
    /**
     * pdf forced even if user has word capability
     * @type {boolean}
     * @memberof TechnicalSpecificationConfig
     */
    forcedPdf?: boolean;
    /**
     * whether to prepend the item title or not
     * @type {boolean}
     * @memberof TechnicalSpecificationConfig
     */
    includeItemTitle?: boolean;
    /**
     * Field per applicationType
     * @type {{ [key: string]: Array<string>; }}
     * @memberof TechnicalSpecificationConfig
     */
    excludedFields?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {TechnicalSpecificationConfig}
     * @memberof TechnicalSpecificationConfig
     */
    parentConfig?: TechnicalSpecificationConfig;
}

/**
 * 
 * @export
 * @interface UnitOfMeasure
 */
export interface UnitOfMeasure {
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasure
     */
    precision?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasure
     */
    symbol?: string;
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasure
     */
    step?: number;
}

/**
 * An option type compatible with the unit. If an option has multiple values (e.g. kit or kitForFan), the details of these values can be found in the details property of this object.
 * @export
 * @interface UnitOption
 */
export interface UnitOption {
    /**
     * 
     * @type {string}
     * @memberof UnitOption
     */
    fieldKey?: string;
    /**
     * 
     * @type {number}
     * @memberof UnitOption
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof UnitOption
     */
    weight?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UnitOption
     */
    available?: boolean;
    /**
     * 
     * @type {Array<UnitOptionValue>}
     * @memberof UnitOption
     */
    details?: Array<UnitOptionValue>;
}

/**
 * Contains the chosen values for that option. Created to handle kits and kit for fan.
 * @export
 * @interface UnitOptionValue
 */
export interface UnitOptionValue {
    /**
     * 
     * @type {string}
     * @memberof UnitOptionValue
     */
    fieldKey?: string;
    /**
     * The value of the option. The label of this option has to be taken from the translated label call /field-option-labels. The key for the label is the fieldKey of the parent object.
     * @type {any}
     * @memberof UnitOptionValue
     */
    value?: any;
    /**
     * 
     * @type {number}
     * @memberof UnitOptionValue
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof UnitOptionValue
     */
    weight?: number;
}

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lastModifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    imageUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    countryId?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    countryName?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    subdivisionId?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    subdivisionName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    zipCode?: string;
}

/**
 * 
 * @export
 * @interface UserProblem
 */
export interface UserProblem {
    /**
     * 
     * @type {string}
     * @memberof UserProblem
     */
    description: string;
    /**
     * a file containing the FE dump file (e.g. redux dump)
     * @type {string}
     * @memberof UserProblem
     */
    dump?: string;
    /**
     * 
     * @type {Array<UserProblemAttachment>}
     * @memberof UserProblem
     */
    attachments?: Array<UserProblemAttachment>;
}

/**
 * 
 * @export
 * @interface UserProblemAttachment
 */
export interface UserProblemAttachment {
    /**
     * The name of the attachment
     * @type {string}
     * @memberof UserProblemAttachment
     */
    name: string;
    /**
     * the base64 file content
     * @type {string}
     * @memberof UserProblemAttachment
     */
    content: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum VersionLength {
    SHORT = 'SHORT',
    LONG = 'LONG'
}


/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Changes user password
         * @param {PasswordChange} passwordChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(passwordChange: PasswordChange, options: any = {}): RequestArgs {
            // verify required parameter 'passwordChange' is not null or undefined
            if (passwordChange === null || passwordChange === undefined) {
                throw new RequiredError('passwordChange','Required parameter passwordChange was null or undefined when calling changePassword.');
            }
            const localVarPath = `/account/change-password`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PasswordChange" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(passwordChange || {}) : (passwordChange || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Finish resetting the email
         * @summary Completes the email reset procedure
         * @param {string} key the email reset key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishEmailReset(key: string, options: any = {}): RequestArgs {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling finishEmailReset.');
            }
            const localVarPath = `/account/reset-email/finish`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Completes the password reset procedure
         * @param {KeyAndPassword} keyAndPassword keyAndPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishPasswordReset(keyAndPassword: KeyAndPassword, options: any = {}): RequestArgs {
            // verify required parameter 'keyAndPassword' is not null or undefined
            if (keyAndPassword === null || keyAndPassword === undefined) {
                throw new RequiredError('keyAndPassword','Required parameter keyAndPassword was null or undefined when calling finishPasswordReset.');
            }
            const localVarPath = `/account/reset-password/finish`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KeyAndPassword" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(keyAndPassword || {}) : (keyAndPassword || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(options: any = {}): RequestArgs {
            const localVarPath = `/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register a new account
         * @param {RegistrationRequest} registrationRequest Registration Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAccount(registrationRequest: RegistrationRequest, options: any = {}): RequestArgs {
            // verify required parameter 'registrationRequest' is not null or undefined
            if (registrationRequest === null || registrationRequest === undefined) {
                throw new RequiredError('registrationRequest','Required parameter registrationRequest was null or undefined when calling registerAccount.');
            }
            const localVarPath = `/account/register`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RegistrationRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(registrationRequest || {}) : (registrationRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request for an email reset. An email for an email reset will be sent to the user.
         * @param {EmailReset} emailReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEmailReset(emailReset: EmailReset, options: any = {}): RequestArgs {
            // verify required parameter 'emailReset' is not null or undefined
            if (emailReset === null || emailReset === undefined) {
                throw new RequiredError('emailReset','Required parameter emailReset was null or undefined when calling requestEmailReset.');
            }
            const localVarPath = `/account/reset-email/init`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EmailReset" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(emailReset || {}) : (emailReset || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request for a password reset. An email for a password reset will be sent to the user
         * @param {PasswordReset} passwordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordReset(passwordReset: PasswordReset, options: any = {}): RequestArgs {
            // verify required parameter 'passwordReset' is not null or undefined
            if (passwordReset === null || passwordReset === undefined) {
                throw new RequiredError('passwordReset','Required parameter passwordReset was null or undefined when calling requestPasswordReset.');
            }
            const localVarPath = `/account/reset-password/init`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PasswordReset" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(passwordReset || {}) : (passwordReset || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resend Confirmation Mail
         * @summary Resend confirmation mail
         * @param {ResendEmailRequest} resendEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationMail(resendEmailRequest: ResendEmailRequest, options: any = {}): RequestArgs {
            // verify required parameter 'resendEmailRequest' is not null or undefined
            if (resendEmailRequest === null || resendEmailRequest === undefined) {
                throw new RequiredError('resendEmailRequest','Required parameter resendEmailRequest was null or undefined when calling resendConfirmationMail.');
            }
            const localVarPath = `/account/resend-confirmation-mail`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ResendEmailRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resendEmailRequest || {}) : (resendEmailRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates account details
         * @param {User} user userDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccount(user: User, options: any = {}): RequestArgs {
            // verify required parameter 'user' is not null or undefined
            if (user === null || user === undefined) {
                throw new RequiredError('user','Required parameter user was null or undefined when calling saveAccount.');
            }
            const localVarPath = `/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"User" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(user || {}) : (user || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Changes user password
         * @param {PasswordChange} passwordChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(passwordChange: PasswordChange, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).changePassword(passwordChange, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Finish resetting the email
         * @summary Completes the email reset procedure
         * @param {string} key the email reset key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishEmailReset(key: string, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).finishEmailReset(key, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Completes the password reset procedure
         * @param {KeyAndPassword} keyAndPassword keyAndPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishPasswordReset(keyAndPassword: KeyAndPassword, options?: any): (basePath?: string) => AxiosPromise<AuthenticationResponse> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).finishPasswordReset(keyAndPassword, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Retrieves account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(options?: any): (basePath?: string) => AxiosPromise<User> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).getAccount(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Register a new account
         * @param {RegistrationRequest} registrationRequest Registration Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAccount(registrationRequest: RegistrationRequest, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).registerAccount(registrationRequest, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Request for an email reset. An email for an email reset will be sent to the user.
         * @param {EmailReset} emailReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEmailReset(emailReset: EmailReset, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).requestEmailReset(emailReset, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Request for a password reset. An email for a password reset will be sent to the user
         * @param {PasswordReset} passwordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordReset(passwordReset: PasswordReset, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).requestPasswordReset(passwordReset, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Resend Confirmation Mail
         * @summary Resend confirmation mail
         * @param {ResendEmailRequest} resendEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationMail(resendEmailRequest: ResendEmailRequest, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).resendConfirmationMail(resendEmailRequest, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Updates account details
         * @param {User} user userDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccount(user: User, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).saveAccount(user, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary Changes user password
         * @param {PasswordChange} passwordChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(passwordChange: PasswordChange, options?: any) {
            return AccountApiFp(configuration).changePassword(passwordChange, options)(basePath);
        },
        /**
         * Finish resetting the email
         * @summary Completes the email reset procedure
         * @param {string} key the email reset key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishEmailReset(key: string, options?: any) {
            return AccountApiFp(configuration).finishEmailReset(key, options)(basePath);
        },
        /**
         * 
         * @summary Completes the password reset procedure
         * @param {KeyAndPassword} keyAndPassword keyAndPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishPasswordReset(keyAndPassword: KeyAndPassword, options?: any) {
            return AccountApiFp(configuration).finishPasswordReset(keyAndPassword, options)(basePath);
        },
        /**
         * 
         * @summary Retrieves account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(options?: any) {
            return AccountApiFp(configuration).getAccount(options)(basePath);
        },
        /**
         * 
         * @summary Register a new account
         * @param {RegistrationRequest} registrationRequest Registration Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAccount(registrationRequest: RegistrationRequest, options?: any) {
            return AccountApiFp(configuration).registerAccount(registrationRequest, options)(basePath);
        },
        /**
         * 
         * @summary Request for an email reset. An email for an email reset will be sent to the user.
         * @param {EmailReset} emailReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEmailReset(emailReset: EmailReset, options?: any) {
            return AccountApiFp(configuration).requestEmailReset(emailReset, options)(basePath);
        },
        /**
         * 
         * @summary Request for a password reset. An email for a password reset will be sent to the user
         * @param {PasswordReset} passwordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordReset(passwordReset: PasswordReset, options?: any) {
            return AccountApiFp(configuration).requestPasswordReset(passwordReset, options)(basePath);
        },
        /**
         * Resend Confirmation Mail
         * @summary Resend confirmation mail
         * @param {ResendEmailRequest} resendEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationMail(resendEmailRequest: ResendEmailRequest, options?: any) {
            return AccountApiFp(configuration).resendConfirmationMail(resendEmailRequest, options)(basePath);
        },
        /**
         * 
         * @summary Updates account details
         * @param {User} user userDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccount(user: User, options?: any) {
            return AccountApiFp(configuration).saveAccount(user, options)(basePath);
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Changes user password
     * @param {PasswordChange} passwordChange 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public changePassword(passwordChange: PasswordChange, options?: any) {
        return AccountApiFp(this.configuration).changePassword(passwordChange, options)(this.basePath);
    }

    /**
     * Finish resetting the email
     * @summary Completes the email reset procedure
     * @param {string} key the email reset key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public finishEmailReset(key: string, options?: any) {
        return AccountApiFp(this.configuration).finishEmailReset(key, options)(this.basePath);
    }

    /**
     * 
     * @summary Completes the password reset procedure
     * @param {KeyAndPassword} keyAndPassword keyAndPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public finishPasswordReset(keyAndPassword: KeyAndPassword, options?: any) {
        return AccountApiFp(this.configuration).finishPasswordReset(keyAndPassword, options)(this.basePath);
    }

    /**
     * 
     * @summary Retrieves account details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccount(options?: any) {
        return AccountApiFp(this.configuration).getAccount(options)(this.basePath);
    }

    /**
     * 
     * @summary Register a new account
     * @param {RegistrationRequest} registrationRequest Registration Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public registerAccount(registrationRequest: RegistrationRequest, options?: any) {
        return AccountApiFp(this.configuration).registerAccount(registrationRequest, options)(this.basePath);
    }

    /**
     * 
     * @summary Request for an email reset. An email for an email reset will be sent to the user.
     * @param {EmailReset} emailReset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public requestEmailReset(emailReset: EmailReset, options?: any) {
        return AccountApiFp(this.configuration).requestEmailReset(emailReset, options)(this.basePath);
    }

    /**
     * 
     * @summary Request for a password reset. An email for a password reset will be sent to the user
     * @param {PasswordReset} passwordReset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public requestPasswordReset(passwordReset: PasswordReset, options?: any) {
        return AccountApiFp(this.configuration).requestPasswordReset(passwordReset, options)(this.basePath);
    }

    /**
     * Resend Confirmation Mail
     * @summary Resend confirmation mail
     * @param {ResendEmailRequest} resendEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public resendConfirmationMail(resendEmailRequest: ResendEmailRequest, options?: any) {
        return AccountApiFp(this.configuration).resendConfirmationMail(resendEmailRequest, options)(this.basePath);
    }

    /**
     * 
     * @summary Updates account details
     * @param {User} user userDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public saveAccount(user: User, options?: any) {
        return AccountApiFp(this.configuration).saveAccount(user, options)(this.basePath);
    }

}

/**
 * ApplicationTypeApi - axios parameter creator
 * @export
 */
export const ApplicationTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary All the available application types, filtered by portfolio id
         * @param {number} [portfolioId] the id of the portfolio to be used to filter the items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableApplicationTypes(portfolioId?: number, options: any = {}): RequestArgs {
            const localVarPath = `/application-types`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (portfolioId !== undefined) {
                localVarQueryParameter['portfolioId'] = portfolioId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationTypeApi - functional programming interface
 * @export
 */
export const ApplicationTypeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary All the available application types, filtered by portfolio id
         * @param {number} [portfolioId] the id of the portfolio to be used to filter the items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableApplicationTypes(portfolioId?: number, options?: any): (basePath?: string) => AxiosPromise<Array<ApplicationType>> {
            const localVarAxiosArgs = ApplicationTypeApiAxiosParamCreator(configuration).getAvailableApplicationTypes(portfolioId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * ApplicationTypeApi - factory interface
 * @export
 */
export const ApplicationTypeApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary All the available application types, filtered by portfolio id
         * @param {number} [portfolioId] the id of the portfolio to be used to filter the items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableApplicationTypes(portfolioId?: number, options?: any) {
            return ApplicationTypeApiFp(configuration).getAvailableApplicationTypes(portfolioId, options)(basePath);
        },
    };
};

/**
 * ApplicationTypeApi - object-oriented interface
 * @export
 * @class ApplicationTypeApi
 * @extends {BaseAPI}
 */
export class ApplicationTypeApi extends BaseAPI {
    /**
     * 
     * @summary All the available application types, filtered by portfolio id
     * @param {number} [portfolioId] the id of the portfolio to be used to filter the items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationTypeApi
     */
    public getAvailableApplicationTypes(portfolioId?: number, options?: any) {
        return ApplicationTypeApiFp(this.configuration).getAvailableApplicationTypes(portfolioId, options)(this.basePath);
    }

}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary user login
         * @param {AuthenticationRequest} [authenticationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(authenticationRequest?: AuthenticationRequest, options: any = {}): RequestArgs {
            const localVarPath = `/authenticate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AuthenticationRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(authenticationRequest || {}) : (authenticationRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if authentication is valid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAuthenticated(options: any = {}): RequestArgs {
            const localVarPath = `/authenticate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary user login
         * @param {AuthenticationRequest} [authenticationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(authenticationRequest?: AuthenticationRequest, options?: any): (basePath?: string) => AxiosPromise<AuthenticationResponse> {
            const localVarAxiosArgs = AuthenticationApiAxiosParamCreator(configuration).authenticate(authenticationRequest, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Checks if authentication is valid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAuthenticated(options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = AuthenticationApiAxiosParamCreator(configuration).isAuthenticated(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary user login
         * @param {AuthenticationRequest} [authenticationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(authenticationRequest?: AuthenticationRequest, options?: any) {
            return AuthenticationApiFp(configuration).authenticate(authenticationRequest, options)(basePath);
        },
        /**
         * 
         * @summary Checks if authentication is valid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAuthenticated(options?: any) {
            return AuthenticationApiFp(configuration).isAuthenticated(options)(basePath);
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary user login
     * @param {AuthenticationRequest} [authenticationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticate(authenticationRequest?: AuthenticationRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticate(authenticationRequest, options)(this.basePath);
    }

    /**
     * 
     * @summary Checks if authentication is valid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public isAuthenticated(options?: any) {
        return AuthenticationApiFp(this.configuration).isAuthenticated(options)(this.basePath);
    }

}

/**
 * CPQApi - axios parameter creator
 * @export
 */
export const CPQApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Sincronizza le data tables plair con le custom table CPQ
         * @param {CPQTablesSynchronization} [cPQTablesSynchronization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTables(cPQTablesSynchronization?: CPQTablesSynchronization, options: any = {}): RequestArgs {
            const localVarPath = `/cpq/tables/sync`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CPQTablesSynchronization" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(cPQTablesSynchronization || {}) : (cPQTablesSynchronization || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CPQApi - functional programming interface
 * @export
 */
export const CPQApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Sincronizza le data tables plair con le custom table CPQ
         * @param {CPQTablesSynchronization} [cPQTablesSynchronization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTables(cPQTablesSynchronization?: CPQTablesSynchronization, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = CPQApiAxiosParamCreator(configuration).syncTables(cPQTablesSynchronization, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * CPQApi - factory interface
 * @export
 */
export const CPQApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary Sincronizza le data tables plair con le custom table CPQ
         * @param {CPQTablesSynchronization} [cPQTablesSynchronization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTables(cPQTablesSynchronization?: CPQTablesSynchronization, options?: any) {
            return CPQApiFp(configuration).syncTables(cPQTablesSynchronization, options)(basePath);
        },
    };
};

/**
 * CPQApi - object-oriented interface
 * @export
 * @class CPQApi
 * @extends {BaseAPI}
 */
export class CPQApi extends BaseAPI {
    /**
     * 
     * @summary Sincronizza le data tables plair con le custom table CPQ
     * @param {CPQTablesSynchronization} [cPQTablesSynchronization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CPQApi
     */
    public syncTables(cPQTablesSynchronization?: CPQTablesSynchronization, options?: any) {
        return CPQApiFp(this.configuration).syncTables(cPQTablesSynchronization, options)(this.basePath);
    }

}

/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the list of available (sorted) campaigns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveCampaigns(options: any = {}): RequestArgs {
            const localVarPath = `/campaigns`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the list of available (sorted) campaigns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveCampaigns(options?: any): (basePath?: string) => AxiosPromise<Array<Campaign>> {
            const localVarAxiosArgs = CampaignApiAxiosParamCreator(configuration).getActiveCampaigns(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary Returns the list of available (sorted) campaigns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveCampaigns(options?: any) {
            return CampaignApiFp(configuration).getActiveCampaigns(options)(basePath);
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * 
     * @summary Returns the list of available (sorted) campaigns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getActiveCampaigns(options?: any) {
        return CampaignApiFp(this.configuration).getActiveCampaigns(options)(this.basePath);
    }

}

/**
 * CapabilitiesApi - axios parameter creator
 * @export
 */
export const CapabilitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary The list of capabilities for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCapabilities(options: any = {}): RequestArgs {
            const localVarPath = `/capabilities`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CapabilitiesApi - functional programming interface
 * @export
 */
export const CapabilitiesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary The list of capabilities for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCapabilities(options?: any): (basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = CapabilitiesApiAxiosParamCreator(configuration).getUserCapabilities(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * CapabilitiesApi - factory interface
 * @export
 */
export const CapabilitiesApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary The list of capabilities for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCapabilities(options?: any) {
            return CapabilitiesApiFp(configuration).getUserCapabilities(options)(basePath);
        },
    };
};

/**
 * CapabilitiesApi - object-oriented interface
 * @export
 * @class CapabilitiesApi
 * @extends {BaseAPI}
 */
export class CapabilitiesApi extends BaseAPI {
    /**
     * 
     * @summary The list of capabilities for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CapabilitiesApi
     */
    public getUserCapabilities(options?: any) {
        return CapabilitiesApiFp(this.configuration).getUserCapabilities(options)(this.basePath);
    }

}

/**
 * CountryApi - axios parameter creator
 * @export
 */
export const CountryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns an array of the available countries
         * @summary Retrieves all available countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries(options: any = {}): RequestArgs {
            const localVarPath = `/countries`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryApi - functional programming interface
 * @export
 */
export const CountryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Returns an array of the available countries
         * @summary Retrieves all available countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries(options?: any): (basePath?: string) => AxiosPromise<Array<Country>> {
            const localVarAxiosArgs = CountryApiAxiosParamCreator(configuration).getCountries(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * CountryApi - factory interface
 * @export
 */
export const CountryApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * Returns an array of the available countries
         * @summary Retrieves all available countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries(options?: any) {
            return CountryApiFp(configuration).getCountries(options)(basePath);
        },
    };
};

/**
 * CountryApi - object-oriented interface
 * @export
 * @class CountryApi
 * @extends {BaseAPI}
 */
export class CountryApi extends BaseAPI {
    /**
     * Returns an array of the available countries
     * @summary Retrieves all available countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public getCountries(options?: any) {
        return CountryApiFp(this.configuration).getCountries(options)(this.basePath);
    }

}

/**
 * FieldsConfigurationApi - axios parameter creator
 * @export
 */
export const FieldsConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary The list of all the fields, with labels and translation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBaseFields(options: any = {}): RequestArgs {
            const localVarPath = `/fields`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The list of all the report fields, with labels, translations and sections
         * @param {number} applicationTypeId 
         * @param {DutyMode} [dutyMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportFields(applicationTypeId: number, dutyMode?: DutyMode, options: any = {}): RequestArgs {
            // verify required parameter 'applicationTypeId' is not null or undefined
            if (applicationTypeId === null || applicationTypeId === undefined) {
                throw new RequiredError('applicationTypeId','Required parameter applicationTypeId was null or undefined when calling getReportFields.');
            }
            const localVarPath = `/report-fields`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (applicationTypeId !== undefined) {
                localVarQueryParameter['applicationTypeId'] = applicationTypeId;
            }

            if (dutyMode !== undefined) {
                localVarQueryParameter['dutyMode'] = dutyMode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The list of all the results table fields, with labels, translations and sections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResultsTableFields(options: any = {}): RequestArgs {
            const localVarPath = `/result-fields`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FieldsConfigurationApi - functional programming interface
 * @export
 */
export const FieldsConfigurationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary The list of all the fields, with labels and translation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBaseFields(options?: any): (basePath?: string) => AxiosPromise<Array<BaseField>> {
            const localVarAxiosArgs = FieldsConfigurationApiAxiosParamCreator(configuration).getBaseFields(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary The list of all the report fields, with labels, translations and sections
         * @param {number} applicationTypeId 
         * @param {DutyMode} [dutyMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportFields(applicationTypeId: number, dutyMode?: DutyMode, options?: any): (basePath?: string) => AxiosPromise<Array<ReportSection>> {
            const localVarAxiosArgs = FieldsConfigurationApiAxiosParamCreator(configuration).getReportFields(applicationTypeId, dutyMode, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary The list of all the results table fields, with labels, translations and sections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResultsTableFields(options?: any): (basePath?: string) => AxiosPromise<Array<ResultField>> {
            const localVarAxiosArgs = FieldsConfigurationApiAxiosParamCreator(configuration).getResultsTableFields(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * FieldsConfigurationApi - factory interface
 * @export
 */
export const FieldsConfigurationApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary The list of all the fields, with labels and translation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBaseFields(options?: any) {
            return FieldsConfigurationApiFp(configuration).getBaseFields(options)(basePath);
        },
        /**
         * 
         * @summary The list of all the report fields, with labels, translations and sections
         * @param {number} applicationTypeId 
         * @param {DutyMode} [dutyMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportFields(applicationTypeId: number, dutyMode?: DutyMode, options?: any) {
            return FieldsConfigurationApiFp(configuration).getReportFields(applicationTypeId, dutyMode, options)(basePath);
        },
        /**
         * 
         * @summary The list of all the results table fields, with labels, translations and sections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResultsTableFields(options?: any) {
            return FieldsConfigurationApiFp(configuration).getResultsTableFields(options)(basePath);
        },
    };
};

/**
 * FieldsConfigurationApi - object-oriented interface
 * @export
 * @class FieldsConfigurationApi
 * @extends {BaseAPI}
 */
export class FieldsConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary The list of all the fields, with labels and translation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsConfigurationApi
     */
    public getBaseFields(options?: any) {
        return FieldsConfigurationApiFp(this.configuration).getBaseFields(options)(this.basePath);
    }

    /**
     * 
     * @summary The list of all the report fields, with labels, translations and sections
     * @param {number} applicationTypeId 
     * @param {DutyMode} [dutyMode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsConfigurationApi
     */
    public getReportFields(applicationTypeId: number, dutyMode?: DutyMode, options?: any) {
        return FieldsConfigurationApiFp(this.configuration).getReportFields(applicationTypeId, dutyMode, options)(this.basePath);
    }

    /**
     * 
     * @summary The list of all the results table fields, with labels, translations and sections
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsConfigurationApi
     */
    public getResultsTableFields(options?: any) {
        return FieldsConfigurationApiFp(this.configuration).getResultsTableFields(options)(this.basePath);
    }

}

/**
 * ItemApi - axios parameter creator
 * @export
 */
export const ItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Used to render the table in the page /results
         * @summary Gets the results of the calculation.
         * @param {number} itemId 
         * @param {boolean} [forceCalculate] Resets the results table and forces a new calculation
         * @param {boolean} [withMechanicalOptions] True if the response must include also the mechanical options data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateResultsTable(itemId: number, forceCalculate?: boolean, withMechanicalOptions?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling calculateResultsTable.');
            }
            const localVarPath = `/items/{itemId}/results-table`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (forceCalculate !== undefined) {
                localVarQueryParameter['forceCalculate'] = forceCalculate;
            }

            if (withMechanicalOptions !== undefined) {
                localVarQueryParameter['withMechanicalOptions'] = withMechanicalOptions;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * clones the item
         * @summary Clones the item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneItem(itemId: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling cloneItem.');
            }
            const localVarPath = `/items/{itemId}/clone`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Clones the item and switches the new item to the RATING mode, using the unitId passed as parameter
         * @summary Creates a new item in rating mode using the selected unit.
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneItemForRating(itemId: number, unitId: string, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling cloneItemForRating.');
            }
            // verify required parameter 'unitId' is not null or undefined
            if (unitId === null || unitId === undefined) {
                throw new RequiredError('unitId','Required parameter unitId was null or undefined when calling cloneItemForRating.');
            }
            const localVarPath = `/items/{itemId}/clone-for-rating`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (unitId !== undefined) {
                localVarQueryParameter['unitId'] = unitId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new item, based on chosen application type
         * @summary Creates a new item
         * @param {number} applicationTypeId 
         * @param {number} [projectId] the project id for the item
         * @param {number} [portfolioId] the portfolio id for the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItem(applicationTypeId: number, projectId?: number, portfolioId?: number, options: any = {}): RequestArgs {
            // verify required parameter 'applicationTypeId' is not null or undefined
            if (applicationTypeId === null || applicationTypeId === undefined) {
                throw new RequiredError('applicationTypeId','Required parameter applicationTypeId was null or undefined when calling createItem.');
            }
            const localVarPath = `/items`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (applicationTypeId !== undefined) {
                localVarQueryParameter['applicationTypeId'] = applicationTypeId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (portfolioId !== undefined) {
                localVarQueryParameter['portfolioId'] = portfolioId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes item
         * @summary Deletes an item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItem(itemId: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling deleteItem.');
            }
            const localVarPath = `/items/{itemId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Downloads a CSV containing DLL response fields, core calculated fields and mechanical calculated fields for one item with showPrices=true
         * @summary Generates a csv document of the item results
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportItemResults(itemId: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling exportItemResults.');
            }
            const localVarPath = `/items/{itemId}/results/csv`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Downloads a CSV containing DLL response fields, core calculated fields and mechanical fields for a list of items
         * @summary Generates a csv document of a list of item results
         * @param {Array<number>} itemIds A list of item ids of the same project
         * @param {boolean} [showPrices] to show the prices into the tech specs csv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportItemsResults(itemIds: Array<number>, showPrices?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'itemIds' is not null or undefined
            if (itemIds === null || itemIds === undefined) {
                throw new RequiredError('itemIds','Required parameter itemIds was null or undefined when calling exportItemsResults.');
            }
            const localVarPath = `/items/results/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (itemIds) {
                localVarQueryParameter['itemIds'] = itemIds;
            }

            if (showPrices !== undefined) {
                localVarQueryParameter['showPrices'] = showPrices;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the item details. Results can be null if they are invalid or never calculated before.
         * @summary Get item details
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemDetails(itemId: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getItemDetails.');
            }
            const localVarPath = `/items/{itemId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the visiblity rules for the results table for this item. DEPRECATED, use /results-table-fields + /item/{itemId}/results/settings instead
         * @summary Get the results config for this item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemResultsConfig(itemId: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getItemResultsConfig.');
            }
            const localVarPath = `/items/{itemId}/results-config`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return all user items
         * @summary Returns user items
         * @param {number} [projectId] used to filter all the items for the given project ID
         * @param {number} [page] the page number
         * @param {number} [size] the page size
         * @param {string} [sort] the field to be sorted (e.g.: lastModifiedDate,desc)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItems(projectId?: number, page?: number, size?: number, sort?: string, options: any = {}): RequestArgs {
            const localVarPath = `/items`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the structure of the form
         * @summary Gets the mechanical form structure
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMechanicalFormConfig(itemId: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getMechanicalFormConfig.');
            }
            const localVarPath = `/items/{itemId}/mechanical/form-config`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to render the table in the page /results
         * @summary Gets the results of the calculation.
         * @param {number} itemId 
         * @param {boolean} [forceCalculate] Force a new calculation (both thermal and mechanical)
         * @param {boolean} [withMechanicalOptions] True if the response must include also the mechanical options data
         * @param {boolean} [withLatestCoreVersion] True if the calculation has to be done with the latest DLL version. False to calculate using the same DLL version used for the previous calculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResults(itemId: number, forceCalculate?: boolean, withMechanicalOptions?: boolean, withLatestCoreVersion?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getResults.');
            }
            const localVarPath = `/items/{itemId}/results`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (forceCalculate !== undefined) {
                localVarQueryParameter['forceCalculate'] = forceCalculate;
            }

            if (withMechanicalOptions !== undefined) {
                localVarQueryParameter['withMechanicalOptions'] = withMechanicalOptions;
            }

            if (withLatestCoreVersion !== undefined) {
                localVarQueryParameter['withLatestCoreVersion'] = withLatestCoreVersion;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to render the table in the page /results
         * @summary Gets the results of the calculation.
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResultsTable(itemId: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getResultsTable.');
            }
            const localVarPath = `/items/{itemId}/results-table`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the structure of the form
         * @summary Gets the thermal form structure
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThermalFormConfig(itemId: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getThermalFormConfig.');
            }
            const localVarPath = `/items/{itemId}/thermal/form-config`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Execute cpq guided selling for the give item, returning an productId
         * @summary Execute cpq guided selling for the give item
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidedSelling(itemId: number, unitId: string, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling guidedSelling.');
            }
            // verify required parameter 'unitId' is not null or undefined
            if (unitId === null || unitId === undefined) {
                throw new RequiredError('unitId','Required parameter unitId was null or undefined when calling guidedSelling.');
            }
            const localVarPath = `/items/{itemId}/guided-selling`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (unitId !== undefined) {
                localVarQueryParameter['unitId'] = unitId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lock a unit
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockUnit(itemId: number, unitId: string, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling lockUnit.');
            }
            // verify required parameter 'unitId' is not null or undefined
            if (unitId === null || unitId === undefined) {
                throw new RequiredError('unitId','Required parameter unitId was null or undefined when calling lockUnit.');
            }
            const localVarPath = `/items/{itemId}/results/{unitId}/lock`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"unitId"}}`, encodeURIComponent(String(unitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset item configuration back to initial default values
         * @summary Reset item configuration
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetItemData(itemId: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling resetItemData.');
            }
            const localVarPath = `/items/{itemId}/reset`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save and Finish. Chooses one of the units in the results list.
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveItemResults(itemId: number, unitId: string, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling saveItemResults.');
            }
            // verify required parameter 'unitId' is not null or undefined
            if (unitId === null || unitId === undefined) {
                throw new RequiredError('unitId','Required parameter unitId was null or undefined when calling saveItemResults.');
            }
            const localVarPath = `/items/{itemId}/results`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (unitId !== undefined) {
                localVarQueryParameter['unitId'] = unitId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Select a unit in the results table
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectResultUnit(itemId: number, unitId: string, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling selectResultUnit.');
            }
            // verify required parameter 'unitId' is not null or undefined
            if (unitId === null || unitId === undefined) {
                throw new RequiredError('unitId','Required parameter unitId was null or undefined when calling selectResultUnit.');
            }
            const localVarPath = `/items/{itemId}/results/{unitId}/select`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"unitId"}}`, encodeURIComponent(String(unitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlock all units of the results table
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockAllUnits(itemId: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling unlockAllUnits.');
            }
            const localVarPath = `/items/{itemId}/results/unlock-all`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlock a unit
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockUnit(itemId: number, unitId: string, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling unlockUnit.');
            }
            // verify required parameter 'unitId' is not null or undefined
            if (unitId === null || unitId === undefined) {
                throw new RequiredError('unitId','Required parameter unitId was null or undefined when calling unlockUnit.');
            }
            const localVarPath = `/items/{itemId}/results/{unitId}/unlock`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"unitId"}}`, encodeURIComponent(String(unitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates item configuration (thermal or mechanical data)
         * @summary Updates item configuration
         * @param {number} itemId 
         * @param {Item} item The item data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemConfiguration(itemId: number, item: Item, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling updateItemConfiguration.');
            }
            // verify required parameter 'item' is not null or undefined
            if (item === null || item === undefined) {
                throw new RequiredError('item','Required parameter item was null or undefined when calling updateItemConfiguration.');
            }
            const localVarPath = `/items/{itemId}/configuration`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Item" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(item || {}) : (item || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates both item basic info and thermal configuration. DEPRECATED: use updateItemInfo and updateItemConfiguration separately 
         * @summary DEPRECATED Updates item data
         * @param {number} itemId 
         * @param {Item} item The item data, needed to specify the project
         * @param {number} [projectId] The project id in which to move the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemData(itemId: number, item: Item, projectId?: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling updateItemData.');
            }
            // verify required parameter 'item' is not null or undefined
            if (item === null || item === undefined) {
                throw new RequiredError('item','Required parameter item was null or undefined when calling updateItemData.');
            }
            const localVarPath = `/items/{itemId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Item" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(item || {}) : (item || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to update item (label, position, note, project)
         * @summary Updates item basic info
         * @param {number} itemId 
         * @param {Item} item The item data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemInfo(itemId: number, item: Item, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling updateItemInfo.');
            }
            // verify required parameter 'item' is not null or undefined
            if (item === null || item === undefined) {
                throw new RequiredError('item','Required parameter item was null or undefined when calling updateItemInfo.');
            }
            const localVarPath = `/items/{itemId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Item" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(item || {}) : (item || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemApi - functional programming interface
 * @export
 */
export const ItemApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Used to render the table in the page /results
         * @summary Gets the results of the calculation.
         * @param {number} itemId 
         * @param {boolean} [forceCalculate] Resets the results table and forces a new calculation
         * @param {boolean} [withMechanicalOptions] True if the response must include also the mechanical options data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateResultsTable(itemId: number, forceCalculate?: boolean, withMechanicalOptions?: boolean, options?: any): (basePath?: string) => AxiosPromise<ItemResults> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).calculateResultsTable(itemId, forceCalculate, withMechanicalOptions, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * clones the item
         * @summary Clones the item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneItem(itemId: number, options?: any): (basePath?: string) => AxiosPromise<Item> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).cloneItem(itemId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Clones the item and switches the new item to the RATING mode, using the unitId passed as parameter
         * @summary Creates a new item in rating mode using the selected unit.
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneItemForRating(itemId: number, unitId: string, options?: any): (basePath?: string) => AxiosPromise<Item> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).cloneItemForRating(itemId, unitId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Creates a new item, based on chosen application type
         * @summary Creates a new item
         * @param {number} applicationTypeId 
         * @param {number} [projectId] the project id for the item
         * @param {number} [portfolioId] the portfolio id for the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItem(applicationTypeId: number, projectId?: number, portfolioId?: number, options?: any): (basePath?: string) => AxiosPromise<Item> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).createItem(applicationTypeId, projectId, portfolioId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Deletes item
         * @summary Deletes an item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItem(itemId: number, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).deleteItem(itemId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Downloads a CSV containing DLL response fields, core calculated fields and mechanical calculated fields for one item with showPrices=true
         * @summary Generates a csv document of the item results
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportItemResults(itemId: number, options?: any): (basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).exportItemResults(itemId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Downloads a CSV containing DLL response fields, core calculated fields and mechanical fields for a list of items
         * @summary Generates a csv document of a list of item results
         * @param {Array<number>} itemIds A list of item ids of the same project
         * @param {boolean} [showPrices] to show the prices into the tech specs csv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportItemsResults(itemIds: Array<number>, showPrices?: boolean, options?: any): (basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).exportItemsResults(itemIds, showPrices, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Returns the item details. Results can be null if they are invalid or never calculated before.
         * @summary Get item details
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemDetails(itemId: number, options?: any): (basePath?: string) => AxiosPromise<Item> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).getItemDetails(itemId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Returns the visiblity rules for the results table for this item. DEPRECATED, use /results-table-fields + /item/{itemId}/results/settings instead
         * @summary Get the results config for this item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemResultsConfig(itemId: number, options?: any): (basePath?: string) => AxiosPromise<ItemResultsConfig> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).getItemResultsConfig(itemId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Return all user items
         * @summary Returns user items
         * @param {number} [projectId] used to filter all the items for the given project ID
         * @param {number} [page] the page number
         * @param {number} [size] the page size
         * @param {string} [sort] the field to be sorted (e.g.: lastModifiedDate,desc)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItems(projectId?: number, page?: number, size?: number, sort?: string, options?: any): (basePath?: string) => AxiosPromise<Array<Item>> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).getItems(projectId, page, size, sort, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Returns the structure of the form
         * @summary Gets the mechanical form structure
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMechanicalFormConfig(itemId: number, options?: any): (basePath?: string) => AxiosPromise<FormConfig> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).getMechanicalFormConfig(itemId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Used to render the table in the page /results
         * @summary Gets the results of the calculation.
         * @param {number} itemId 
         * @param {boolean} [forceCalculate] Force a new calculation (both thermal and mechanical)
         * @param {boolean} [withMechanicalOptions] True if the response must include also the mechanical options data
         * @param {boolean} [withLatestCoreVersion] True if the calculation has to be done with the latest DLL version. False to calculate using the same DLL version used for the previous calculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResults(itemId: number, forceCalculate?: boolean, withMechanicalOptions?: boolean, withLatestCoreVersion?: boolean, options?: any): (basePath?: string) => AxiosPromise<ItemResults> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).getResults(itemId, forceCalculate, withMechanicalOptions, withLatestCoreVersion, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Used to render the table in the page /results
         * @summary Gets the results of the calculation.
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResultsTable(itemId: number, options?: any): (basePath?: string) => AxiosPromise<ItemResults> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).getResultsTable(itemId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Returns the structure of the form
         * @summary Gets the thermal form structure
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThermalFormConfig(itemId: number, options?: any): (basePath?: string) => AxiosPromise<FormConfig> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).getThermalFormConfig(itemId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Execute cpq guided selling for the give item, returning an productId
         * @summary Execute cpq guided selling for the give item
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidedSelling(itemId: number, unitId: string, options?: any): (basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).guidedSelling(itemId, unitId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Lock a unit
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockUnit(itemId: number, unitId: string, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).lockUnit(itemId, unitId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Reset item configuration back to initial default values
         * @summary Reset item configuration
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetItemData(itemId: number, options?: any): (basePath?: string) => AxiosPromise<Item> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).resetItemData(itemId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save and Finish. Chooses one of the units in the results list.
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveItemResults(itemId: number, unitId: string, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).saveItemResults(itemId, unitId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Select a unit in the results table
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectResultUnit(itemId: number, unitId: string, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).selectResultUnit(itemId, unitId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Unlock all units of the results table
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockAllUnits(itemId: number, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).unlockAllUnits(itemId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Unlock a unit
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockUnit(itemId: number, unitId: string, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).unlockUnit(itemId, unitId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Updates item configuration (thermal or mechanical data)
         * @summary Updates item configuration
         * @param {number} itemId 
         * @param {Item} item The item data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemConfiguration(itemId: number, item: Item, options?: any): (basePath?: string) => AxiosPromise<Item> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).updateItemConfiguration(itemId, item, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Updates both item basic info and thermal configuration. DEPRECATED: use updateItemInfo and updateItemConfiguration separately 
         * @summary DEPRECATED Updates item data
         * @param {number} itemId 
         * @param {Item} item The item data, needed to specify the project
         * @param {number} [projectId] The project id in which to move the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemData(itemId: number, item: Item, projectId?: number, options?: any): (basePath?: string) => AxiosPromise<Item> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).updateItemData(itemId, item, projectId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Used to update item (label, position, note, project)
         * @summary Updates item basic info
         * @param {number} itemId 
         * @param {Item} item The item data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemInfo(itemId: number, item: Item, options?: any): (basePath?: string) => AxiosPromise<Item> {
            const localVarAxiosArgs = ItemApiAxiosParamCreator(configuration).updateItemInfo(itemId, item, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * ItemApi - factory interface
 * @export
 */
export const ItemApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * Used to render the table in the page /results
         * @summary Gets the results of the calculation.
         * @param {number} itemId 
         * @param {boolean} [forceCalculate] Resets the results table and forces a new calculation
         * @param {boolean} [withMechanicalOptions] True if the response must include also the mechanical options data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateResultsTable(itemId: number, forceCalculate?: boolean, withMechanicalOptions?: boolean, options?: any) {
            return ItemApiFp(configuration).calculateResultsTable(itemId, forceCalculate, withMechanicalOptions, options)(basePath);
        },
        /**
         * clones the item
         * @summary Clones the item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneItem(itemId: number, options?: any) {
            return ItemApiFp(configuration).cloneItem(itemId, options)(basePath);
        },
        /**
         * Clones the item and switches the new item to the RATING mode, using the unitId passed as parameter
         * @summary Creates a new item in rating mode using the selected unit.
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneItemForRating(itemId: number, unitId: string, options?: any) {
            return ItemApiFp(configuration).cloneItemForRating(itemId, unitId, options)(basePath);
        },
        /**
         * Creates a new item, based on chosen application type
         * @summary Creates a new item
         * @param {number} applicationTypeId 
         * @param {number} [projectId] the project id for the item
         * @param {number} [portfolioId] the portfolio id for the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItem(applicationTypeId: number, projectId?: number, portfolioId?: number, options?: any) {
            return ItemApiFp(configuration).createItem(applicationTypeId, projectId, portfolioId, options)(basePath);
        },
        /**
         * Deletes item
         * @summary Deletes an item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItem(itemId: number, options?: any) {
            return ItemApiFp(configuration).deleteItem(itemId, options)(basePath);
        },
        /**
         * Downloads a CSV containing DLL response fields, core calculated fields and mechanical calculated fields for one item with showPrices=true
         * @summary Generates a csv document of the item results
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportItemResults(itemId: number, options?: any) {
            return ItemApiFp(configuration).exportItemResults(itemId, options)(basePath);
        },
        /**
         * Downloads a CSV containing DLL response fields, core calculated fields and mechanical fields for a list of items
         * @summary Generates a csv document of a list of item results
         * @param {Array<number>} itemIds A list of item ids of the same project
         * @param {boolean} [showPrices] to show the prices into the tech specs csv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportItemsResults(itemIds: Array<number>, showPrices?: boolean, options?: any) {
            return ItemApiFp(configuration).exportItemsResults(itemIds, showPrices, options)(basePath);
        },
        /**
         * Returns the item details. Results can be null if they are invalid or never calculated before.
         * @summary Get item details
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemDetails(itemId: number, options?: any) {
            return ItemApiFp(configuration).getItemDetails(itemId, options)(basePath);
        },
        /**
         * Returns the visiblity rules for the results table for this item. DEPRECATED, use /results-table-fields + /item/{itemId}/results/settings instead
         * @summary Get the results config for this item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemResultsConfig(itemId: number, options?: any) {
            return ItemApiFp(configuration).getItemResultsConfig(itemId, options)(basePath);
        },
        /**
         * Return all user items
         * @summary Returns user items
         * @param {number} [projectId] used to filter all the items for the given project ID
         * @param {number} [page] the page number
         * @param {number} [size] the page size
         * @param {string} [sort] the field to be sorted (e.g.: lastModifiedDate,desc)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItems(projectId?: number, page?: number, size?: number, sort?: string, options?: any) {
            return ItemApiFp(configuration).getItems(projectId, page, size, sort, options)(basePath);
        },
        /**
         * Returns the structure of the form
         * @summary Gets the mechanical form structure
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMechanicalFormConfig(itemId: number, options?: any) {
            return ItemApiFp(configuration).getMechanicalFormConfig(itemId, options)(basePath);
        },
        /**
         * Used to render the table in the page /results
         * @summary Gets the results of the calculation.
         * @param {number} itemId 
         * @param {boolean} [forceCalculate] Force a new calculation (both thermal and mechanical)
         * @param {boolean} [withMechanicalOptions] True if the response must include also the mechanical options data
         * @param {boolean} [withLatestCoreVersion] True if the calculation has to be done with the latest DLL version. False to calculate using the same DLL version used for the previous calculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResults(itemId: number, forceCalculate?: boolean, withMechanicalOptions?: boolean, withLatestCoreVersion?: boolean, options?: any) {
            return ItemApiFp(configuration).getResults(itemId, forceCalculate, withMechanicalOptions, withLatestCoreVersion, options)(basePath);
        },
        /**
         * Used to render the table in the page /results
         * @summary Gets the results of the calculation.
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResultsTable(itemId: number, options?: any) {
            return ItemApiFp(configuration).getResultsTable(itemId, options)(basePath);
        },
        /**
         * Returns the structure of the form
         * @summary Gets the thermal form structure
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThermalFormConfig(itemId: number, options?: any) {
            return ItemApiFp(configuration).getThermalFormConfig(itemId, options)(basePath);
        },
        /**
         * Execute cpq guided selling for the give item, returning an productId
         * @summary Execute cpq guided selling for the give item
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidedSelling(itemId: number, unitId: string, options?: any) {
            return ItemApiFp(configuration).guidedSelling(itemId, unitId, options)(basePath);
        },
        /**
         * 
         * @summary Lock a unit
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockUnit(itemId: number, unitId: string, options?: any) {
            return ItemApiFp(configuration).lockUnit(itemId, unitId, options)(basePath);
        },
        /**
         * Reset item configuration back to initial default values
         * @summary Reset item configuration
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetItemData(itemId: number, options?: any) {
            return ItemApiFp(configuration).resetItemData(itemId, options)(basePath);
        },
        /**
         * 
         * @summary Save and Finish. Chooses one of the units in the results list.
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveItemResults(itemId: number, unitId: string, options?: any) {
            return ItemApiFp(configuration).saveItemResults(itemId, unitId, options)(basePath);
        },
        /**
         * 
         * @summary Select a unit in the results table
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectResultUnit(itemId: number, unitId: string, options?: any) {
            return ItemApiFp(configuration).selectResultUnit(itemId, unitId, options)(basePath);
        },
        /**
         * 
         * @summary Unlock all units of the results table
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockAllUnits(itemId: number, options?: any) {
            return ItemApiFp(configuration).unlockAllUnits(itemId, options)(basePath);
        },
        /**
         * 
         * @summary Unlock a unit
         * @param {number} itemId 
         * @param {string} unitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockUnit(itemId: number, unitId: string, options?: any) {
            return ItemApiFp(configuration).unlockUnit(itemId, unitId, options)(basePath);
        },
        /**
         * Updates item configuration (thermal or mechanical data)
         * @summary Updates item configuration
         * @param {number} itemId 
         * @param {Item} item The item data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemConfiguration(itemId: number, item: Item, options?: any) {
            return ItemApiFp(configuration).updateItemConfiguration(itemId, item, options)(basePath);
        },
        /**
         * Updates both item basic info and thermal configuration. DEPRECATED: use updateItemInfo and updateItemConfiguration separately 
         * @summary DEPRECATED Updates item data
         * @param {number} itemId 
         * @param {Item} item The item data, needed to specify the project
         * @param {number} [projectId] The project id in which to move the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemData(itemId: number, item: Item, projectId?: number, options?: any) {
            return ItemApiFp(configuration).updateItemData(itemId, item, projectId, options)(basePath);
        },
        /**
         * Used to update item (label, position, note, project)
         * @summary Updates item basic info
         * @param {number} itemId 
         * @param {Item} item The item data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemInfo(itemId: number, item: Item, options?: any) {
            return ItemApiFp(configuration).updateItemInfo(itemId, item, options)(basePath);
        },
    };
};

/**
 * ItemApi - object-oriented interface
 * @export
 * @class ItemApi
 * @extends {BaseAPI}
 */
export class ItemApi extends BaseAPI {
    /**
     * Used to render the table in the page /results
     * @summary Gets the results of the calculation.
     * @param {number} itemId 
     * @param {boolean} [forceCalculate] Resets the results table and forces a new calculation
     * @param {boolean} [withMechanicalOptions] True if the response must include also the mechanical options data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public calculateResultsTable(itemId: number, forceCalculate?: boolean, withMechanicalOptions?: boolean, options?: any) {
        return ItemApiFp(this.configuration).calculateResultsTable(itemId, forceCalculate, withMechanicalOptions, options)(this.basePath);
    }

    /**
     * clones the item
     * @summary Clones the item
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public cloneItem(itemId: number, options?: any) {
        return ItemApiFp(this.configuration).cloneItem(itemId, options)(this.basePath);
    }

    /**
     * Clones the item and switches the new item to the RATING mode, using the unitId passed as parameter
     * @summary Creates a new item in rating mode using the selected unit.
     * @param {number} itemId 
     * @param {string} unitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public cloneItemForRating(itemId: number, unitId: string, options?: any) {
        return ItemApiFp(this.configuration).cloneItemForRating(itemId, unitId, options)(this.basePath);
    }

    /**
     * Creates a new item, based on chosen application type
     * @summary Creates a new item
     * @param {number} applicationTypeId 
     * @param {number} [projectId] the project id for the item
     * @param {number} [portfolioId] the portfolio id for the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public createItem(applicationTypeId: number, projectId?: number, portfolioId?: number, options?: any) {
        return ItemApiFp(this.configuration).createItem(applicationTypeId, projectId, portfolioId, options)(this.basePath);
    }

    /**
     * Deletes item
     * @summary Deletes an item
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public deleteItem(itemId: number, options?: any) {
        return ItemApiFp(this.configuration).deleteItem(itemId, options)(this.basePath);
    }

    /**
     * Downloads a CSV containing DLL response fields, core calculated fields and mechanical calculated fields for one item with showPrices=true
     * @summary Generates a csv document of the item results
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public exportItemResults(itemId: number, options?: any) {
        return ItemApiFp(this.configuration).exportItemResults(itemId, options)(this.basePath);
    }

    /**
     * Downloads a CSV containing DLL response fields, core calculated fields and mechanical fields for a list of items
     * @summary Generates a csv document of a list of item results
     * @param {Array<number>} itemIds A list of item ids of the same project
     * @param {boolean} [showPrices] to show the prices into the tech specs csv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public exportItemsResults(itemIds: Array<number>, showPrices?: boolean, options?: any) {
        return ItemApiFp(this.configuration).exportItemsResults(itemIds, showPrices, options)(this.basePath);
    }

    /**
     * Returns the item details. Results can be null if they are invalid or never calculated before.
     * @summary Get item details
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public getItemDetails(itemId: number, options?: any) {
        return ItemApiFp(this.configuration).getItemDetails(itemId, options)(this.basePath);
    }

    /**
     * Returns the visiblity rules for the results table for this item. DEPRECATED, use /results-table-fields + /item/{itemId}/results/settings instead
     * @summary Get the results config for this item
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public getItemResultsConfig(itemId: number, options?: any) {
        return ItemApiFp(this.configuration).getItemResultsConfig(itemId, options)(this.basePath);
    }

    /**
     * Return all user items
     * @summary Returns user items
     * @param {number} [projectId] used to filter all the items for the given project ID
     * @param {number} [page] the page number
     * @param {number} [size] the page size
     * @param {string} [sort] the field to be sorted (e.g.: lastModifiedDate,desc)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public getItems(projectId?: number, page?: number, size?: number, sort?: string, options?: any) {
        return ItemApiFp(this.configuration).getItems(projectId, page, size, sort, options)(this.basePath);
    }

    /**
     * Returns the structure of the form
     * @summary Gets the mechanical form structure
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public getMechanicalFormConfig(itemId: number, options?: any) {
        return ItemApiFp(this.configuration).getMechanicalFormConfig(itemId, options)(this.basePath);
    }

    /**
     * Used to render the table in the page /results
     * @summary Gets the results of the calculation.
     * @param {number} itemId 
     * @param {boolean} [forceCalculate] Force a new calculation (both thermal and mechanical)
     * @param {boolean} [withMechanicalOptions] True if the response must include also the mechanical options data
     * @param {boolean} [withLatestCoreVersion] True if the calculation has to be done with the latest DLL version. False to calculate using the same DLL version used for the previous calculation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public getResults(itemId: number, forceCalculate?: boolean, withMechanicalOptions?: boolean, withLatestCoreVersion?: boolean, options?: any) {
        return ItemApiFp(this.configuration).getResults(itemId, forceCalculate, withMechanicalOptions, withLatestCoreVersion, options)(this.basePath);
    }

    /**
     * Used to render the table in the page /results
     * @summary Gets the results of the calculation.
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public getResultsTable(itemId: number, options?: any) {
        return ItemApiFp(this.configuration).getResultsTable(itemId, options)(this.basePath);
    }

    /**
     * Returns the structure of the form
     * @summary Gets the thermal form structure
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public getThermalFormConfig(itemId: number, options?: any) {
        return ItemApiFp(this.configuration).getThermalFormConfig(itemId, options)(this.basePath);
    }

    /**
     * Execute cpq guided selling for the give item, returning an productId
     * @summary Execute cpq guided selling for the give item
     * @param {number} itemId 
     * @param {string} unitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public guidedSelling(itemId: number, unitId: string, options?: any) {
        return ItemApiFp(this.configuration).guidedSelling(itemId, unitId, options)(this.basePath);
    }

    /**
     * 
     * @summary Lock a unit
     * @param {number} itemId 
     * @param {string} unitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public lockUnit(itemId: number, unitId: string, options?: any) {
        return ItemApiFp(this.configuration).lockUnit(itemId, unitId, options)(this.basePath);
    }

    /**
     * Reset item configuration back to initial default values
     * @summary Reset item configuration
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public resetItemData(itemId: number, options?: any) {
        return ItemApiFp(this.configuration).resetItemData(itemId, options)(this.basePath);
    }

    /**
     * 
     * @summary Save and Finish. Chooses one of the units in the results list.
     * @param {number} itemId 
     * @param {string} unitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public saveItemResults(itemId: number, unitId: string, options?: any) {
        return ItemApiFp(this.configuration).saveItemResults(itemId, unitId, options)(this.basePath);
    }

    /**
     * 
     * @summary Select a unit in the results table
     * @param {number} itemId 
     * @param {string} unitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public selectResultUnit(itemId: number, unitId: string, options?: any) {
        return ItemApiFp(this.configuration).selectResultUnit(itemId, unitId, options)(this.basePath);
    }

    /**
     * 
     * @summary Unlock all units of the results table
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public unlockAllUnits(itemId: number, options?: any) {
        return ItemApiFp(this.configuration).unlockAllUnits(itemId, options)(this.basePath);
    }

    /**
     * 
     * @summary Unlock a unit
     * @param {number} itemId 
     * @param {string} unitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public unlockUnit(itemId: number, unitId: string, options?: any) {
        return ItemApiFp(this.configuration).unlockUnit(itemId, unitId, options)(this.basePath);
    }

    /**
     * Updates item configuration (thermal or mechanical data)
     * @summary Updates item configuration
     * @param {number} itemId 
     * @param {Item} item The item data, needed to specify the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public updateItemConfiguration(itemId: number, item: Item, options?: any) {
        return ItemApiFp(this.configuration).updateItemConfiguration(itemId, item, options)(this.basePath);
    }

    /**
     * Updates both item basic info and thermal configuration. DEPRECATED: use updateItemInfo and updateItemConfiguration separately 
     * @summary DEPRECATED Updates item data
     * @param {number} itemId 
     * @param {Item} item The item data, needed to specify the project
     * @param {number} [projectId] The project id in which to move the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public updateItemData(itemId: number, item: Item, projectId?: number, options?: any) {
        return ItemApiFp(this.configuration).updateItemData(itemId, item, projectId, options)(this.basePath);
    }

    /**
     * Used to update item (label, position, note, project)
     * @summary Updates item basic info
     * @param {number} itemId 
     * @param {Item} item The item data, needed to specify the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public updateItemInfo(itemId: number, item: Item, options?: any) {
        return ItemApiFp(this.configuration).updateItemInfo(itemId, item, options)(this.basePath);
    }

}

/**
 * ItemSettingsApi - axios parameter creator
 * @export
 */
export const ItemSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Bounds settings for the item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemBoundsSettings(itemId: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getItemBoundsSettings.');
            }
            const localVarPath = `/items/{itemId}/bounds/settings`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Generic settings for the item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemGenericSettings(itemId: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getItemGenericSettings.');
            }
            const localVarPath = `/items/{itemId}/generic-settings`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Get the settings for the fields of the results table
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemResultsTableSettings(itemId: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getItemResultsTableSettings.');
            }
            const localVarPath = `/items/{itemId}/results/settings`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the technical spec config for this item
         * @summary Technical Specification configs for this item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemTechnicalSpecificationSettings(itemId: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getItemTechnicalSpecificationSettings.');
            }
            const localVarPath = `/items/{itemId}/technical-specifications/settings`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the configuration for the item
         * @summary Customizes the generic Bounds for the item
         * @param {number} itemId 
         * @param {BoundsConfig} boundsConfig The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemBoundsSettings(itemId: number, boundsConfig: BoundsConfig, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling updateItemBoundsSettings.');
            }
            // verify required parameter 'boundsConfig' is not null or undefined
            if (boundsConfig === null || boundsConfig === undefined) {
                throw new RequiredError('boundsConfig','Required parameter boundsConfig was null or undefined when calling updateItemBoundsSettings.');
            }
            const localVarPath = `/items/{itemId}/bounds/settings`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BoundsConfig" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(boundsConfig || {}) : (boundsConfig || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the configuration for the item
         * @summary Customizes the generic settings for the item
         * @param {number} itemId 
         * @param {GenericSettings} genericSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemGenericSettings(itemId: number, genericSettings: GenericSettings, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling updateItemGenericSettings.');
            }
            // verify required parameter 'genericSettings' is not null or undefined
            if (genericSettings === null || genericSettings === undefined) {
                throw new RequiredError('genericSettings','Required parameter genericSettings was null or undefined when calling updateItemGenericSettings.');
            }
            const localVarPath = `/items/{itemId}/generic-settings`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GenericSettings" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(genericSettings || {}) : (genericSettings || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updated the configuration for the given item
         * @summary Customizes the visibility settings for the technical specifications
         * @param {number} itemId 
         * @param {ResultsTableSettings} resultsTableSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemResultsTableSettings(itemId: number, resultsTableSettings: ResultsTableSettings, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling updateItemResultsTableSettings.');
            }
            // verify required parameter 'resultsTableSettings' is not null or undefined
            if (resultsTableSettings === null || resultsTableSettings === undefined) {
                throw new RequiredError('resultsTableSettings','Required parameter resultsTableSettings was null or undefined when calling updateItemResultsTableSettings.');
            }
            const localVarPath = `/items/{itemId}/results/settings`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ResultsTableSettings" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resultsTableSettings || {}) : (resultsTableSettings || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updated the configuration for the given item
         * @summary Customizes Technical Specification
         * @param {number} itemId 
         * @param {TechnicalSpecificationConfig} technicalSpecificationConfig The item data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemTechnicalSpecificationSettings(itemId: number, technicalSpecificationConfig: TechnicalSpecificationConfig, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling updateItemTechnicalSpecificationSettings.');
            }
            // verify required parameter 'technicalSpecificationConfig' is not null or undefined
            if (technicalSpecificationConfig === null || technicalSpecificationConfig === undefined) {
                throw new RequiredError('technicalSpecificationConfig','Required parameter technicalSpecificationConfig was null or undefined when calling updateItemTechnicalSpecificationSettings.');
            }
            const localVarPath = `/items/{itemId}/technical-specifications/settings`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TechnicalSpecificationConfig" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(technicalSpecificationConfig || {}) : (technicalSpecificationConfig || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemSettingsApi - functional programming interface
 * @export
 */
export const ItemSettingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Bounds settings for the item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemBoundsSettings(itemId: number, options?: any): (basePath?: string) => AxiosPromise<BoundsConfig> {
            const localVarAxiosArgs = ItemSettingsApiAxiosParamCreator(configuration).getItemBoundsSettings(itemId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Generic settings for the item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemGenericSettings(itemId: number, options?: any): (basePath?: string) => AxiosPromise<GenericSettings> {
            const localVarAxiosArgs = ItemSettingsApiAxiosParamCreator(configuration).getItemGenericSettings(itemId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Get the settings for the fields of the results table
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemResultsTableSettings(itemId: number, options?: any): (basePath?: string) => AxiosPromise<ResultsTableSettings> {
            const localVarAxiosArgs = ItemSettingsApiAxiosParamCreator(configuration).getItemResultsTableSettings(itemId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Returns the technical spec config for this item
         * @summary Technical Specification configs for this item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemTechnicalSpecificationSettings(itemId: number, options?: any): (basePath?: string) => AxiosPromise<TechnicalSpecificationConfig> {
            const localVarAxiosArgs = ItemSettingsApiAxiosParamCreator(configuration).getItemTechnicalSpecificationSettings(itemId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Updates the configuration for the item
         * @summary Customizes the generic Bounds for the item
         * @param {number} itemId 
         * @param {BoundsConfig} boundsConfig The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemBoundsSettings(itemId: number, boundsConfig: BoundsConfig, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ItemSettingsApiAxiosParamCreator(configuration).updateItemBoundsSettings(itemId, boundsConfig, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Updates the configuration for the item
         * @summary Customizes the generic settings for the item
         * @param {number} itemId 
         * @param {GenericSettings} genericSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemGenericSettings(itemId: number, genericSettings: GenericSettings, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ItemSettingsApiAxiosParamCreator(configuration).updateItemGenericSettings(itemId, genericSettings, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Updated the configuration for the given item
         * @summary Customizes the visibility settings for the technical specifications
         * @param {number} itemId 
         * @param {ResultsTableSettings} resultsTableSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemResultsTableSettings(itemId: number, resultsTableSettings: ResultsTableSettings, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ItemSettingsApiAxiosParamCreator(configuration).updateItemResultsTableSettings(itemId, resultsTableSettings, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Updated the configuration for the given item
         * @summary Customizes Technical Specification
         * @param {number} itemId 
         * @param {TechnicalSpecificationConfig} technicalSpecificationConfig The item data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemTechnicalSpecificationSettings(itemId: number, technicalSpecificationConfig: TechnicalSpecificationConfig, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ItemSettingsApiAxiosParamCreator(configuration).updateItemTechnicalSpecificationSettings(itemId, technicalSpecificationConfig, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * ItemSettingsApi - factory interface
 * @export
 */
export const ItemSettingsApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Bounds settings for the item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemBoundsSettings(itemId: number, options?: any) {
            return ItemSettingsApiFp(configuration).getItemBoundsSettings(itemId, options)(basePath);
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Generic settings for the item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemGenericSettings(itemId: number, options?: any) {
            return ItemSettingsApiFp(configuration).getItemGenericSettings(itemId, options)(basePath);
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Get the settings for the fields of the results table
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemResultsTableSettings(itemId: number, options?: any) {
            return ItemSettingsApiFp(configuration).getItemResultsTableSettings(itemId, options)(basePath);
        },
        /**
         * Returns the technical spec config for this item
         * @summary Technical Specification configs for this item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemTechnicalSpecificationSettings(itemId: number, options?: any) {
            return ItemSettingsApiFp(configuration).getItemTechnicalSpecificationSettings(itemId, options)(basePath);
        },
        /**
         * Updates the configuration for the item
         * @summary Customizes the generic Bounds for the item
         * @param {number} itemId 
         * @param {BoundsConfig} boundsConfig The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemBoundsSettings(itemId: number, boundsConfig: BoundsConfig, options?: any) {
            return ItemSettingsApiFp(configuration).updateItemBoundsSettings(itemId, boundsConfig, options)(basePath);
        },
        /**
         * Updates the configuration for the item
         * @summary Customizes the generic settings for the item
         * @param {number} itemId 
         * @param {GenericSettings} genericSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemGenericSettings(itemId: number, genericSettings: GenericSettings, options?: any) {
            return ItemSettingsApiFp(configuration).updateItemGenericSettings(itemId, genericSettings, options)(basePath);
        },
        /**
         * Updated the configuration for the given item
         * @summary Customizes the visibility settings for the technical specifications
         * @param {number} itemId 
         * @param {ResultsTableSettings} resultsTableSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemResultsTableSettings(itemId: number, resultsTableSettings: ResultsTableSettings, options?: any) {
            return ItemSettingsApiFp(configuration).updateItemResultsTableSettings(itemId, resultsTableSettings, options)(basePath);
        },
        /**
         * Updated the configuration for the given item
         * @summary Customizes Technical Specification
         * @param {number} itemId 
         * @param {TechnicalSpecificationConfig} technicalSpecificationConfig The item data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemTechnicalSpecificationSettings(itemId: number, technicalSpecificationConfig: TechnicalSpecificationConfig, options?: any) {
            return ItemSettingsApiFp(configuration).updateItemTechnicalSpecificationSettings(itemId, technicalSpecificationConfig, options)(basePath);
        },
    };
};

/**
 * ItemSettingsApi - object-oriented interface
 * @export
 * @class ItemSettingsApi
 * @extends {BaseAPI}
 */
export class ItemSettingsApi extends BaseAPI {
    /**
     * Specifies the order and the visibility of the fields in the results table
     * @summary Bounds settings for the item
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemSettingsApi
     */
    public getItemBoundsSettings(itemId: number, options?: any) {
        return ItemSettingsApiFp(this.configuration).getItemBoundsSettings(itemId, options)(this.basePath);
    }

    /**
     * Specifies the order and the visibility of the fields in the results table
     * @summary Generic settings for the item
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemSettingsApi
     */
    public getItemGenericSettings(itemId: number, options?: any) {
        return ItemSettingsApiFp(this.configuration).getItemGenericSettings(itemId, options)(this.basePath);
    }

    /**
     * Specifies the order and the visibility of the fields in the results table
     * @summary Get the settings for the fields of the results table
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemSettingsApi
     */
    public getItemResultsTableSettings(itemId: number, options?: any) {
        return ItemSettingsApiFp(this.configuration).getItemResultsTableSettings(itemId, options)(this.basePath);
    }

    /**
     * Returns the technical spec config for this item
     * @summary Technical Specification configs for this item
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemSettingsApi
     */
    public getItemTechnicalSpecificationSettings(itemId: number, options?: any) {
        return ItemSettingsApiFp(this.configuration).getItemTechnicalSpecificationSettings(itemId, options)(this.basePath);
    }

    /**
     * Updates the configuration for the item
     * @summary Customizes the generic Bounds for the item
     * @param {number} itemId 
     * @param {BoundsConfig} boundsConfig The updated settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemSettingsApi
     */
    public updateItemBoundsSettings(itemId: number, boundsConfig: BoundsConfig, options?: any) {
        return ItemSettingsApiFp(this.configuration).updateItemBoundsSettings(itemId, boundsConfig, options)(this.basePath);
    }

    /**
     * Updates the configuration for the item
     * @summary Customizes the generic settings for the item
     * @param {number} itemId 
     * @param {GenericSettings} genericSettings The updated settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemSettingsApi
     */
    public updateItemGenericSettings(itemId: number, genericSettings: GenericSettings, options?: any) {
        return ItemSettingsApiFp(this.configuration).updateItemGenericSettings(itemId, genericSettings, options)(this.basePath);
    }

    /**
     * Updated the configuration for the given item
     * @summary Customizes the visibility settings for the technical specifications
     * @param {number} itemId 
     * @param {ResultsTableSettings} resultsTableSettings The updated settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemSettingsApi
     */
    public updateItemResultsTableSettings(itemId: number, resultsTableSettings: ResultsTableSettings, options?: any) {
        return ItemSettingsApiFp(this.configuration).updateItemResultsTableSettings(itemId, resultsTableSettings, options)(this.basePath);
    }

    /**
     * Updated the configuration for the given item
     * @summary Customizes Technical Specification
     * @param {number} itemId 
     * @param {TechnicalSpecificationConfig} technicalSpecificationConfig The item data, needed to specify the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemSettingsApi
     */
    public updateItemTechnicalSpecificationSettings(itemId: number, technicalSpecificationConfig: TechnicalSpecificationConfig, options?: any) {
        return ItemSettingsApiFp(this.configuration).updateItemTechnicalSpecificationSettings(itemId, technicalSpecificationConfig, options)(this.basePath);
    }

}

/**
 * ItemTechnicalSpecificationApi - axios parameter creator
 * @export
 */
export const ItemTechnicalSpecificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Renders the technical specification pdf.
         * @summary Generates the tech specs for the given item.
         * @param {number} itemId 
         * @param {string} [unitId] the unit for which the pdf is needed, if empty all the units are considered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemTechnicalSpecificationPdf(itemId: number, unitId?: string, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getItemTechnicalSpecificationPdf.');
            }
            const localVarPath = `/items/{itemId}/technical-specifications`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (unitId !== undefined) {
                localVarQueryParameter['unitId'] = unitId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to render the pdf. All the items must be part of the same project
         * @summary Generates the tech specs for all the item in the list.
         * @param {Array<number>} itemIds a list of item ids, part of the same project
         * @param {boolean} [showPrices] to show the prices into the tech specs pdf
         * @param {boolean} [buildOffer] true to show the offer details, default to false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemsTechnicalSpecifications(itemIds: Array<number>, showPrices?: boolean, buildOffer?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'itemIds' is not null or undefined
            if (itemIds === null || itemIds === undefined) {
                throw new RequiredError('itemIds','Required parameter itemIds was null or undefined when calling getItemsTechnicalSpecifications.');
            }
            const localVarPath = `/items/technical-specifications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (itemIds) {
                localVarQueryParameter['itemIds'] = itemIds;
            }

            if (showPrices !== undefined) {
                localVarQueryParameter['showPrices'] = showPrices;
            }

            if (buildOffer !== undefined) {
                localVarQueryParameter['buildOffer'] = buildOffer;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemTechnicalSpecificationApi - functional programming interface
 * @export
 */
export const ItemTechnicalSpecificationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Renders the technical specification pdf.
         * @summary Generates the tech specs for the given item.
         * @param {number} itemId 
         * @param {string} [unitId] the unit for which the pdf is needed, if empty all the units are considered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemTechnicalSpecificationPdf(itemId: number, unitId?: string, options?: any): (basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = ItemTechnicalSpecificationApiAxiosParamCreator(configuration).getItemTechnicalSpecificationPdf(itemId, unitId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Used to render the pdf. All the items must be part of the same project
         * @summary Generates the tech specs for all the item in the list.
         * @param {Array<number>} itemIds a list of item ids, part of the same project
         * @param {boolean} [showPrices] to show the prices into the tech specs pdf
         * @param {boolean} [buildOffer] true to show the offer details, default to false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemsTechnicalSpecifications(itemIds: Array<number>, showPrices?: boolean, buildOffer?: boolean, options?: any): (basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = ItemTechnicalSpecificationApiAxiosParamCreator(configuration).getItemsTechnicalSpecifications(itemIds, showPrices, buildOffer, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * ItemTechnicalSpecificationApi - factory interface
 * @export
 */
export const ItemTechnicalSpecificationApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * Renders the technical specification pdf.
         * @summary Generates the tech specs for the given item.
         * @param {number} itemId 
         * @param {string} [unitId] the unit for which the pdf is needed, if empty all the units are considered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemTechnicalSpecificationPdf(itemId: number, unitId?: string, options?: any) {
            return ItemTechnicalSpecificationApiFp(configuration).getItemTechnicalSpecificationPdf(itemId, unitId, options)(basePath);
        },
        /**
         * Used to render the pdf. All the items must be part of the same project
         * @summary Generates the tech specs for all the item in the list.
         * @param {Array<number>} itemIds a list of item ids, part of the same project
         * @param {boolean} [showPrices] to show the prices into the tech specs pdf
         * @param {boolean} [buildOffer] true to show the offer details, default to false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemsTechnicalSpecifications(itemIds: Array<number>, showPrices?: boolean, buildOffer?: boolean, options?: any) {
            return ItemTechnicalSpecificationApiFp(configuration).getItemsTechnicalSpecifications(itemIds, showPrices, buildOffer, options)(basePath);
        },
    };
};

/**
 * ItemTechnicalSpecificationApi - object-oriented interface
 * @export
 * @class ItemTechnicalSpecificationApi
 * @extends {BaseAPI}
 */
export class ItemTechnicalSpecificationApi extends BaseAPI {
    /**
     * Renders the technical specification pdf.
     * @summary Generates the tech specs for the given item.
     * @param {number} itemId 
     * @param {string} [unitId] the unit for which the pdf is needed, if empty all the units are considered
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemTechnicalSpecificationApi
     */
    public getItemTechnicalSpecificationPdf(itemId: number, unitId?: string, options?: any) {
        return ItemTechnicalSpecificationApiFp(this.configuration).getItemTechnicalSpecificationPdf(itemId, unitId, options)(this.basePath);
    }

    /**
     * Used to render the pdf. All the items must be part of the same project
     * @summary Generates the tech specs for all the item in the list.
     * @param {Array<number>} itemIds a list of item ids, part of the same project
     * @param {boolean} [showPrices] to show the prices into the tech specs pdf
     * @param {boolean} [buildOffer] true to show the offer details, default to false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemTechnicalSpecificationApi
     */
    public getItemsTechnicalSpecifications(itemIds: Array<number>, showPrices?: boolean, buildOffer?: boolean, options?: any) {
        return ItemTechnicalSpecificationApiFp(this.configuration).getItemsTechnicalSpecifications(itemIds, showPrices, buildOffer, options)(this.basePath);
    }

}

/**
 * LanguageApi - axios parameter creator
 * @export
 */
export const LanguageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns all the translation labels for the user language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelTranslations(options: any = {}): RequestArgs {
            const localVarPath = `/label-translations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an array of the available languages
         * @summary Retrieves all available languages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguages(options: any = {}): RequestArgs {
            const localVarPath = `/languages`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LanguageApi - functional programming interface
 * @export
 */
export const LanguageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns all the translation labels for the user language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelTranslations(options?: any): (basePath?: string) => AxiosPromise<{ [key: string]: string; }> {
            const localVarAxiosArgs = LanguageApiAxiosParamCreator(configuration).getLabelTranslations(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Returns an array of the available languages
         * @summary Retrieves all available languages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguages(options?: any): (basePath?: string) => AxiosPromise<Array<Language>> {
            const localVarAxiosArgs = LanguageApiAxiosParamCreator(configuration).getLanguages(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * LanguageApi - factory interface
 * @export
 */
export const LanguageApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary Returns all the translation labels for the user language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelTranslations(options?: any) {
            return LanguageApiFp(configuration).getLabelTranslations(options)(basePath);
        },
        /**
         * Returns an array of the available languages
         * @summary Retrieves all available languages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguages(options?: any) {
            return LanguageApiFp(configuration).getLanguages(options)(basePath);
        },
    };
};

/**
 * LanguageApi - object-oriented interface
 * @export
 * @class LanguageApi
 * @extends {BaseAPI}
 */
export class LanguageApi extends BaseAPI {
    /**
     * 
     * @summary Returns all the translation labels for the user language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public getLabelTranslations(options?: any) {
        return LanguageApiFp(this.configuration).getLabelTranslations(options)(this.basePath);
    }

    /**
     * Returns an array of the available languages
     * @summary Retrieves all available languages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public getLanguages(options?: any) {
        return LanguageApiFp(this.configuration).getLanguages(options)(this.basePath);
    }

}

/**
 * LoggingApi - axios parameter creator
 * @export
 */
export const LoggingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Sends a log message to the endpoint
         * @summary Logging endpoint for Plair clients
         * @param {LogMessage} logMessage The log information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientLog(logMessage: LogMessage, options: any = {}): RequestArgs {
            // verify required parameter 'logMessage' is not null or undefined
            if (logMessage === null || logMessage === undefined) {
                throw new RequiredError('logMessage','Required parameter logMessage was null or undefined when calling clientLog.');
            }
            const localVarPath = `/log`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LogMessage" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(logMessage || {}) : (logMessage || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoggingApi - functional programming interface
 * @export
 */
export const LoggingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Sends a log message to the endpoint
         * @summary Logging endpoint for Plair clients
         * @param {LogMessage} logMessage The log information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientLog(logMessage: LogMessage, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = LoggingApiAxiosParamCreator(configuration).clientLog(logMessage, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * LoggingApi - factory interface
 * @export
 */
export const LoggingApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * Sends a log message to the endpoint
         * @summary Logging endpoint for Plair clients
         * @param {LogMessage} logMessage The log information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientLog(logMessage: LogMessage, options?: any) {
            return LoggingApiFp(configuration).clientLog(logMessage, options)(basePath);
        },
    };
};

/**
 * LoggingApi - object-oriented interface
 * @export
 * @class LoggingApi
 * @extends {BaseAPI}
 */
export class LoggingApi extends BaseAPI {
    /**
     * Sends a log message to the endpoint
     * @summary Logging endpoint for Plair clients
     * @param {LogMessage} logMessage The log information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggingApi
     */
    public clientLog(logMessage: LogMessage, options?: any) {
        return LoggingApiFp(this.configuration).clientLog(logMessage, options)(this.basePath);
    }

}

/**
 * MechanicalDataApi - axios parameter creator
 * @export
 */
export const MechanicalDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve the actions for each mechanical form option, if any
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMechanicalFieldOptionActions(options: any = {}): RequestArgs {
            const localVarPath = `/mechanical/field-option-actions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve all the translated labels for the mechanical fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMechanicalFieldOptionLabels(options: any = {}): RequestArgs {
            const localVarPath = `/mechanical/field-option-labels`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MechanicalDataApi - functional programming interface
 * @export
 */
export const MechanicalDataApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve the actions for each mechanical form option, if any
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMechanicalFieldOptionActions(options?: any): (basePath?: string) => AxiosPromise<Array<FieldOptionAction>> {
            const localVarAxiosArgs = MechanicalDataApiAxiosParamCreator(configuration).getMechanicalFieldOptionActions(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Retrieve all the translated labels for the mechanical fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMechanicalFieldOptionLabels(options?: any): (basePath?: string) => AxiosPromise<OptionLabels> {
            const localVarAxiosArgs = MechanicalDataApiAxiosParamCreator(configuration).getMechanicalFieldOptionLabels(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * MechanicalDataApi - factory interface
 * @export
 */
export const MechanicalDataApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary Retrieve the actions for each mechanical form option, if any
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMechanicalFieldOptionActions(options?: any) {
            return MechanicalDataApiFp(configuration).getMechanicalFieldOptionActions(options)(basePath);
        },
        /**
         * 
         * @summary Retrieve all the translated labels for the mechanical fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMechanicalFieldOptionLabels(options?: any) {
            return MechanicalDataApiFp(configuration).getMechanicalFieldOptionLabels(options)(basePath);
        },
    };
};

/**
 * MechanicalDataApi - object-oriented interface
 * @export
 * @class MechanicalDataApi
 * @extends {BaseAPI}
 */
export class MechanicalDataApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve the actions for each mechanical form option, if any
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MechanicalDataApi
     */
    public getMechanicalFieldOptionActions(options?: any) {
        return MechanicalDataApiFp(this.configuration).getMechanicalFieldOptionActions(options)(this.basePath);
    }

    /**
     * 
     * @summary Retrieve all the translated labels for the mechanical fields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MechanicalDataApi
     */
    public getMechanicalFieldOptionLabels(options?: any) {
        return MechanicalDataApiFp(this.configuration).getMechanicalFieldOptionLabels(options)(this.basePath);
    }

}

/**
 * PortfolioApi - axios parameter creator
 * @export
 */
export const PortfolioApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the list of all available portfolios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPortfolio(options: any = {}): RequestArgs {
            const localVarPath = `/portfolio`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list of all available portfolios
         * @param {number} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioUtilities(portfolioId: number, options: any = {}): RequestArgs {
            // verify required parameter 'portfolioId' is not null or undefined
            if (portfolioId === null || portfolioId === undefined) {
                throw new RequiredError('portfolioId','Required parameter portfolioId was null or undefined when calling getPortfolioUtilities.');
            }
            const localVarPath = `/portfolio/{portfolioId}/utilities`
                .replace(`{${"portfolioId"}}`, encodeURIComponent(String(portfolioId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioApi - functional programming interface
 * @export
 */
export const PortfolioApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the list of all available portfolios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPortfolio(options?: any): (basePath?: string) => AxiosPromise<Array<Portfolio>> {
            const localVarAxiosArgs = PortfolioApiAxiosParamCreator(configuration).getAllPortfolio(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Returns the list of all available portfolios
         * @param {number} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioUtilities(portfolioId: number, options?: any): (basePath?: string) => AxiosPromise<Array<PortfolioUtility>> {
            const localVarAxiosArgs = PortfolioApiAxiosParamCreator(configuration).getPortfolioUtilities(portfolioId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * PortfolioApi - factory interface
 * @export
 */
export const PortfolioApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary Returns the list of all available portfolios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPortfolio(options?: any) {
            return PortfolioApiFp(configuration).getAllPortfolio(options)(basePath);
        },
        /**
         * 
         * @summary Returns the list of all available portfolios
         * @param {number} portfolioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortfolioUtilities(portfolioId: number, options?: any) {
            return PortfolioApiFp(configuration).getPortfolioUtilities(portfolioId, options)(basePath);
        },
    };
};

/**
 * PortfolioApi - object-oriented interface
 * @export
 * @class PortfolioApi
 * @extends {BaseAPI}
 */
export class PortfolioApi extends BaseAPI {
    /**
     * 
     * @summary Returns the list of all available portfolios
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public getAllPortfolio(options?: any) {
        return PortfolioApiFp(this.configuration).getAllPortfolio(options)(this.basePath);
    }

    /**
     * 
     * @summary Returns the list of all available portfolios
     * @param {number} portfolioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioApi
     */
    public getPortfolioUtilities(portfolioId: number, options?: any) {
        return PortfolioApiFp(this.configuration).getPortfolioUtilities(portfolioId, options)(this.basePath);
    }

}

/**
 * PriceListApi - axios parameter creator
 * @export
 */
export const PriceListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns an array of the available subdivisions
         * @summary Retrieves current price list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPriceList(options: any = {}): RequestArgs {
            const localVarPath = `/price-list/current`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PriceListApi - functional programming interface
 * @export
 */
export const PriceListApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Returns an array of the available subdivisions
         * @summary Retrieves current price list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPriceList(options?: any): (basePath?: string) => AxiosPromise<PriceList> {
            const localVarAxiosArgs = PriceListApiAxiosParamCreator(configuration).getCurrentPriceList(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * PriceListApi - factory interface
 * @export
 */
export const PriceListApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * Returns an array of the available subdivisions
         * @summary Retrieves current price list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPriceList(options?: any) {
            return PriceListApiFp(configuration).getCurrentPriceList(options)(basePath);
        },
    };
};

/**
 * PriceListApi - object-oriented interface
 * @export
 * @class PriceListApi
 * @extends {BaseAPI}
 */
export class PriceListApi extends BaseAPI {
    /**
     * Returns an array of the available subdivisions
     * @summary Retrieves current price list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceListApi
     */
    public getCurrentPriceList(options?: any) {
        return PriceListApiFp(this.configuration).getCurrentPriceList(options)(this.basePath);
    }

}

/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clones a project along with its items
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneProject(projectId: number, options: any = {}): RequestArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling cloneProject.');
            }
            const localVarPath = `/projects/{projectId}/clone`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new project
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(project?: Project, options: any = {}): RequestArgs {
            const localVarPath = `/projects`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Project" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(project || {}) : (project || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId: number, options: any = {}): RequestArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteProject.');
            }
            const localVarPath = `/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets user projects
         * @param {number} [page] the page number
         * @param {number} [size] the page size
         * @param {string} [sort] the field to be sorted (e.g.: lastModifiedDate,desc)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjects(page?: number, size?: number, sort?: string, options: any = {}): RequestArgs {
            const localVarPath = `/projects`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a project along with its items
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDetails(projectId: number, options: any = {}): RequestArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getProjectDetails.');
            }
            const localVarPath = `/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a project
         * @param {number} projectId 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId: number, project?: Project, options: any = {}): RequestArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling updateProject.');
            }
            const localVarPath = `/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Project" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(project || {}) : (project || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clones a project along with its items
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneProject(projectId: number, options?: any): (basePath?: string) => AxiosPromise<Project> {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).cloneProject(projectId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Creates a new project
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(project?: Project, options?: any): (basePath?: string) => AxiosPromise<Project> {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).createProject(project, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Deletes a project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId: number, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).deleteProject(projectId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Gets user projects
         * @param {number} [page] the page number
         * @param {number} [size] the page size
         * @param {string} [sort] the field to be sorted (e.g.: lastModifiedDate,desc)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjects(page?: number, size?: number, sort?: string, options?: any): (basePath?: string) => AxiosPromise<Array<Project>> {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getAllProjects(page, size, sort, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Gets a project along with its items
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDetails(projectId: number, options?: any): (basePath?: string) => AxiosPromise<Project> {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getProjectDetails(projectId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Updates a project
         * @param {number} projectId 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId: number, project?: Project, options?: any): (basePath?: string) => AxiosPromise<Project> {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).updateProject(projectId, project, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary Clones a project along with its items
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneProject(projectId: number, options?: any) {
            return ProjectApiFp(configuration).cloneProject(projectId, options)(basePath);
        },
        /**
         * 
         * @summary Creates a new project
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(project?: Project, options?: any) {
            return ProjectApiFp(configuration).createProject(project, options)(basePath);
        },
        /**
         * 
         * @summary Deletes a project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId: number, options?: any) {
            return ProjectApiFp(configuration).deleteProject(projectId, options)(basePath);
        },
        /**
         * 
         * @summary Gets user projects
         * @param {number} [page] the page number
         * @param {number} [size] the page size
         * @param {string} [sort] the field to be sorted (e.g.: lastModifiedDate,desc)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjects(page?: number, size?: number, sort?: string, options?: any) {
            return ProjectApiFp(configuration).getAllProjects(page, size, sort, options)(basePath);
        },
        /**
         * 
         * @summary Gets a project along with its items
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDetails(projectId: number, options?: any) {
            return ProjectApiFp(configuration).getProjectDetails(projectId, options)(basePath);
        },
        /**
         * 
         * @summary Updates a project
         * @param {number} projectId 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId: number, project?: Project, options?: any) {
            return ProjectApiFp(configuration).updateProject(projectId, project, options)(basePath);
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @summary Clones a project along with its items
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public cloneProject(projectId: number, options?: any) {
        return ProjectApiFp(this.configuration).cloneProject(projectId, options)(this.basePath);
    }

    /**
     * 
     * @summary Creates a new project
     * @param {Project} [project] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public createProject(project?: Project, options?: any) {
        return ProjectApiFp(this.configuration).createProject(project, options)(this.basePath);
    }

    /**
     * 
     * @summary Deletes a project
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteProject(projectId: number, options?: any) {
        return ProjectApiFp(this.configuration).deleteProject(projectId, options)(this.basePath);
    }

    /**
     * 
     * @summary Gets user projects
     * @param {number} [page] the page number
     * @param {number} [size] the page size
     * @param {string} [sort] the field to be sorted (e.g.: lastModifiedDate,desc)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getAllProjects(page?: number, size?: number, sort?: string, options?: any) {
        return ProjectApiFp(this.configuration).getAllProjects(page, size, sort, options)(this.basePath);
    }

    /**
     * 
     * @summary Gets a project along with its items
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectDetails(projectId: number, options?: any) {
        return ProjectApiFp(this.configuration).getProjectDetails(projectId, options)(this.basePath);
    }

    /**
     * 
     * @summary Updates a project
     * @param {number} projectId 
     * @param {Project} [project] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public updateProject(projectId: number, project?: Project, options?: any) {
        return ProjectApiFp(this.configuration).updateProject(projectId, project, options)(this.basePath);
    }

}

/**
 * ProjectSettingsApi - axios parameter creator
 * @export
 */
export const ProjectSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the Bounds config for this project
         * @summary Bounds configs for this project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectBoundsSettings(projectId: number, options: any = {}): RequestArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getProjectBoundsSettings.');
            }
            const localVarPath = `/projects/{projectId}/bounds/settings`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Generic settings for the project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectGenericSettings(projectId: number, options: any = {}): RequestArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getProjectGenericSettings.');
            }
            const localVarPath = `/projects/{projectId}/generic-settings`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Get the settings for the fields of the results table
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectResultsTableSettings(projectId: number, options: any = {}): RequestArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getProjectResultsTableSettings.');
            }
            const localVarPath = `/projects/{projectId}/results/settings`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the technical spec config for this project
         * @summary Technical Specification configs for this project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTechnicalSpecificationsSettings(projectId: number, options: any = {}): RequestArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getProjectTechnicalSpecificationsSettings.');
            }
            const localVarPath = `/projects/{projectId}/technical-specifications/settings`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updated the configuration for the given project
         * @summary Customizes the visibility settings for the technical specifications
         * @param {number} projectId 
         * @param {ResultsTableSettings} resultsTableSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProjectResultsTableSettings(projectId: number, resultsTableSettings: ResultsTableSettings, options: any = {}): RequestArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling saveProjectResultsTableSettings.');
            }
            // verify required parameter 'resultsTableSettings' is not null or undefined
            if (resultsTableSettings === null || resultsTableSettings === undefined) {
                throw new RequiredError('resultsTableSettings','Required parameter resultsTableSettings was null or undefined when calling saveProjectResultsTableSettings.');
            }
            const localVarPath = `/projects/{projectId}/results/settings`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ResultsTableSettings" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resultsTableSettings || {}) : (resultsTableSettings || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updated the configuration for the given project
         * @summary Customizes Bounds
         * @param {number} projectId 
         * @param {BoundsConfig} boundsConfig The project data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectBoundsSettings(projectId: number, boundsConfig: BoundsConfig, options: any = {}): RequestArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling updateProjectBoundsSettings.');
            }
            // verify required parameter 'boundsConfig' is not null or undefined
            if (boundsConfig === null || boundsConfig === undefined) {
                throw new RequiredError('boundsConfig','Required parameter boundsConfig was null or undefined when calling updateProjectBoundsSettings.');
            }
            const localVarPath = `/projects/{projectId}/bounds/settings`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BoundsConfig" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(boundsConfig || {}) : (boundsConfig || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the configuration for the project
         * @summary Customizes the generic settings for the project
         * @param {number} projectId 
         * @param {GenericSettings} genericSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectGenericSettings(projectId: number, genericSettings: GenericSettings, options: any = {}): RequestArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling updateProjectGenericSettings.');
            }
            // verify required parameter 'genericSettings' is not null or undefined
            if (genericSettings === null || genericSettings === undefined) {
                throw new RequiredError('genericSettings','Required parameter genericSettings was null or undefined when calling updateProjectGenericSettings.');
            }
            const localVarPath = `/projects/{projectId}/generic-settings`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GenericSettings" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(genericSettings || {}) : (genericSettings || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updated the configuration for the given project
         * @summary Customizes Technical Specification
         * @param {number} projectId 
         * @param {TechnicalSpecificationConfig} technicalSpecificationConfig The project data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectTechnicalSpecificationsSettings(projectId: number, technicalSpecificationConfig: TechnicalSpecificationConfig, options: any = {}): RequestArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling updateProjectTechnicalSpecificationsSettings.');
            }
            // verify required parameter 'technicalSpecificationConfig' is not null or undefined
            if (technicalSpecificationConfig === null || technicalSpecificationConfig === undefined) {
                throw new RequiredError('technicalSpecificationConfig','Required parameter technicalSpecificationConfig was null or undefined when calling updateProjectTechnicalSpecificationsSettings.');
            }
            const localVarPath = `/projects/{projectId}/technical-specifications/settings`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TechnicalSpecificationConfig" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(technicalSpecificationConfig || {}) : (technicalSpecificationConfig || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectSettingsApi - functional programming interface
 * @export
 */
export const ProjectSettingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Returns the Bounds config for this project
         * @summary Bounds configs for this project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectBoundsSettings(projectId: number, options?: any): (basePath?: string) => AxiosPromise<BoundsConfig> {
            const localVarAxiosArgs = ProjectSettingsApiAxiosParamCreator(configuration).getProjectBoundsSettings(projectId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Generic settings for the project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectGenericSettings(projectId: number, options?: any): (basePath?: string) => AxiosPromise<GenericSettings> {
            const localVarAxiosArgs = ProjectSettingsApiAxiosParamCreator(configuration).getProjectGenericSettings(projectId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Get the settings for the fields of the results table
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectResultsTableSettings(projectId: number, options?: any): (basePath?: string) => AxiosPromise<ResultsTableSettings> {
            const localVarAxiosArgs = ProjectSettingsApiAxiosParamCreator(configuration).getProjectResultsTableSettings(projectId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Returns the technical spec config for this project
         * @summary Technical Specification configs for this project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTechnicalSpecificationsSettings(projectId: number, options?: any): (basePath?: string) => AxiosPromise<TechnicalSpecificationConfig> {
            const localVarAxiosArgs = ProjectSettingsApiAxiosParamCreator(configuration).getProjectTechnicalSpecificationsSettings(projectId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Updated the configuration for the given project
         * @summary Customizes the visibility settings for the technical specifications
         * @param {number} projectId 
         * @param {ResultsTableSettings} resultsTableSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProjectResultsTableSettings(projectId: number, resultsTableSettings: ResultsTableSettings, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ProjectSettingsApiAxiosParamCreator(configuration).saveProjectResultsTableSettings(projectId, resultsTableSettings, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Updated the configuration for the given project
         * @summary Customizes Bounds
         * @param {number} projectId 
         * @param {BoundsConfig} boundsConfig The project data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectBoundsSettings(projectId: number, boundsConfig: BoundsConfig, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ProjectSettingsApiAxiosParamCreator(configuration).updateProjectBoundsSettings(projectId, boundsConfig, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Updates the configuration for the project
         * @summary Customizes the generic settings for the project
         * @param {number} projectId 
         * @param {GenericSettings} genericSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectGenericSettings(projectId: number, genericSettings: GenericSettings, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ProjectSettingsApiAxiosParamCreator(configuration).updateProjectGenericSettings(projectId, genericSettings, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Updated the configuration for the given project
         * @summary Customizes Technical Specification
         * @param {number} projectId 
         * @param {TechnicalSpecificationConfig} technicalSpecificationConfig The project data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectTechnicalSpecificationsSettings(projectId: number, technicalSpecificationConfig: TechnicalSpecificationConfig, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ProjectSettingsApiAxiosParamCreator(configuration).updateProjectTechnicalSpecificationsSettings(projectId, technicalSpecificationConfig, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * ProjectSettingsApi - factory interface
 * @export
 */
export const ProjectSettingsApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * Returns the Bounds config for this project
         * @summary Bounds configs for this project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectBoundsSettings(projectId: number, options?: any) {
            return ProjectSettingsApiFp(configuration).getProjectBoundsSettings(projectId, options)(basePath);
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Generic settings for the project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectGenericSettings(projectId: number, options?: any) {
            return ProjectSettingsApiFp(configuration).getProjectGenericSettings(projectId, options)(basePath);
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Get the settings for the fields of the results table
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectResultsTableSettings(projectId: number, options?: any) {
            return ProjectSettingsApiFp(configuration).getProjectResultsTableSettings(projectId, options)(basePath);
        },
        /**
         * Returns the technical spec config for this project
         * @summary Technical Specification configs for this project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTechnicalSpecificationsSettings(projectId: number, options?: any) {
            return ProjectSettingsApiFp(configuration).getProjectTechnicalSpecificationsSettings(projectId, options)(basePath);
        },
        /**
         * Updated the configuration for the given project
         * @summary Customizes the visibility settings for the technical specifications
         * @param {number} projectId 
         * @param {ResultsTableSettings} resultsTableSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProjectResultsTableSettings(projectId: number, resultsTableSettings: ResultsTableSettings, options?: any) {
            return ProjectSettingsApiFp(configuration).saveProjectResultsTableSettings(projectId, resultsTableSettings, options)(basePath);
        },
        /**
         * Updated the configuration for the given project
         * @summary Customizes Bounds
         * @param {number} projectId 
         * @param {BoundsConfig} boundsConfig The project data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectBoundsSettings(projectId: number, boundsConfig: BoundsConfig, options?: any) {
            return ProjectSettingsApiFp(configuration).updateProjectBoundsSettings(projectId, boundsConfig, options)(basePath);
        },
        /**
         * Updates the configuration for the project
         * @summary Customizes the generic settings for the project
         * @param {number} projectId 
         * @param {GenericSettings} genericSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectGenericSettings(projectId: number, genericSettings: GenericSettings, options?: any) {
            return ProjectSettingsApiFp(configuration).updateProjectGenericSettings(projectId, genericSettings, options)(basePath);
        },
        /**
         * Updated the configuration for the given project
         * @summary Customizes Technical Specification
         * @param {number} projectId 
         * @param {TechnicalSpecificationConfig} technicalSpecificationConfig The project data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectTechnicalSpecificationsSettings(projectId: number, technicalSpecificationConfig: TechnicalSpecificationConfig, options?: any) {
            return ProjectSettingsApiFp(configuration).updateProjectTechnicalSpecificationsSettings(projectId, technicalSpecificationConfig, options)(basePath);
        },
    };
};

/**
 * ProjectSettingsApi - object-oriented interface
 * @export
 * @class ProjectSettingsApi
 * @extends {BaseAPI}
 */
export class ProjectSettingsApi extends BaseAPI {
    /**
     * Returns the Bounds config for this project
     * @summary Bounds configs for this project
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSettingsApi
     */
    public getProjectBoundsSettings(projectId: number, options?: any) {
        return ProjectSettingsApiFp(this.configuration).getProjectBoundsSettings(projectId, options)(this.basePath);
    }

    /**
     * Specifies the order and the visibility of the fields in the results table
     * @summary Generic settings for the project
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSettingsApi
     */
    public getProjectGenericSettings(projectId: number, options?: any) {
        return ProjectSettingsApiFp(this.configuration).getProjectGenericSettings(projectId, options)(this.basePath);
    }

    /**
     * Specifies the order and the visibility of the fields in the results table
     * @summary Get the settings for the fields of the results table
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSettingsApi
     */
    public getProjectResultsTableSettings(projectId: number, options?: any) {
        return ProjectSettingsApiFp(this.configuration).getProjectResultsTableSettings(projectId, options)(this.basePath);
    }

    /**
     * Returns the technical spec config for this project
     * @summary Technical Specification configs for this project
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSettingsApi
     */
    public getProjectTechnicalSpecificationsSettings(projectId: number, options?: any) {
        return ProjectSettingsApiFp(this.configuration).getProjectTechnicalSpecificationsSettings(projectId, options)(this.basePath);
    }

    /**
     * Updated the configuration for the given project
     * @summary Customizes the visibility settings for the technical specifications
     * @param {number} projectId 
     * @param {ResultsTableSettings} resultsTableSettings The updated settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSettingsApi
     */
    public saveProjectResultsTableSettings(projectId: number, resultsTableSettings: ResultsTableSettings, options?: any) {
        return ProjectSettingsApiFp(this.configuration).saveProjectResultsTableSettings(projectId, resultsTableSettings, options)(this.basePath);
    }

    /**
     * Updated the configuration for the given project
     * @summary Customizes Bounds
     * @param {number} projectId 
     * @param {BoundsConfig} boundsConfig The project data, needed to specify the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSettingsApi
     */
    public updateProjectBoundsSettings(projectId: number, boundsConfig: BoundsConfig, options?: any) {
        return ProjectSettingsApiFp(this.configuration).updateProjectBoundsSettings(projectId, boundsConfig, options)(this.basePath);
    }

    /**
     * Updates the configuration for the project
     * @summary Customizes the generic settings for the project
     * @param {number} projectId 
     * @param {GenericSettings} genericSettings The updated settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSettingsApi
     */
    public updateProjectGenericSettings(projectId: number, genericSettings: GenericSettings, options?: any) {
        return ProjectSettingsApiFp(this.configuration).updateProjectGenericSettings(projectId, genericSettings, options)(this.basePath);
    }

    /**
     * Updated the configuration for the given project
     * @summary Customizes Technical Specification
     * @param {number} projectId 
     * @param {TechnicalSpecificationConfig} technicalSpecificationConfig The project data, needed to specify the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSettingsApi
     */
    public updateProjectTechnicalSpecificationsSettings(projectId: number, technicalSpecificationConfig: TechnicalSpecificationConfig, options?: any) {
        return ProjectSettingsApiFp(this.configuration).updateProjectTechnicalSpecificationsSettings(projectId, technicalSpecificationConfig, options)(this.basePath);
    }

}

/**
 * QuoteApi - axios parameter creator
 * @export
 */
export const QuoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtains input info for the quote
         * @summary Obtains input info for the quote
         * @param {Array<number>} itemIds A list of item ids, part of the same project
         * @param {string} [unitId] The (optional) unitId, when itemIds contains only one itemId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quoteInput(itemIds: Array<number>, unitId?: string, options: any = {}): RequestArgs {
            // verify required parameter 'itemIds' is not null or undefined
            if (itemIds === null || itemIds === undefined) {
                throw new RequiredError('itemIds','Required parameter itemIds was null or undefined when calling quoteInput.');
            }
            const localVarPath = `/items/quote-input`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (itemIds) {
                localVarQueryParameter['itemIds'] = itemIds;
            }

            if (unitId !== undefined) {
                localVarQueryParameter['unitId'] = unitId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Crates or updates the items quote
         * @summary Crates or updates the items quote
         * @param {Array<number>} itemIds a list of item ids, part of the same project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quoteItems(itemIds: Array<number>, options: any = {}): RequestArgs {
            // verify required parameter 'itemIds' is not null or undefined
            if (itemIds === null || itemIds === undefined) {
                throw new RequiredError('itemIds','Required parameter itemIds was null or undefined when calling quoteItems.');
            }
            const localVarPath = `/items/quote`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (itemIds) {
                localVarQueryParameter['itemIds'] = itemIds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuoteApi - functional programming interface
 * @export
 */
export const QuoteApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Obtains input info for the quote
         * @summary Obtains input info for the quote
         * @param {Array<number>} itemIds A list of item ids, part of the same project
         * @param {string} [unitId] The (optional) unitId, when itemIds contains only one itemId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quoteInput(itemIds: Array<number>, unitId?: string, options?: any): (basePath?: string) => AxiosPromise<Array<QuoteInput>> {
            const localVarAxiosArgs = QuoteApiAxiosParamCreator(configuration).quoteInput(itemIds, unitId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Crates or updates the items quote
         * @summary Crates or updates the items quote
         * @param {Array<number>} itemIds a list of item ids, part of the same project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quoteItems(itemIds: Array<number>, options?: any): (basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = QuoteApiAxiosParamCreator(configuration).quoteItems(itemIds, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * QuoteApi - factory interface
 * @export
 */
export const QuoteApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * Obtains input info for the quote
         * @summary Obtains input info for the quote
         * @param {Array<number>} itemIds A list of item ids, part of the same project
         * @param {string} [unitId] The (optional) unitId, when itemIds contains only one itemId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quoteInput(itemIds: Array<number>, unitId?: string, options?: any) {
            return QuoteApiFp(configuration).quoteInput(itemIds, unitId, options)(basePath);
        },
        /**
         * Crates or updates the items quote
         * @summary Crates or updates the items quote
         * @param {Array<number>} itemIds a list of item ids, part of the same project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quoteItems(itemIds: Array<number>, options?: any) {
            return QuoteApiFp(configuration).quoteItems(itemIds, options)(basePath);
        },
    };
};

/**
 * QuoteApi - object-oriented interface
 * @export
 * @class QuoteApi
 * @extends {BaseAPI}
 */
export class QuoteApi extends BaseAPI {
    /**
     * Obtains input info for the quote
     * @summary Obtains input info for the quote
     * @param {Array<number>} itemIds A list of item ids, part of the same project
     * @param {string} [unitId] The (optional) unitId, when itemIds contains only one itemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuoteApi
     */
    public quoteInput(itemIds: Array<number>, unitId?: string, options?: any) {
        return QuoteApiFp(this.configuration).quoteInput(itemIds, unitId, options)(this.basePath);
    }

    /**
     * Crates or updates the items quote
     * @summary Crates or updates the items quote
     * @param {Array<number>} itemIds a list of item ids, part of the same project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuoteApi
     */
    public quoteItems(itemIds: Array<number>, options?: any) {
        return QuoteApiFp(this.configuration).quoteItems(itemIds, options)(this.basePath);
    }

}

/**
 * ReportProblemApi - axios parameter creator
 * @export
 */
export const ReportProblemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Report a user problem
         * @param {UserProblem} [userProblem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportProblem(userProblem?: UserProblem, options: any = {}): RequestArgs {
            const localVarPath = `/report-problem`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserProblem" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userProblem || {}) : (userProblem || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportProblemApi - functional programming interface
 * @export
 */
export const ReportProblemApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Report a user problem
         * @param {UserProblem} [userProblem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportProblem(userProblem?: UserProblem, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ReportProblemApiAxiosParamCreator(configuration).reportProblem(userProblem, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * ReportProblemApi - factory interface
 * @export
 */
export const ReportProblemApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary Report a user problem
         * @param {UserProblem} [userProblem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportProblem(userProblem?: UserProblem, options?: any) {
            return ReportProblemApiFp(configuration).reportProblem(userProblem, options)(basePath);
        },
    };
};

/**
 * ReportProblemApi - object-oriented interface
 * @export
 * @class ReportProblemApi
 * @extends {BaseAPI}
 */
export class ReportProblemApi extends BaseAPI {
    /**
     * 
     * @summary Report a user problem
     * @param {UserProblem} [userProblem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportProblemApi
     */
    public reportProblem(userProblem?: UserProblem, options?: any) {
        return ReportProblemApiFp(this.configuration).reportProblem(userProblem, options)(this.basePath);
    }

}

/**
 * ShareApi - axios parameter creator
 * @export
 */
export const ShareApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the list of sharing for the item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemShares(itemId: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getItemShares.');
            }
            const localVarPath = `/items/{itemId}/shares`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets projects shared with me
         * @param {number} [page] the page number
         * @param {number} [size] the page size
         * @param {string} [sort] the field to be sorted (e.g.: lastModifiedDate,desc)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectSharedWithMe(page?: number, size?: number, sort?: string, options: any = {}): RequestArgs {
            const localVarPath = `/shared-projects`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of sharing for the project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectShares(projectId: number, options: any = {}): RequestArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getProjectShares.');
            }
            const localVarPath = `/projects/{projectId}/shares`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Clones the item into a project owned by the user
         * @summary Import a shared item
         * @param {number} itemId 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importItem(itemId: number, projectId?: number, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling importItem.');
            }
            const localVarPath = `/items/{itemId}/import`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clones a shared project along with its items
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProject(projectId: number, options: any = {}): RequestArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling importProject.');
            }
            const localVarPath = `/projects/{projectId}/import`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing sharing
         * @param {number} shareId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeShare(shareId: number, options: any = {}): RequestArgs {
            // verify required parameter 'shareId' is not null or undefined
            if (shareId === null || shareId === undefined) {
                throw new RequiredError('shareId','Required parameter shareId was null or undefined when calling removeShare.');
            }
            const localVarPath = `/shares/{shareId}`
                .replace(`{${"shareId"}}`, encodeURIComponent(String(shareId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Shares the item
         * @param {number} itemId 
         * @param {NewShare} [newShare] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareItem(itemId: number, newShare?: NewShare, options: any = {}): RequestArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling shareItem.');
            }
            const localVarPath = `/items/{itemId}/shares`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NewShare" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newShare || {}) : (newShare || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Shares the project
         * @param {number} projectId 
         * @param {NewShare} [newShare] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareProject(projectId: number, newShare?: NewShare, options: any = {}): RequestArgs {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling shareProject.');
            }
            const localVarPath = `/projects/{projectId}/shares`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NewShare" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newShare || {}) : (newShare || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShareApi - functional programming interface
 * @export
 */
export const ShareApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the list of sharing for the item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemShares(itemId: number, options?: any): (basePath?: string) => AxiosPromise<Array<Share>> {
            const localVarAxiosArgs = ShareApiAxiosParamCreator(configuration).getItemShares(itemId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Gets projects shared with me
         * @param {number} [page] the page number
         * @param {number} [size] the page size
         * @param {string} [sort] the field to be sorted (e.g.: lastModifiedDate,desc)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectSharedWithMe(page?: number, size?: number, sort?: string, options?: any): (basePath?: string) => AxiosPromise<Array<Project>> {
            const localVarAxiosArgs = ShareApiAxiosParamCreator(configuration).getProjectSharedWithMe(page, size, sort, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Gets the list of sharing for the project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectShares(projectId: number, options?: any): (basePath?: string) => AxiosPromise<Array<Share>> {
            const localVarAxiosArgs = ShareApiAxiosParamCreator(configuration).getProjectShares(projectId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Clones the item into a project owned by the user
         * @summary Import a shared item
         * @param {number} itemId 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importItem(itemId: number, projectId?: number, options?: any): (basePath?: string) => AxiosPromise<Item> {
            const localVarAxiosArgs = ShareApiAxiosParamCreator(configuration).importItem(itemId, projectId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Clones a shared project along with its items
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProject(projectId: number, options?: any): (basePath?: string) => AxiosPromise<Project> {
            const localVarAxiosArgs = ShareApiAxiosParamCreator(configuration).importProject(projectId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Delete an existing sharing
         * @param {number} shareId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeShare(shareId: number, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ShareApiAxiosParamCreator(configuration).removeShare(shareId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Shares the item
         * @param {number} itemId 
         * @param {NewShare} [newShare] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareItem(itemId: number, newShare?: NewShare, options?: any): (basePath?: string) => AxiosPromise<Share> {
            const localVarAxiosArgs = ShareApiAxiosParamCreator(configuration).shareItem(itemId, newShare, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Shares the project
         * @param {number} projectId 
         * @param {NewShare} [newShare] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareProject(projectId: number, newShare?: NewShare, options?: any): (basePath?: string) => AxiosPromise<Share> {
            const localVarAxiosArgs = ShareApiAxiosParamCreator(configuration).shareProject(projectId, newShare, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * ShareApi - factory interface
 * @export
 */
export const ShareApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary Gets the list of sharing for the item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemShares(itemId: number, options?: any) {
            return ShareApiFp(configuration).getItemShares(itemId, options)(basePath);
        },
        /**
         * 
         * @summary Gets projects shared with me
         * @param {number} [page] the page number
         * @param {number} [size] the page size
         * @param {string} [sort] the field to be sorted (e.g.: lastModifiedDate,desc)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectSharedWithMe(page?: number, size?: number, sort?: string, options?: any) {
            return ShareApiFp(configuration).getProjectSharedWithMe(page, size, sort, options)(basePath);
        },
        /**
         * 
         * @summary Gets the list of sharing for the project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectShares(projectId: number, options?: any) {
            return ShareApiFp(configuration).getProjectShares(projectId, options)(basePath);
        },
        /**
         * Clones the item into a project owned by the user
         * @summary Import a shared item
         * @param {number} itemId 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importItem(itemId: number, projectId?: number, options?: any) {
            return ShareApiFp(configuration).importItem(itemId, projectId, options)(basePath);
        },
        /**
         * 
         * @summary Clones a shared project along with its items
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProject(projectId: number, options?: any) {
            return ShareApiFp(configuration).importProject(projectId, options)(basePath);
        },
        /**
         * 
         * @summary Delete an existing sharing
         * @param {number} shareId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeShare(shareId: number, options?: any) {
            return ShareApiFp(configuration).removeShare(shareId, options)(basePath);
        },
        /**
         * 
         * @summary Shares the item
         * @param {number} itemId 
         * @param {NewShare} [newShare] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareItem(itemId: number, newShare?: NewShare, options?: any) {
            return ShareApiFp(configuration).shareItem(itemId, newShare, options)(basePath);
        },
        /**
         * 
         * @summary Shares the project
         * @param {number} projectId 
         * @param {NewShare} [newShare] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareProject(projectId: number, newShare?: NewShare, options?: any) {
            return ShareApiFp(configuration).shareProject(projectId, newShare, options)(basePath);
        },
    };
};

/**
 * ShareApi - object-oriented interface
 * @export
 * @class ShareApi
 * @extends {BaseAPI}
 */
export class ShareApi extends BaseAPI {
    /**
     * 
     * @summary Gets the list of sharing for the item
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public getItemShares(itemId: number, options?: any) {
        return ShareApiFp(this.configuration).getItemShares(itemId, options)(this.basePath);
    }

    /**
     * 
     * @summary Gets projects shared with me
     * @param {number} [page] the page number
     * @param {number} [size] the page size
     * @param {string} [sort] the field to be sorted (e.g.: lastModifiedDate,desc)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public getProjectSharedWithMe(page?: number, size?: number, sort?: string, options?: any) {
        return ShareApiFp(this.configuration).getProjectSharedWithMe(page, size, sort, options)(this.basePath);
    }

    /**
     * 
     * @summary Gets the list of sharing for the project
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public getProjectShares(projectId: number, options?: any) {
        return ShareApiFp(this.configuration).getProjectShares(projectId, options)(this.basePath);
    }

    /**
     * Clones the item into a project owned by the user
     * @summary Import a shared item
     * @param {number} itemId 
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public importItem(itemId: number, projectId?: number, options?: any) {
        return ShareApiFp(this.configuration).importItem(itemId, projectId, options)(this.basePath);
    }

    /**
     * 
     * @summary Clones a shared project along with its items
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public importProject(projectId: number, options?: any) {
        return ShareApiFp(this.configuration).importProject(projectId, options)(this.basePath);
    }

    /**
     * 
     * @summary Delete an existing sharing
     * @param {number} shareId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public removeShare(shareId: number, options?: any) {
        return ShareApiFp(this.configuration).removeShare(shareId, options)(this.basePath);
    }

    /**
     * 
     * @summary Shares the item
     * @param {number} itemId 
     * @param {NewShare} [newShare] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public shareItem(itemId: number, newShare?: NewShare, options?: any) {
        return ShareApiFp(this.configuration).shareItem(itemId, newShare, options)(this.basePath);
    }

    /**
     * 
     * @summary Shares the project
     * @param {number} projectId 
     * @param {NewShare} [newShare] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public shareProject(projectId: number, newShare?: NewShare, options?: any) {
        return ShareApiFp(this.configuration).shareProject(projectId, newShare, options)(this.basePath);
    }

}

/**
 * SubdivisionsApi - axios parameter creator
 * @export
 */
export const SubdivisionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns an array of the available subdivisions
         * @summary Retrieves all available subdivisions
         * @param {number} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubdivisions(countryId?: number, options: any = {}): RequestArgs {
            const localVarPath = `/subdivisions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            if (countryId !== undefined) {
                localVarQueryParameter['countryId'] = countryId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubdivisionsApi - functional programming interface
 * @export
 */
export const SubdivisionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Returns an array of the available subdivisions
         * @summary Retrieves all available subdivisions
         * @param {number} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubdivisions(countryId?: number, options?: any): (basePath?: string) => AxiosPromise<Array<Subdivision>> {
            const localVarAxiosArgs = SubdivisionsApiAxiosParamCreator(configuration).getSubdivisions(countryId, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * SubdivisionsApi - factory interface
 * @export
 */
export const SubdivisionsApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * Returns an array of the available subdivisions
         * @summary Retrieves all available subdivisions
         * @param {number} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubdivisions(countryId?: number, options?: any) {
            return SubdivisionsApiFp(configuration).getSubdivisions(countryId, options)(basePath);
        },
    };
};

/**
 * SubdivisionsApi - object-oriented interface
 * @export
 * @class SubdivisionsApi
 * @extends {BaseAPI}
 */
export class SubdivisionsApi extends BaseAPI {
    /**
     * Returns an array of the available subdivisions
     * @summary Retrieves all available subdivisions
     * @param {number} [countryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubdivisionsApi
     */
    public getSubdivisions(countryId?: number, options?: any) {
        return SubdivisionsApiFp(this.configuration).getSubdivisions(countryId, options)(this.basePath);
    }

}

/**
 * SystemSettingsApi - axios parameter creator
 * @export
 */
export const SystemSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Specifies system default bounds
         * @summary Bounds settings for the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoundsSettings(options: any = {}): RequestArgs {
            const localVarPath = `/bounds-settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Generic settings for the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenericSettings(options: any = {}): RequestArgs {
            const localVarPath = `/generic-settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Specifies system default bounds
         * @summary Bounds settings for the system
         * @param {BoundsConfig} boundsConfig The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBoundsSettings(boundsConfig: BoundsConfig, options: any = {}): RequestArgs {
            // verify required parameter 'boundsConfig' is not null or undefined
            if (boundsConfig === null || boundsConfig === undefined) {
                throw new RequiredError('boundsConfig','Required parameter boundsConfig was null or undefined when calling updateBoundsSettings.');
            }
            const localVarPath = `/bounds-settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BoundsConfig" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(boundsConfig || {}) : (boundsConfig || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the configuration for the user
         * @summary Generic settings for the system
         * @param {GenericSettings} genericSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGenericSettings(genericSettings: GenericSettings, options: any = {}): RequestArgs {
            // verify required parameter 'genericSettings' is not null or undefined
            if (genericSettings === null || genericSettings === undefined) {
                throw new RequiredError('genericSettings','Required parameter genericSettings was null or undefined when calling updateGenericSettings.');
            }
            const localVarPath = `/generic-settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GenericSettings" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(genericSettings || {}) : (genericSettings || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemSettingsApi - functional programming interface
 * @export
 */
export const SystemSettingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Specifies system default bounds
         * @summary Bounds settings for the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoundsSettings(options?: any): (basePath?: string) => AxiosPromise<BoundsConfig> {
            const localVarAxiosArgs = SystemSettingsApiAxiosParamCreator(configuration).getBoundsSettings(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Generic settings for the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenericSettings(options?: any): (basePath?: string) => AxiosPromise<GenericSettings> {
            const localVarAxiosArgs = SystemSettingsApiAxiosParamCreator(configuration).getGenericSettings(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Specifies system default bounds
         * @summary Bounds settings for the system
         * @param {BoundsConfig} boundsConfig The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBoundsSettings(boundsConfig: BoundsConfig, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = SystemSettingsApiAxiosParamCreator(configuration).updateBoundsSettings(boundsConfig, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Updates the configuration for the user
         * @summary Generic settings for the system
         * @param {GenericSettings} genericSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGenericSettings(genericSettings: GenericSettings, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = SystemSettingsApiAxiosParamCreator(configuration).updateGenericSettings(genericSettings, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * SystemSettingsApi - factory interface
 * @export
 */
export const SystemSettingsApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * Specifies system default bounds
         * @summary Bounds settings for the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoundsSettings(options?: any) {
            return SystemSettingsApiFp(configuration).getBoundsSettings(options)(basePath);
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Generic settings for the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenericSettings(options?: any) {
            return SystemSettingsApiFp(configuration).getGenericSettings(options)(basePath);
        },
        /**
         * Specifies system default bounds
         * @summary Bounds settings for the system
         * @param {BoundsConfig} boundsConfig The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBoundsSettings(boundsConfig: BoundsConfig, options?: any) {
            return SystemSettingsApiFp(configuration).updateBoundsSettings(boundsConfig, options)(basePath);
        },
        /**
         * Updates the configuration for the user
         * @summary Generic settings for the system
         * @param {GenericSettings} genericSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGenericSettings(genericSettings: GenericSettings, options?: any) {
            return SystemSettingsApiFp(configuration).updateGenericSettings(genericSettings, options)(basePath);
        },
    };
};

/**
 * SystemSettingsApi - object-oriented interface
 * @export
 * @class SystemSettingsApi
 * @extends {BaseAPI}
 */
export class SystemSettingsApi extends BaseAPI {
    /**
     * Specifies system default bounds
     * @summary Bounds settings for the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemSettingsApi
     */
    public getBoundsSettings(options?: any) {
        return SystemSettingsApiFp(this.configuration).getBoundsSettings(options)(this.basePath);
    }

    /**
     * Specifies the order and the visibility of the fields in the results table
     * @summary Generic settings for the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemSettingsApi
     */
    public getGenericSettings(options?: any) {
        return SystemSettingsApiFp(this.configuration).getGenericSettings(options)(this.basePath);
    }

    /**
     * Specifies system default bounds
     * @summary Bounds settings for the system
     * @param {BoundsConfig} boundsConfig The updated settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemSettingsApi
     */
    public updateBoundsSettings(boundsConfig: BoundsConfig, options?: any) {
        return SystemSettingsApiFp(this.configuration).updateBoundsSettings(boundsConfig, options)(this.basePath);
    }

    /**
     * Updates the configuration for the user
     * @summary Generic settings for the system
     * @param {GenericSettings} genericSettings The updated settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemSettingsApi
     */
    public updateGenericSettings(genericSettings: GenericSettings, options?: any) {
        return SystemSettingsApiFp(this.configuration).updateGenericSettings(genericSettings, options)(this.basePath);
    }

}

/**
 * ThermalDataApi - axios parameter creator
 * @export
 */
export const ThermalDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve the actions for each thermal form option, if any
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThermalFieldOptionActions(options: any = {}): RequestArgs {
            const localVarPath = `/thermal/field-option-actions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve all the translated labels for the thermal fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThermalFieldOptionLabels(options: any = {}): RequestArgs {
            const localVarPath = `/thermal/field-option-labels`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ThermalDataApi - functional programming interface
 * @export
 */
export const ThermalDataApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve the actions for each thermal form option, if any
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThermalFieldOptionActions(options?: any): (basePath?: string) => AxiosPromise<Array<FieldOptionAction>> {
            const localVarAxiosArgs = ThermalDataApiAxiosParamCreator(configuration).getThermalFieldOptionActions(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Retrieve all the translated labels for the thermal fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThermalFieldOptionLabels(options?: any): (basePath?: string) => AxiosPromise<OptionLabels> {
            const localVarAxiosArgs = ThermalDataApiAxiosParamCreator(configuration).getThermalFieldOptionLabels(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * ThermalDataApi - factory interface
 * @export
 */
export const ThermalDataApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * 
         * @summary Retrieve the actions for each thermal form option, if any
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThermalFieldOptionActions(options?: any) {
            return ThermalDataApiFp(configuration).getThermalFieldOptionActions(options)(basePath);
        },
        /**
         * 
         * @summary Retrieve all the translated labels for the thermal fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThermalFieldOptionLabels(options?: any) {
            return ThermalDataApiFp(configuration).getThermalFieldOptionLabels(options)(basePath);
        },
    };
};

/**
 * ThermalDataApi - object-oriented interface
 * @export
 * @class ThermalDataApi
 * @extends {BaseAPI}
 */
export class ThermalDataApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve the actions for each thermal form option, if any
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThermalDataApi
     */
    public getThermalFieldOptionActions(options?: any) {
        return ThermalDataApiFp(this.configuration).getThermalFieldOptionActions(options)(this.basePath);
    }

    /**
     * 
     * @summary Retrieve all the translated labels for the thermal fields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThermalDataApi
     */
    public getThermalFieldOptionLabels(options?: any) {
        return ThermalDataApiFp(this.configuration).getThermalFieldOptionLabels(options)(this.basePath);
    }

}

/**
 * UnitOfMeasuresApi - axios parameter creator
 * @export
 */
export const UnitOfMeasuresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns an array of tuples from / to / formula
         * @summary Retrieves all the formulas to convert one unit of measurement to another
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversionFormulas(options: any = {}): RequestArgs {
            const localVarPath = `/unit-of-measures/conversions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an array of the available metric systems
         * @summary Retrieves all metric systems
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricSystems(options: any = {}): RequestArgs {
            const localVarPath = `/unit-of-measures/metric-systems`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnitOfMeasuresApi - functional programming interface
 * @export
 */
export const UnitOfMeasuresApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Returns an array of tuples from / to / formula
         * @summary Retrieves all the formulas to convert one unit of measurement to another
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversionFormulas(options?: any): (basePath?: string) => AxiosPromise<Array<ConversionFormula>> {
            const localVarAxiosArgs = UnitOfMeasuresApiAxiosParamCreator(configuration).getConversionFormulas(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Returns an array of the available metric systems
         * @summary Retrieves all metric systems
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricSystems(options?: any): (basePath?: string) => AxiosPromise<Array<MetricSystem>> {
            const localVarAxiosArgs = UnitOfMeasuresApiAxiosParamCreator(configuration).getMetricSystems(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * UnitOfMeasuresApi - factory interface
 * @export
 */
export const UnitOfMeasuresApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * Returns an array of tuples from / to / formula
         * @summary Retrieves all the formulas to convert one unit of measurement to another
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversionFormulas(options?: any) {
            return UnitOfMeasuresApiFp(configuration).getConversionFormulas(options)(basePath);
        },
        /**
         * Returns an array of the available metric systems
         * @summary Retrieves all metric systems
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricSystems(options?: any) {
            return UnitOfMeasuresApiFp(configuration).getMetricSystems(options)(basePath);
        },
    };
};

/**
 * UnitOfMeasuresApi - object-oriented interface
 * @export
 * @class UnitOfMeasuresApi
 * @extends {BaseAPI}
 */
export class UnitOfMeasuresApi extends BaseAPI {
    /**
     * Returns an array of tuples from / to / formula
     * @summary Retrieves all the formulas to convert one unit of measurement to another
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitOfMeasuresApi
     */
    public getConversionFormulas(options?: any) {
        return UnitOfMeasuresApiFp(this.configuration).getConversionFormulas(options)(this.basePath);
    }

    /**
     * Returns an array of the available metric systems
     * @summary Retrieves all metric systems
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitOfMeasuresApi
     */
    public getMetricSystems(options?: any) {
        return UnitOfMeasuresApiFp(this.configuration).getMetricSystems(options)(this.basePath);
    }

}

/**
 * UserSettingsApi - axios parameter creator
 * @export
 */
export const UserSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the Bounds config for this item
         * @summary Bounds configs for this item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBoundsSettings(options: any = {}): RequestArgs {
            const localVarPath = `/user/bounds/settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Generic settings for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGenericSettings(options: any = {}): RequestArgs {
            const localVarPath = `/user/generic-settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Get the settings for the fields of the results table
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserResultsTableSettings(options: any = {}): RequestArgs {
            const localVarPath = `/user/results/settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the technical spec config for this item
         * @summary Technical Specification configs for this item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTechnicalSpecificationsSettings(options: any = {}): RequestArgs {
            const localVarPath = `/user/technical-specifications/settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updated the configuration for the given item
         * @summary Customizes Bounds
         * @param {BoundsConfig} boundsConfig The item data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserBoundsSettings(boundsConfig: BoundsConfig, options: any = {}): RequestArgs {
            // verify required parameter 'boundsConfig' is not null or undefined
            if (boundsConfig === null || boundsConfig === undefined) {
                throw new RequiredError('boundsConfig','Required parameter boundsConfig was null or undefined when calling updateUserBoundsSettings.');
            }
            const localVarPath = `/user/bounds/settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BoundsConfig" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(boundsConfig || {}) : (boundsConfig || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the configuration for the user
         * @summary Customizes the generic settings for the user
         * @param {GenericSettings} genericSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserGenericSettings(genericSettings: GenericSettings, options: any = {}): RequestArgs {
            // verify required parameter 'genericSettings' is not null or undefined
            if (genericSettings === null || genericSettings === undefined) {
                throw new RequiredError('genericSettings','Required parameter genericSettings was null or undefined when calling updateUserGenericSettings.');
            }
            const localVarPath = `/user/generic-settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GenericSettings" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(genericSettings || {}) : (genericSettings || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updated the configuration for the given item
         * @summary Customizes the visibility settings for the technical specifications
         * @param {ResultsTableSettings} resultsTableSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserResultsTableSettings(resultsTableSettings: ResultsTableSettings, options: any = {}): RequestArgs {
            // verify required parameter 'resultsTableSettings' is not null or undefined
            if (resultsTableSettings === null || resultsTableSettings === undefined) {
                throw new RequiredError('resultsTableSettings','Required parameter resultsTableSettings was null or undefined when calling updateUserResultsTableSettings.');
            }
            const localVarPath = `/user/results/settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ResultsTableSettings" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resultsTableSettings || {}) : (resultsTableSettings || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updated the configuration for the given item
         * @summary Customizes Technical Specification
         * @param {TechnicalSpecificationConfig} technicalSpecificationConfig The item data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserTechnicalSpecificationsSettings(technicalSpecificationConfig: TechnicalSpecificationConfig, options: any = {}): RequestArgs {
            // verify required parameter 'technicalSpecificationConfig' is not null or undefined
            if (technicalSpecificationConfig === null || technicalSpecificationConfig === undefined) {
                throw new RequiredError('technicalSpecificationConfig','Required parameter technicalSpecificationConfig was null or undefined when calling updateUserTechnicalSpecificationsSettings.');
            }
            const localVarPath = `/user/technical-specifications/settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication cookieAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TechnicalSpecificationConfig" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(technicalSpecificationConfig || {}) : (technicalSpecificationConfig || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserSettingsApi - functional programming interface
 * @export
 */
export const UserSettingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Returns the Bounds config for this item
         * @summary Bounds configs for this item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBoundsSettings(options?: any): (basePath?: string) => AxiosPromise<BoundsConfig> {
            const localVarAxiosArgs = UserSettingsApiAxiosParamCreator(configuration).getUserBoundsSettings(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Generic settings for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGenericSettings(options?: any): (basePath?: string) => AxiosPromise<GenericSettings> {
            const localVarAxiosArgs = UserSettingsApiAxiosParamCreator(configuration).getUserGenericSettings(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Get the settings for the fields of the results table
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserResultsTableSettings(options?: any): (basePath?: string) => AxiosPromise<ResultsTableSettings> {
            const localVarAxiosArgs = UserSettingsApiAxiosParamCreator(configuration).getUserResultsTableSettings(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Returns the technical spec config for this item
         * @summary Technical Specification configs for this item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTechnicalSpecificationsSettings(options?: any): (basePath?: string) => AxiosPromise<TechnicalSpecificationConfig> {
            const localVarAxiosArgs = UserSettingsApiAxiosParamCreator(configuration).getUserTechnicalSpecificationsSettings(options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Updated the configuration for the given item
         * @summary Customizes Bounds
         * @param {BoundsConfig} boundsConfig The item data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserBoundsSettings(boundsConfig: BoundsConfig, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = UserSettingsApiAxiosParamCreator(configuration).updateUserBoundsSettings(boundsConfig, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Updates the configuration for the user
         * @summary Customizes the generic settings for the user
         * @param {GenericSettings} genericSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserGenericSettings(genericSettings: GenericSettings, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = UserSettingsApiAxiosParamCreator(configuration).updateUserGenericSettings(genericSettings, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Updated the configuration for the given item
         * @summary Customizes the visibility settings for the technical specifications
         * @param {ResultsTableSettings} resultsTableSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserResultsTableSettings(resultsTableSettings: ResultsTableSettings, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = UserSettingsApiAxiosParamCreator(configuration).updateUserResultsTableSettings(resultsTableSettings, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Updated the configuration for the given item
         * @summary Customizes Technical Specification
         * @param {TechnicalSpecificationConfig} technicalSpecificationConfig The item data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserTechnicalSpecificationsSettings(technicalSpecificationConfig: TechnicalSpecificationConfig, options?: any): (basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = UserSettingsApiAxiosParamCreator(configuration).updateUserTechnicalSpecificationsSettings(technicalSpecificationConfig, options);
            return (basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * UserSettingsApi - factory interface
 * @export
 */
export const UserSettingsApiFactory = function (configuration?: Configuration, basePath?: string) {
    return {
        /**
         * Returns the Bounds config for this item
         * @summary Bounds configs for this item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBoundsSettings(options?: any) {
            return UserSettingsApiFp(configuration).getUserBoundsSettings(options)(basePath);
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Generic settings for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGenericSettings(options?: any) {
            return UserSettingsApiFp(configuration).getUserGenericSettings(options)(basePath);
        },
        /**
         * Specifies the order and the visibility of the fields in the results table
         * @summary Get the settings for the fields of the results table
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserResultsTableSettings(options?: any) {
            return UserSettingsApiFp(configuration).getUserResultsTableSettings(options)(basePath);
        },
        /**
         * Returns the technical spec config for this item
         * @summary Technical Specification configs for this item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTechnicalSpecificationsSettings(options?: any) {
            return UserSettingsApiFp(configuration).getUserTechnicalSpecificationsSettings(options)(basePath);
        },
        /**
         * Updated the configuration for the given item
         * @summary Customizes Bounds
         * @param {BoundsConfig} boundsConfig The item data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserBoundsSettings(boundsConfig: BoundsConfig, options?: any) {
            return UserSettingsApiFp(configuration).updateUserBoundsSettings(boundsConfig, options)(basePath);
        },
        /**
         * Updates the configuration for the user
         * @summary Customizes the generic settings for the user
         * @param {GenericSettings} genericSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserGenericSettings(genericSettings: GenericSettings, options?: any) {
            return UserSettingsApiFp(configuration).updateUserGenericSettings(genericSettings, options)(basePath);
        },
        /**
         * Updated the configuration for the given item
         * @summary Customizes the visibility settings for the technical specifications
         * @param {ResultsTableSettings} resultsTableSettings The updated settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserResultsTableSettings(resultsTableSettings: ResultsTableSettings, options?: any) {
            return UserSettingsApiFp(configuration).updateUserResultsTableSettings(resultsTableSettings, options)(basePath);
        },
        /**
         * Updated the configuration for the given item
         * @summary Customizes Technical Specification
         * @param {TechnicalSpecificationConfig} technicalSpecificationConfig The item data, needed to specify the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserTechnicalSpecificationsSettings(technicalSpecificationConfig: TechnicalSpecificationConfig, options?: any) {
            return UserSettingsApiFp(configuration).updateUserTechnicalSpecificationsSettings(technicalSpecificationConfig, options)(basePath);
        },
    };
};

/**
 * UserSettingsApi - object-oriented interface
 * @export
 * @class UserSettingsApi
 * @extends {BaseAPI}
 */
export class UserSettingsApi extends BaseAPI {
    /**
     * Returns the Bounds config for this item
     * @summary Bounds configs for this item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public getUserBoundsSettings(options?: any) {
        return UserSettingsApiFp(this.configuration).getUserBoundsSettings(options)(this.basePath);
    }

    /**
     * Specifies the order and the visibility of the fields in the results table
     * @summary Generic settings for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public getUserGenericSettings(options?: any) {
        return UserSettingsApiFp(this.configuration).getUserGenericSettings(options)(this.basePath);
    }

    /**
     * Specifies the order and the visibility of the fields in the results table
     * @summary Get the settings for the fields of the results table
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public getUserResultsTableSettings(options?: any) {
        return UserSettingsApiFp(this.configuration).getUserResultsTableSettings(options)(this.basePath);
    }

    /**
     * Returns the technical spec config for this item
     * @summary Technical Specification configs for this item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public getUserTechnicalSpecificationsSettings(options?: any) {
        return UserSettingsApiFp(this.configuration).getUserTechnicalSpecificationsSettings(options)(this.basePath);
    }

    /**
     * Updated the configuration for the given item
     * @summary Customizes Bounds
     * @param {BoundsConfig} boundsConfig The item data, needed to specify the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public updateUserBoundsSettings(boundsConfig: BoundsConfig, options?: any) {
        return UserSettingsApiFp(this.configuration).updateUserBoundsSettings(boundsConfig, options)(this.basePath);
    }

    /**
     * Updates the configuration for the user
     * @summary Customizes the generic settings for the user
     * @param {GenericSettings} genericSettings The updated settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public updateUserGenericSettings(genericSettings: GenericSettings, options?: any) {
        return UserSettingsApiFp(this.configuration).updateUserGenericSettings(genericSettings, options)(this.basePath);
    }

    /**
     * Updated the configuration for the given item
     * @summary Customizes the visibility settings for the technical specifications
     * @param {ResultsTableSettings} resultsTableSettings The updated settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public updateUserResultsTableSettings(resultsTableSettings: ResultsTableSettings, options?: any) {
        return UserSettingsApiFp(this.configuration).updateUserResultsTableSettings(resultsTableSettings, options)(this.basePath);
    }

    /**
     * Updated the configuration for the given item
     * @summary Customizes Technical Specification
     * @param {TechnicalSpecificationConfig} technicalSpecificationConfig The item data, needed to specify the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public updateUserTechnicalSpecificationsSettings(technicalSpecificationConfig: TechnicalSpecificationConfig, options?: any) {
        return UserSettingsApiFp(this.configuration).updateUserTechnicalSpecificationsSettings(technicalSpecificationConfig, options)(this.basePath);
    }

}

