import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Switch,
  Tag,
  Upload,
} from "antd";
import { FormComponentProps } from "antd/lib/form";
import { RcFile } from "antd/lib/upload";
import { RcCustomRequestOptions } from "antd/lib/upload/interface";
import moment from "moment";
import React from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import settingsInfo from "../../../../config/settings";
import { Language, VersionLength } from "../../../../generated/axios";
import { messages } from "../../../../shared/lib/locales/definedMessages";
import { readBlobInBase64 } from "../../../../shared/lib/readBlobInBase64";
import { getLanguages } from "../../../../shared/store/account/actions";
import { getItemTechnicalSpecificationSettings } from "../../../../shared/store/item/actions";
import {
  GetTechSpecParams,
  getTechSpecSettings,
  resetTechSpecSettings,
} from "../../../../shared/store/settings/actions";
import { SettingsState } from "../../../../shared/store/settings/types";
import { IStore } from "../../../../shared/store/types";
import { messages as validationMessages } from "../../../../shared/validate/validationMessages";
import { Callback } from "../../../../types/Callback";
import { CapabilityCheck, capabilityMap } from "../../../capability";
import { Option } from "../../../settings/ViewProfile";
import "./customizeTechicalSpecificationModal.scss";
import { HiddenFields } from "./HiddenFields";
import LoadingMessage from "./LoadingMessage";
import { TechnicalSpecificationFields } from "./TechnicalSpecificationFields";

const RadioGroup = Radio.Group;
const { TextArea } = Input;

interface OwnProps {
  hasDataToDisplay: boolean;
  itemId?: number;
  projectId?: number;
  scope: string;
}

interface ReduxProps {
  languages: Language[];
  settings: SettingsState;
}

interface DispatchProps {
  getItemTechnicalSpecificationSettings: (itemId: number) => void;
  getLanguages: Callback;
  getTechSpecSettings: (params: GetTechSpecParams) => Promise<void>;
  resetTechSpecSettings: Callback;
}

interface IProps
  extends OwnProps,
    ReduxProps,
    DispatchProps,
    FormComponentProps,
    InjectedIntlProps {}

interface IState {
  forcedPdf?: boolean;
  alfaLavalLogo?: boolean;
  discountedPrice?: boolean;
  price?: boolean;
  logoFileName?: string;
  pdfFileName?: string;
  appendCustomPdf?: boolean;
}

class CustomizeTechnicalSpecificationModal extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      itemId,
      projectId,
      getItemTechnicalSpecificationSettings,
      getLanguages,
      getTechSpecSettings,
      resetTechSpecSettings,
    } = this.props;
    if (itemId) {
      getItemTechnicalSpecificationSettings(itemId);
    }
    resetTechSpecSettings();
    getLanguages();
    getTechSpecSettings({ itemId, projectId, purpose: "editing" });
  }

  setFlag = (flagName: string) => (value: boolean) => {
    const {
      form: { validateFields },
    } = this.props;
    let fieldToValidate = "";
    if (flagName === "price" || flagName === "discountedPrice") {
      fieldToValidate = "percentage";
    } else if (flagName === "alfaLavalLogo") {
      fieldToValidate = "logoFileName";
    } else if (flagName === "appendCustomPdf") {
      fieldToValidate = "pdfFileName";
    }
    if (fieldToValidate) {
      setTimeout(() => validateFields([fieldToValidate], { force: true }), 0);
    }
    this.setState(() => ({ [flagName]: value }));
  };

  customRequest = ({ file /*, onSuccess */ }: RcCustomRequestOptions) => {
    readBlobInBase64(file)
      .then((uploadLogo: string) => {
        const logoFileName = file.name || undefined;
        this.props.form.setFieldsValue({ uploadLogo, logoFileName });
        this.setState(() => ({ logoFileName }));
      })
      .catch(console.error);
  };

  customRequestPdf = ({ file /*, onSuccess */ }: RcCustomRequestOptions) => {
    readBlobInBase64(file)
      .then((customPdf: string) => {
        const pdfFileName = file.name || undefined;
        this.props.form.setFieldsValue({ customPdf, pdfFileName });
        this.setState(() => ({ pdfFileName }));
      })
      .catch(console.error);
  };

  checkFileSize = (file: RcFile) => {
    const LIMIT = 3; // in MB
    const isOk = file.size / 1024 / 1024 <= LIMIT;
    if (!isOk) {
      message.error(
        this.props.intl.formatMessage(messages.fileExceedsSizeLimit, {
          "1": LIMIT,
        })
      );
    }
    return isOk;
  };

  removeLogoFileName = () => {
    const logoFileName = "";
    const uploadLogo = "";
    this.props.form.setFieldsValue({ uploadLogo, logoFileName });
    this.setState(() => ({ logoFileName }));
  };

  removePdfFileName = () => {
    const pdfFileName = "";
    const customPdf = "";
    this.props.form.setFieldsValue({ customPdf, pdfFileName });
    this.setState(() => ({ pdfFileName }));
  };

  renderFormattedLoadingMessage = () => (
    <Row>
      <Col span={24} className="customize-modal__container">
        <Row>
          <Col
            className="customize-modal__section-title margin-bottom--40"
            span={24}
          >
            <LoadingMessage />
          </Col>
        </Row>
      </Col>
    </Row>
  );

  render() {
    const { hasDataToDisplay, intl, languages, scope, settings } = this.props;
    const { getFieldDecorator, getFieldValue, setFieldsValue } =
      this.props.form; // this.props.form is been injected by Form.create()
    const config = settings.techSpecSettingsForEditing;

    const {
      discountedPrice = config?.discountedPrice,
      alfaLavalLogo = config?.alfaLavalLogo,
      price = config?.price,
      logoFileName = config?.logoFileName,
      appendCustomPdf = config?.appendCustomPdf,
      pdfFileName = config?.pdfFileName,
    } = this.state;

    if (!hasDataToDisplay || config === undefined) {
      return this.renderFormattedLoadingMessage();
    }

    return (
      <Form className="customize-modal">
        <Row>
          <Col span={24} className="customize-modal__container">
            <Row>
              <Col
                className="customize-modal__section-title margin-bottom--40"
                span={24}
              >
                <FormattedMessage
                  id="technical specification"
                  defaultMessage="Technical Specification"
                />
              </Col>
            </Row>
            <CapabilityCheck showIfHas={capabilityMap.ITEM_EXP_TECH_SPEC}>
              <CapabilityCheck
                showIfHas={capabilityMap.ITEM_EXP_TECH_SPEC_WORD}
              >
                {/* "FORCED PDF" COL START */}
                <Row>
                  <Col span={24}>
                    <Form.Item
                      className="customize-modal__label"
                      label={
                        <FormattedMessage
                          id="forcedPdf"
                          defaultMessage="PDF VERSION"
                        />
                      }
                    >
                      {getFieldDecorator("forcedPdf", {
                        initialValue: config?.forcedPdf,
                        valuePropName: "checked",
                      })(<Switch onChange={this.setFlag("forcedPdf")} />)}
                    </Form.Item>
                  </Col>
                </Row>
                {/* "FORCED PDF" COL END */}
              </CapabilityCheck>
            </CapabilityCheck>

            {/* "INCLUDE ITEM TITLE" COL START */}
            <Row>
              <Col span={24}>
                <Form.Item
                  className="customize-modal__label"
                  label={
                    <FormattedMessage
                      id="includeItemTitle"
                      defaultMessage="INCLUDE ITEM TITLE"
                    />
                  }
                >
                  {getFieldDecorator("includeItemTitle", {
                    initialValue: config?.includeItemTitle,
                    valuePropName: "checked",
                  })(<Switch onChange={this.setFlag("includeItemTitle")} />)}
                </Form.Item>
              </Col>
            </Row>
            {/* "INCLUDE ITEM TITLE" COL END */}

            <CapabilityCheck
              showIfHas={capabilityMap.SETTINGS_TECH_SPEC_SHOW_EXT_VERSION}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    className="customize-modal__label"
                    label={
                      <FormattedMessage
                        id="version length"
                        defaultMessage="Version length"
                      />
                    }
                  >
                    {getFieldDecorator("versionLength", {
                      initialValue: config?.versionLength,
                    })(
                      <RadioGroup className="customize-modal__radio">
                        <Radio value={VersionLength.LONG}>
                          <FormattedMessage
                            id="long"
                            defaultMessage="Extended"
                          />
                        </Radio>
                        <Radio value={VersionLength.SHORT}>
                          <FormattedMessage id="short" defaultMessage="Short" />
                        </Radio>
                      </RadioGroup>
                    )}
                  </Form.Item>
                </Col>
                {/* "VERSION LENGTH" COL END */}
              </Row>
            </CapabilityCheck>

            <Row>
              {/* "LANGUAGE" COL START */}
              <Col span={24} className="customize-modal__cell">
                <Form.Item
                  className="customize-modal__label"
                  label={
                    <FormattedMessage id="language" defaultMessage="Language" />
                  }
                >
                  {getFieldDecorator("language", {
                    initialValue: config?.language,
                  })(
                    <Select className="customize-modal__select">
                      {languages?.map((language) => (
                        <Option value={language.key} key={language.id}>
                          {language.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {/* "LANGUAGE" COL END */}
            </Row>
            <Row>
              {/* "CLIENT DATA" COL START */}
              <Col span={24} className="customize-modal__cell">
                <Form.Item
                  className="customize-modal__label"
                  label={
                    <FormattedMessage
                      id="first name"
                      defaultMessage="FIRST NAME"
                    />
                  }
                >
                  {getFieldDecorator("firstName", {
                    initialValue: config?.firstName,
                  })(
                    <Input
                      placeholder={intl.formatMessage(
                        messages.inputInsertNamePlaceholder
                      )}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  className="customize-modal__label"
                  label={
                    <FormattedMessage
                      id="second name"
                      defaultMessage="SECOND NAME"
                    />
                  }
                >
                  {getFieldDecorator("lastName", {
                    initialValue: config?.lastName,
                  })(
                    <Input
                      placeholder={intl.formatMessage(
                        messages.inputInsertLastNamePlaceholder
                      )}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  className="customize-modal__label"
                  label={
                    <FormattedMessage id="address" defaultMessage="Address" />
                  }
                >
                  {getFieldDecorator("address", {
                    initialValue: config?.address,
                  })(
                    <Input
                      placeholder={intl.formatMessage(
                        messages.inputInsertAddressPlaceholder
                      )}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            {/* "CLIENT DATA" COL END */}

            {scope === settingsInfo.scopes.PROJECT ? (
              <CapabilityCheck
                showIfHas={capabilityMap.SETTINGS_TECH_SPEC_DOWNLOAD_FULL_OFFER}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      className="customize-modal__label"
                      label={
                        <FormattedMessage
                          id="offerNumber"
                          defaultMessage="Offer N°"
                        />
                      }
                    >
                      {getFieldDecorator("offerNumber", {
                        initialValue: config?.offerNumber,
                      })(
                        <Input
                          placeholder={intl.formatMessage(
                            messages.inputInsertOfferNumberPlaceholder
                          )}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      className="customize-modal__label"
                      label={
                        <FormattedMessage
                          id="offerDate"
                          defaultMessage="Offer date"
                        />
                      }
                    >
                      {getFieldDecorator("offerDate", {
                        initialValue: config?.offerDate
                          ? moment(config?.offerDate)
                          : null,
                      })(<DatePicker />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      className="customize-modal__label"
                      label={
                        <FormattedMessage
                          id="subject"
                          defaultMessage="Subject"
                        />
                      }
                    >
                      {getFieldDecorator("subject", {
                        initialValue: config?.subject,
                      })(
                        <Input
                          placeholder={intl.formatMessage(
                            messages.inputInsertSubjectPlaceholder
                          )}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      className="customize-modal__label"
                      label={
                        <FormattedMessage
                          id="reference"
                          defaultMessage="Reference"
                        />
                      }
                    >
                      {getFieldDecorator("reference", {
                        initialValue: config?.reference,
                      })(
                        <Input
                          placeholder={intl.formatMessage(
                            messages.inputInsertReferencePlaceholder
                          )}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      className="customize-modal__label"
                      label={
                        <FormattedMessage
                          id="input.note"
                          defaultMessage="Note"
                        />
                      }
                    >
                      {getFieldDecorator("note", {
                        initialValue: config?.note,
                      })(
                        <TextArea
                          placeholder={intl.formatMessage(
                            messages.inputInsertNotePlaceholder
                          )}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      className="customize-modal__label"
                      label={
                        <FormattedMessage
                          id="Append custom PDF"
                          defaultMessage="Append custom PDF"
                        />
                      }
                    >
                      {getFieldDecorator("appendCustomPdf", {
                        initialValue: config?.appendCustomPdf,
                        valuePropName: "checked",
                      })(<Switch onChange={this.setFlag("appendCustomPdf")} />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      className="customize-modal__label"
                      label={
                        pdfFileName
                          ? intl.formatMessage(messages.customPdf)
                          : undefined
                      }
                    >
                      {pdfFileName ? (
                        <Tag
                          closable
                          onClose={this.removePdfFileName}
                          className="customize__file-list"
                        >
                          <div className="limit-text-tag ellipsis">
                            {pdfFileName}
                          </div>
                        </Tag>
                      ) : (
                        intl.formatMessage(messages.noCustomPdf)
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      className="customize-modal__label"
                      label={
                        <FormattedMessage
                          id="upload custom pdf"
                          defaultMessage="UPLOAD CUSTOM PDF"
                        />
                      }
                    >
                      <Upload
                        className="customize-modal__upload"
                        customRequest={this.customRequestPdf}
                        beforeUpload={this.checkFileSize}
                        accept=".pdf,.docx"
                        disabled={!appendCustomPdf}
                      >
                        <Button
                          type="primary"
                          ghost
                          className="margin-top--10"
                          disabled={!appendCustomPdf}
                        >
                          <FormattedMessage
                            id="Upload PDF"
                            defaultMessage="Upload PDF"
                          />
                        </Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("pdfFileName", {
                        initialValue: config?.pdfFileName,
                        rules: [
                          {
                            required: appendCustomPdf,
                            message: (
                              <FormattedMessage
                                id="custom pdf required"
                                defaultMessage="A file PDF is required (Max 3MB)"
                              />
                            ),
                          },
                        ],
                      })(<div />)}
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator("customPdf", {
                        initialValue: config?.customPdf,
                      })(<input type="hidden" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={
                        <FormattedMessage
                          id="offerDiscount"
                          defaultMessage="Offer discount"
                        />
                      }
                      className="customize-modal__label"
                    >
                      {getFieldDecorator("offerDiscount", {
                        rules: [
                          {
                            pattern: /^([1-9]?\d)?(\.\d{1,2})?$/,
                            message: intl.formatMessage(
                              validationMessages["validation.range"],
                              {
                                "1": "0",
                                "2": "99.99",
                              }
                            ),
                          },
                        ],
                        initialValue: config?.offerDiscount,
                      })(
                        <InputNumber
                          size="large"
                          placeholder={intl.formatMessage(
                            messages.inputInsertOfferDiscountPlaceholder
                          )}
                          precision={2}
                          min={0}
                          max={99}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </CapabilityCheck>
            ) : null}

            {/* "LOGO" COL START */}
            <CapabilityCheck
              showIfHas={capabilityMap.SETTINGS_TECH_SPEC_CUSTOMIZE_LOGO}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    className="customize-modal__label"
                    label={
                      <FormattedMessage
                        id="luve logo"
                        defaultMessage="LUVE LOGO"
                      />
                    }
                  >
                    {getFieldDecorator("alfaLavalLogo", {
                      initialValue: config?.alfaLavalLogo,
                      valuePropName: "checked",
                    })(<Switch onChange={this.setFlag("alfaLavalLogo")} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    className="customize-modal__label"
                    label={
                      logoFileName
                        ? intl.formatMessage(messages.otherLogo)
                        : undefined
                    }
                  >
                    {logoFileName ? (
                      <Tag
                        closable
                        onClose={this.removeLogoFileName}
                        className="customize__file-list"
                      >
                        <div className="limit-text-tag ellipsis">
                          {logoFileName}
                        </div>
                      </Tag>
                    ) : (
                      intl.formatMessage(messages.noOtherLogo)
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    className="customize-modal__label"
                    label={
                      <FormattedMessage
                        id="upload another logo"
                        defaultMessage="UPLOAD ANOTHER LOGO"
                      />
                    }
                  >
                    <Upload
                      className="customize-modal__upload"
                      customRequest={this.customRequest}
                      // name="uploadLogo"
                      accept=".jpg,.jpeg,.png"
                      disabled={alfaLavalLogo}
                    >
                      <Button
                        type="primary"
                        ghost
                        className="margin-top--10"
                        disabled={alfaLavalLogo}
                      >
                        Upload file
                      </Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("logoFileName", {
                      initialValue: config?.logoFileName,
                      rules: [
                        {
                          required: !alfaLavalLogo,
                          message: (
                            <FormattedMessage
                              id="logo error"
                              defaultMessage="A logo is required"
                            />
                          ),
                        },
                      ],
                    })(<div />)}
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator("uploadLogo", {
                      initialValue: config?.uploadLogo,
                    })(<input type="hidden" />)}
                  </Form.Item>
                </Col>
              </Row>
            </CapabilityCheck>
            <CapabilityCheck showIfHas={capabilityMap.ITEM_HIDE_EUROVENT_LOGO}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={
                      <FormattedMessage
                        id="certificate logo"
                        defaultMessage="CERTIFICATE LOGO"
                      />
                    }
                    className="customize-modal__label"
                  >
                    {getFieldDecorator("certificateLogo", {
                      initialValue: config?.certificateLogo,
                      valuePropName: "checked",
                    })(<Switch />)}
                  </Form.Item>
                </Col>
              </Row>
            </CapabilityCheck>
            {/* "LOGO" COL END */}
            <Row>
              <Col span={24} className="customize-modal__cell">
                <Form.Item
                  label={
                    <FormattedMessage id="drawing" defaultMessage="DRAWING" />
                  }
                  className="customize-modal__label"
                >
                  {getFieldDecorator("drawing", {
                    initialValue: config?.drawing,
                    valuePropName: "checked",
                  })(<Switch />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="customize-modal__cell">
                <Form.Item
                  label={
                    <FormattedMessage
                      id="displayItemNote"
                      defaultMessage="INCLUDE NOTES"
                    />
                  }
                  className="customize-modal__label"
                >
                  {getFieldDecorator("displayItemNote", {
                    initialValue: config?.displayItemNote,
                    valuePropName: "checked",
                  })(<Switch />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={
                    <FormattedMessage
                      id="spare parts"
                      defaultMessage="SPARE PARTS"
                    />
                  }
                  className="customize-modal__label"
                >
                  {getFieldDecorator("spareParts", {
                    initialValue: config?.spareParts,
                    valuePropName: "checked",
                  })(<Switch />)}
                </Form.Item>
              </Col>
            </Row>
            <CapabilityCheck showIfHas={capabilityMap.USER_SHOW_PRICES}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={
                      <FormattedMessage id="price" defaultMessage="PRICE" />
                    }
                    className="customize-modal__label"
                  >
                    {getFieldDecorator("price", {
                      initialValue: config?.price,
                      valuePropName: "checked",
                    })(<Switch onChange={this.setFlag("price")} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={
                      <FormattedMessage
                        id="apply discount"
                        defaultMessage="APPLY DISCOUNT"
                      />
                    }
                    className="customize-modal__label"
                  >
                    {getFieldDecorator("discountedPrice", {
                      initialValue: config?.discountedPrice,
                      valuePropName: "checked",
                    })(
                      <Switch
                        onChange={this.setFlag("discountedPrice")}
                        disabled={!price}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={
                      <FormattedMessage
                        id="percentage"
                        defaultMessage="PERCENTAGE"
                      />
                    }
                    className="customize-modal__label"
                  >
                    {getFieldDecorator("percentage", {
                      rules: [
                        {
                          required: price && discountedPrice,
                          message: intl.formatMessage(
                            validationMessages["validation.required"],
                            {
                              "1": intl.formatMessage({
                                id: "percentage",
                                defaultMessage: "Percentage",
                              }),
                            }
                          ),
                        },
                        {
                          pattern: /^([1-9]?\d)?(\.\d{1,2})?$/,
                          message: intl.formatMessage(
                            validationMessages["validation.range"],
                            {
                              "1": "0",
                              "2": "99.99",
                            }
                          ),
                        },
                      ],
                      initialValue: config?.percentage,
                    })(
                      <InputNumber
                        size="large"
                        placeholder={intl.formatMessage(
                          messages.inputNumberExamplePercPlaceholder
                        )}
                        min={0}
                        max={99}
                        precision={2}
                        disabled={!price || !discountedPrice}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={
                      <FormattedMessage
                        id="hide discount values"
                        defaultMessage="HIDE DISCOUNT VALUES"
                      />
                    }
                    className="customize-modal__label"
                  >
                    {getFieldDecorator("showOnlyDiscountedPrice", {
                      initialValue: config?.showOnlyDiscountedPrice,
                      valuePropName: "checked",
                    })(
                      <Switch
                        onChange={this.setFlag("showOnlyDiscountedPrice")}
                        disabled={!price || !discountedPrice}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={
                      <FormattedMessage
                        id="showSoundPowerLevel"
                        defaultMessage="SHOW SOUND POWER LEVEL"
                      />
                    }
                    className="customize-modal__label"
                  >
                    {getFieldDecorator("showSoundPowerLevel", {
                      initialValue: config?.showSoundPowerLevel,
                      valuePropName: "checked",
                    })(
                      <Switch onChange={this.setFlag("showSoundPowerLevel")} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </CapabilityCheck>
            <CapabilityCheck
              showIfHas={capabilityMap.SETTINGS_TECH_SPEC_CUSTOMIZE_FIELDS}
            >
              <HiddenFields
                excludedFields={config?.excludedFields}
                getFieldDecorator={getFieldDecorator}
              />
              <TechnicalSpecificationFields
                getFieldValue={getFieldValue}
                setFieldsValue={setFieldsValue}
                parentExcludedFields={config?.parentConfig?.excludedFields}
              />
            </CapabilityCheck>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state: IStore): ReduxProps => ({
  languages: state.account.languages,
  settings: state.settings,
});

const mapDispatchToProps = {
  getItemTechnicalSpecificationSettings,
  getLanguages,
  getTechSpecSettings,
  resetTechSpecSettings,
};

const WithIntl = injectIntl<IProps>(CustomizeTechnicalSpecificationModal, {
  withRef: true,
});

const WithForm = Form.create<
  OwnProps & ReduxProps & DispatchProps & FormComponentProps
>()(WithIntl);

export default connect(mapStateToProps, mapDispatchToProps, undefined, {
  forwardRef: true,
})(WithForm);
