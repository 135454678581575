import React, { Fragment } from "react";

export function replaceWith(
  separator: string,
  rep: React.ReactNode,
  value?: string
) {
  const [firstPart, ...rest] = value?.split(separator) ?? [];

  return (
    <>
      {firstPart}
      {rest?.map((part, index) => (
        <Fragment key={index}>
          {rep}
          {part}
        </Fragment>
      ))}
    </>
  );
}
