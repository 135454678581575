import IAction from "../../../types/action";
import { FAILURE, REQUEST, SUCCESS } from "../../lib/asyncActionHelper";
import {
  QUOTE_ITEMS,
  UPDATE_CUSTOMER_REFERENCE,
  UPDATE_ITEM_QUANTITY,
} from "../project/consts";
import * as types from "./consts";
import { QuoteModalState } from "./types";

const initialState: QuoteModalState = {
  ids: [],
  loading: false,
  list: [],
};

export default (
  state: QuoteModalState = initialState,
  action: IAction
): QuoteModalState => {
  switch (action.type) {
    // LOAD_QUOTE_INPUT
    case REQUEST(types.LOAD_QUOTE_INPUT): {
      const listIds = state.list.map((item) => item.itemId);
      const clearList =
        JSON.stringify(action.payload) !== JSON.stringify(listIds);
      return {
        ...state,
        ids: action.payload,
        ...(clearList ? { list: [] } : {}),
        loading: true,
      };
    }
    case SUCCESS(types.LOAD_QUOTE_INPUT):
      return { ...state, list: action.payload, loading: false };
    case FAILURE(types.LOAD_QUOTE_INPUT):
      return { ...state, loading: false };

    // UPDATE_ITEM_QUANTITY
    case REQUEST(UPDATE_ITEM_QUANTITY):
      return {
        ...state,
        list: state.list.map((item) => {
          return item.itemId === action.payload.id
            ? { ...item, updating: true }
            : item;
        }),
      };
    case SUCCESS(UPDATE_ITEM_QUANTITY):
      return {
        ...state,
        list: state.list.map((item) => {
          return item.itemId === action.payload.id
            ? {
                ...item,
                itemQuantity: action.payload.quantity,
                updating: undefined,
              }
            : item;
        }),
      };
    case FAILURE(UPDATE_ITEM_QUANTITY):
      return {
        ...state,
        list: state.list.map((item) => {
          return item.itemId === action.payload.id
            ? { ...item, updating: undefined }
            : item;
        }),
      };

    // UPDATE_CUSTOMER_REFERENCE
    case REQUEST(UPDATE_CUSTOMER_REFERENCE):
      return {
        ...state,
        list: state.list.map((item) => {
          return item.itemId === action.payload.id
            ? { ...item, updatingCR: true }
            : item;
        }),
      };
    case SUCCESS(UPDATE_CUSTOMER_REFERENCE):
      return {
        ...state,
        list: state.list.map((item) => {
          return item.itemId === action.payload.id
            ? {
                ...item,
                customerReference: action.payload.customerReference,
                updatingCR: undefined,
              }
            : item;
        }),
      };
    case FAILURE(UPDATE_CUSTOMER_REFERENCE):
      return {
        ...state,
        list: state.list.map((item) => {
          return item.itemId === action.payload.id
            ? { ...item, updatingCR: undefined }
            : item;
        }),
      };

    // QUOTE_ITEMS
    case REQUEST(QUOTE_ITEMS):
      return { ...state, saving: true };
    case SUCCESS(QUOTE_ITEMS):
      return { ...state, saving: false };
    case FAILURE(QUOTE_ITEMS):
      return { ...state, saving: false };

    default:
      return state;
  }
};
