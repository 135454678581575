import { Tooltip } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import BestChoiceIcon from "./BestChoiceIcon";
import BestRatioIcon from "./BestRatioIcon";
import GreenChoiceIcon from "./GreenChoiceIcon";

interface IProps {
  bestChoice?: boolean;
  bestRatio?: boolean;
  mostSustainable?: boolean;
}

export const BestIcons: React.FC<IProps> = ({
  bestChoice,
  bestRatio,
  mostSustainable,
}) => {
  return (
    <div className={"best-icons"}>
      {bestChoice && (
        <Tooltip
          overlayClassName="no-arrow"
          title={
            <FormattedMessage id="best choice" defaultMessage="BEST CHOICE" />
          }
        >
          <BestChoiceIcon />
        </Tooltip>
      )}
      {bestRatio && (
        <Tooltip
          overlayClassName="no-arrow"
          title={
            <FormattedMessage id="best ratio" defaultMessage="BEST RATIO" />
          }
        >
          <BestRatioIcon />
        </Tooltip>
      )}
      {mostSustainable && (
        <Tooltip
          overlayClassName="no-arrow"
          title={
            <FormattedMessage
              id="most sustainable green choice"
              defaultMessage="MOST SUSTAINABLE GREEN CHOICE"
            />
          }
        >
          <GreenChoiceIcon />
        </Tooltip>
      )}
    </div>
  );
};
