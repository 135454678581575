import { Alert, Checkbox, Form, Spin, Switch } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { ApplicationTypeSectionTitle } from "./ApplicationTypeSectionTitle";
import { useApplicationType } from "./hooks/useApplicationType";
import { useReportFields } from "./hooks/useReportFields";

const compareArrays = (a: string[] = [], b: string[] = []): boolean => {
  if (a.length !== b.length) return false;
  const sortedA = [...a].sort();
  const sortedB = [...b].sort();
  return sortedA.every((element, index) => element === sortedB[index]);
};

interface Props {
  currentApplicationType: number;
  getFieldValue: WrappedFormUtils["getFieldValue"];
  parentExcludedFields: string[];
  setFieldsValue: WrappedFormUtils["setFieldsValue"];
}
export function ApplicationTypeFieldsForm({
  currentApplicationType,
  getFieldValue,
  parentExcludedFields,
  setFieldsValue,
}: Readonly<Props>) {
  const applicationType = useApplicationType(currentApplicationType);

  const hiddenFieldName = `excludedFields.at${currentApplicationType}`;

  const [defaultChecked, setDefaultChecked] = React.useState(
    compareArrays(parentExcludedFields, getFieldValue(hiddenFieldName))
  );

  const [reportSectionWithOptions, loading, error] = useReportFields(
    currentApplicationType
  );

  const allValues = useMemo(
    () =>
      reportSectionWithOptions
        .map(
          (reportSection) =>
            reportSection.options?.map((option) => option.value) ?? []
        )
        .flat()
        .filter((value) => value !== undefined),
    [reportSectionWithOptions]
  );

  const retrieveInitialValueFrom = () => {
    const excludedValues = getFieldValue(hiddenFieldName);
    return allValues.filter((value) => !excludedValues?.includes(value));
  };

  const onChange = useCallback(
    (values: string[]) => {
      const excludedValues = allValues.filter(
        (value) => !values?.includes(value as string)
      );
      setFieldsValue({ [hiddenFieldName]: excludedValues });
    },
    [allValues, hiddenFieldName, setFieldsValue]
  );

  const onDefaultCheckedChange = useCallback(
    (checked: boolean) => {
      setDefaultChecked(checked);
      if (checked) {
        const excludedValues = allValues.filter((value) =>
          parentExcludedFields.includes(value as string)
        );
        setFieldsValue({ [hiddenFieldName]: excludedValues });
      }
    },
    [
      allValues,
      hiddenFieldName,
      parentExcludedFields,
      setDefaultChecked,
      setFieldsValue,
    ]
  );

  return (
    <div
      className="application-type-form-animation"
      key={currentApplicationType}
    >
      <ApplicationTypeSectionTitle applicationType={applicationType} />
      {error && (
        <Alert
          type={"error"}
          message={
            <FormattedMessage
              id={"error-loading-fields-for-application-type"}
              defaultMessage={
                "There was an error loading the field list for the selected application type"
              }
            />
          }
          style={{ maxWidth: "100%" }}
        />
      )}
      {!error && loading && <Spin size="small" />}
      {!error && !loading && (
        <div>
          <Form.Item
            className="bounds__label"
            label={
              <FormattedMessage
                id="setToDefaults"
                defaultMessage="Set default values"
              />
            }
          >
            <Switch
              checked={defaultChecked}
              onChange={onDefaultCheckedChange}
            />
          </Form.Item>

          <Form.Item
            // When flag "Set default values" change value: resetting of form checkboxes to new initial values
            key={defaultChecked ? "default" : "custom"}
            label={
              <FormattedMessage
                id="Select fields to display"
                defaultMessage="Select fields to display"
              />
            }
            className="customize-modal__label"
          >
            <Checkbox.Group
              onChange={onChange}
              defaultValue={retrieveInitialValueFrom()}
              className={"vertical"}
              disabled={defaultChecked}
            >
              {reportSectionWithOptions
                .filter((reportSection) =>
                  Boolean(reportSection.options?.length)
                )
                .map((reportSection) => (
                  <div key={reportSection.id} className={"report-section"}>
                    <div className={"report-section-title"}>
                      {reportSection.logo && (
                        <img
                          src={reportSection.logo}
                          width={18}
                          height={18}
                          alt={reportSection.description ?? ""}
                        />
                      )}
                      <span>{reportSection.name}</span>
                    </div>
                    {reportSection.options?.map((option) => (
                      <div key={`${option.value}`}>
                        <Checkbox value={option.value}>{option.label}</Checkbox>
                      </div>
                    ))}
                  </div>
                ))}
            </Checkbox.Group>
          </Form.Item>
        </div>
      )}
    </div>
  );
}
