import { Icon, Tooltip } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CapabilityCheck, capabilityMap } from "../../../capability";

interface IProps {
  className?: string;
  drawingUrl?: string;
  dxfDrawingUrl?: string;
  printPdf: (unitId?: string) => void;
  unitId?: string;
}

export default class TableCellTechButtons extends React.Component<IProps> {
  printPdf = () => {
    const { printPdf, unitId } = this.props;
    printPdf(unitId);
  };

  render(): React.ReactNode {
    const { className, dxfDrawingUrl, drawingUrl } = this.props;

    return (
      <div className={className}>
        <CapabilityCheck showIfHas={capabilityMap.ITEM_EXP_TECH_SPEC}>
          <Tooltip
            placement={"top"}
            title={
              <FormattedMessage
                id={"download technical specification"}
                defaultMessage={"Download Technical specification"}
              />
            }
            overlayClassName={"no-arrow"}
          >
            <Icon
              type="download"
              theme="outlined"
              className="icon--blue icon--pointer"
              onClick={this.printPdf}
            />
          </Tooltip>
        </CapabilityCheck>

        {drawingUrl && (
          <Tooltip
            placement={"top"}
            title={
              <FormattedMessage
                id={"view drawing"}
                defaultMessage={"View Drawing"}
              />
            }
            overlayClassName={"no-arrow"}
          >
            <a href={drawingUrl} target="_blank" rel="noopener noreferrer">
              <Icon
                type="picture"
                theme="outlined"
                className="icon--blue icon--pointer"
              />
            </a>
          </Tooltip>
        )}

        {dxfDrawingUrl && (
          <CapabilityCheck
            showIfHasOneOf={[
              capabilityMap.ITEM_DOWNLOAD_SINGLE_UNIT_DXF,
              capabilityMap.ITEM_DOWNLOAD_ALL_UNIT_DXF,
            ]}
          >
            <Tooltip
              placement={"top"}
              title={
                <FormattedMessage
                  id={"download Drawing in dxf"}
                  defaultMessage={"Download Drawing in DXF"}
                />
              }
              overlayClassName={"no-arrow"}
            >
              <a
                href={dxfDrawingUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="icon--blue icon--pointer"
                style={{ fontSize: "12px" }}
              >
                DXF
              </a>
            </Tooltip>
          </CapabilityCheck>
        )}
      </div>
    );
  }
}
