import classNames from "classnames";
import React from "react";
import { BestIcons } from "./BestIcons";

interface IProps {
  bestChoice?: boolean;
  bestRatio?: boolean;
  mostSustainable?: boolean;
  bulletColorCssClass: string;
  className?: string;
  isSelected: boolean;
  unitName?: string;
}

const TableCellUnit: React.FC<IProps> = ({
  bestChoice,
  bestRatio,
  mostSustainable,
  bulletColorCssClass,
  className,
  isSelected,
  unitName,
}) => {
  return (
    <div
      className={classNames("cell-unit", className, {
        "selected-unit": isSelected,
      })}
    >
      <BestIcons
        bestChoice={bestChoice}
        bestRatio={bestRatio}
        mostSustainable={mostSustainable}
      />
      <div
        className={classNames("cell-unit--unit-name", {
          [bulletColorCssClass]: Boolean(bulletColorCssClass),
        })}
      >
        {unitName}
      </div>
    </div>
  );
};

export default TableCellUnit;
