import { CheckboxOptionType } from "antd/lib/checkbox";
import { useEffect, useState } from "react";
import { getReportFields } from "../../../../../shared/store/settings/actions";
import { ReportSection } from "../../../../../generated/axios";

interface ReportSectionWithOptions extends Omit<ReportSection, "fields"> {
  options?: CheckboxOptionType[];
}

export const useReportFields = (currentApplicationType: number) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<ReportSectionWithOptions[]>([]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    getReportFields(currentApplicationType)
      .then((data) => {
        const optionsList = data.map(({ fields, ...reportSection }) => ({
          ...reportSection,
          options: fields?.map((reportData) => ({
            value: reportData.fieldId,
            label: reportData.label,
          })),
        }));
        setOptions(optionsList);
      })
      .catch((e) => {
        console.error(e);
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [currentApplicationType, setError, setLoading, setOptions]);

  return [options, loading, error] as [
    ReportSectionWithOptions[],
    boolean,
    boolean,
  ];
};
