{
  "1 unit": "1 unit",
  "Append custom PDF": "Append custom PDF",
  "ERROR_SHOWING_ITEM": "Not able to show the item",
  "ERROR_UPPERCASE": "ERROR",
  "Fields to display": "Fields to display",
  "Missing results": "Missing results",
  "Number of units": "Number of units",
  "Quoting is not allowed for a default project item": "Quoting is not allowed for a default project item.",
  "Save item into different project for quoting": "Save this item into a different project to proceed with quoting.",
  "Select fields to display": "Select fields to display",
  "Upload PDF": "Upload PDF",
  "accountAlreadyActivated": "The account has already been activated",
  "accountDeactivated": "The account has been deactivated",
  "actual visible fields": "Actual visible fields",
  "add a note": "ADD A NOTE TO PROJECT",
  "additional details": "Additional details",
  "address": "Address",
  "address.placeholder": "Insert your address here",
  "api.error.message.400": "The request cannot be satisfied due to errors.",
  "api.error.message.400.csv": "One or more items among those selected cannot be exported",
  "api.error.message.400.get_results": "Calculation returned an error. Try to change thermal data and press calculate.",
  "api.error.message.400.get_results.inpage": "Calculation returned an error.<br>Try to change thermal data and press calculate.",
  "api.error.message.403.get_results": "Core calculation returned too many results. Download the csv file to see all the results.",
  "api.error.message.403.get_results.inpage": "Core calculation returned too many results.<br>Download the csv file to see all the results.",
  "api.error.message.404.get_results": "No result available, press calculate.",
  "api.error.message.409.get_results": "Error during server comunication. Please press Calculate again.",
  "api.error.message.500": "Error 500: Internal Server Error",
  "api.error.message.502": "Error 502: Service unavailable. Please try again in a few minutes.",
  "api.error.message.502.inpage": "Error 502: Service unavailable.<br>Please try again in a few minutes.",
  "api.error.message.503": "Error 503: Service unavailable. Please try again in a few minutes.",
  "api.error.message.503.get_results": "Calculation service unavailable. Please try again in a few minutes.",
  "api.error.message.503.get_results.inpage": "Calculation service unavailable.<br>Please try again in a few minutes.",
  "api.error.message.503.inpage": "Error 503: Service unavailable.<br>Please try again in a few minutes.",
  "api.error.message.account-already-activated": "Account already activated",
  "api.error.message.account-deactivated": "The account has been deactivated, contact the administrators",
  "api.error.message.account-not-confirmed": "User has not confirmed registration",
  "api.error.message.default": "Operation failed, retry later",
  "api.error.message.default.get_results": "Calculation returned a generic error.",
  "api.error.message.email-already-used": "Email is already in use!",
  "api.error.message.email-not-found": "Email address not registered",
  "api.error.message.incorrect-password": "The old password is wrong",
  "api.error.message.login-already-used": "Login name already used!",
  "api.error.message.missing-capability": "Missing necessary permissions to perform this operation",
  "api.error.message.same-email": "The provided email is the same as the current email",
  "api.error.message.weak-password": "The password is too weak",
  "apply discount": "APPLY DISCOUNT",
  "available fields": "Available fields",
  "best choice": "BEST CHOICE",
  "best ratio": "BEST RATIO",
  "bounds": "Bounds",
  "calculate results": "CALCULATE RESULTS",
  "calculated value": "Calculated value",
  "calculating results": "Calculating results...",
  "calculationResultsOf": "Calculation results of {date}",
  "cancel": "Cancel",
  "cancel request warning": "Press 'Stop calculation' to interrupt the process",
  "certificate logo": "CERTIFICATE LOGO",
  "change e-mail": "change e-mail",
  "change email ok": "Change email request processed, an e-mail was sent to you",
  "change email ok return": "Change email request processed successfully, remember to use the new email to authenticate to Plair",
  "change email warning": "Changing email address will change your username. You will receive an email with a link to confirm your action. After that you must use new email address to authenticate to Plair",
  "change psw": "Change password",
  "change psw ok": "Change password request processed",
  "change results reset": "Changing input values, results will be reset",
  "change your e-mail": "change your e-mail",
  "changed psw ok": "Password changed correctly",
  "choose range products": "Choose a range of products",
  "city": "city",
  "client data": "Client Data",
  "close": "Close",
  "comment problem": "Type a comment below regarding the problem you've encountered. Maximum lenght 300 words.",
  "company": "Company",
  "company.placeholder": "Insert your company name here",
  "condensingTemperatureTolerance": "Condensing Temperature Tolerance",
  "confirm": "Confirm",
  "confirm new password": "confirm new password",
  "confirm unshare": "Confirm unshare",
  "confirm unshare body": "Are you sure you want to delete the share with {email}?",
  "confirm.password.placeholder": "Confirm your new password!",
  "confirm.password.required": "Please confirm your new password!",
  "confirm_password": "Confirm Password",
  "confirmation question": "Are you sure you want to change your password?",
  "continue": "Continue",
  "country": "Country",
  "create new project": "Create new project",
  "create quote": "CREATE QUOTE",
  "custom pdf required": "A file PDF is required (Max 3MB)",
  "customPdf": "Custom PDF",
  "customerRefPlaceholder": "Customer ref.",
  "customerReference": "REF. CUSTOMER",
  "customize": "CUSTOMIZE",
  "customize technical specification": "Customize Technical Specification",
  "date": "DATE",
  "delete item": "Delete Item",
  "delete project": "Delete Project",
  "discountPlaceholder": "Discount",
  "displayItemNote": "INCLUDE NOTES",
  "download Drawing in dxf": "Download Drawing in DXF",
  "download all": "Download all",
  "download technical specification": "Download Technical specification",
  "drawing": "DRAWING",
  "duplicate item": "Duplicate Item",
  "duplicate project": "Duplicate Project",
  "email": "Email",
  "email.invalid": "The input is not valid E-mail!",
  "email.placeholder": "Insert your new e-mail here",
  "email.required": "Please input your new e-mail!",
  "empty": "Empty",
  "enter share": "Enter user's email to start sharing",
  "err.input.password": "Please input your password!",
  "err.input.username": "Please input your username!",
  "error": "Error",
  "error no user": "Error: No user was found for the provided reset key, or the email is already in use.",
  "error-loading-fields-for-application-type": "There was an error loading the field list for the selected application type",
  "evaporatingTemperatureTolerance": "Evaporating Temperature Tolerance",
  "exception application types": "Error on Application Types",
  "exception item": "Error on Item",
  "exception items": "Error on Items",
  "exception project": "Error on Project",
  "exception projects": "Error on Projects",
  "export all drawings": "Export all drawings",
  "export all dxf": "Export all DXF",
  "export csv": "Export results in CSV",
  "field-lists": "Field List",
  "fileExceedLimit": "The file size exceeds the {1} MB limit",
  "first name": "FIRST NAME",
  "firstName": "Name",
  "firstname.placeholder": "Insert your first name here",
  "forcedPdf": "PDF VERSION",
  "forgot psw": "Forgot password?",
  "generating pdf": "Generating pdf...",
  "go": "GO",
  "guided search": "GUIDED SEARCH",
  "hide discount values": "HIDE DISCOUNT VALUES",
  "horizontal": "Horizontal",
  "import shared item": "Import Shared Item",
  "import shared project": "Import Shared Project",
  "includeItemTitle": "INCLUDE ITEM TITLE",
  "indoor": "Indoor",
  "input.insert.address.placeholder": "Insert address here",
  "input.insert.lastname.placeholder": "Insert second name here",
  "input.insert.name.placeholder": "Insert name here",
  "input.insert.note.placeholder": "Insert note here",
  "input.insert.offer_discount.placeholder": "Insert offer discount here",
  "input.insert.offer_number.placeholder": "Insert offer number here",
  "input.insert.reference.placeholder": "Insert reference here",
  "input.insert.subject.placeholder": "Insert subject here",
  "input.note": "Note",
  "inputRequiredPrivacyPolicy": "Required",
  "instructions": "instructions",
  "interface language": "Interface Language",
  "item settings": "Item Settings",
  "item title": "ITEM TITLE",
  "itemDescription": "Item Description",
  "kit list": "Kit List",
  "kit price": "Kit Price",
  "language": "Language",
  "languages": "Languages",
  "last opened": "LAST OPENED",
  "lastname.placeholder": "Insert your last name here",
  "loading": "Loading...",
  "lock": "Lock",
  "log out": "Log Out",
  "login": "LOGIN",
  "login error": "Incorrect username or password, please retry",
  "login error bad request": "There's been an error, please try again later",
  "login.message": "to access your account",
  "logo": "Logo",
  "logo error": "A logo is required",
  "long": "Extended",
  "luve logo": "LU-VE LOGO",
  "maxFluidPressureDrop": "Max Fluid Pressure Drop",
  "maxResults": "Max Results",
  "maximumCapacityTolerance": "Max Capacity Tolerance",
  "measurement system": "Measurement System",
  "mechanicalConfigurationSidebarTitle": "Mechanical Configuration",
  "message.account_updated": "Account updated",
  "message.adding_share": "Adding share",
  "message.adding_the_note": "Adding the note",
  "message.all_units_unlocked": "All unit unlocked",
  "message.clear_completed": "Clear completed",
  "message.clearing_item": "Clearing item",
  "message.configuration_correctly_saved": "Configuration correctly saved",
  "message.creating_quote": "Creating quote",
  "message.creating_the_project": "Creating the project",
  "message.csv_exported_successfully": "CSV exported successfully",
  "message.data_loading": "Data loading",
  "message.delete_completed": "Delete completed",
  "message.deleting_item": "Deleting item",
  "message.deleting_project": "Deleting project",
  "message.duplicate_completed": "Duplicate completed",
  "message.duplicating_item": "Duplicating item",
  "message.duplicating_project": "Duplicating project",
  "message.error_in_saving_configuration": "Error in saving configuration",
  "message.error_loading_results_prices": "Error loading prices",
  "message.error_saving_item": "Error saving item",
  "message.exporting_csv": "Exporting CSV",
  "message.form_not_valid": "Form is not valid, please check",
  "message.generating_pdf": "Generating PDF",
  "message.import_completed": "Import completed",
  "message.import_item_completed": "Import item completed",
  "message.importing_shared_item": "Importing shared item",
  "message.importing_shared_project": "Importing shared project",
  "message.invalid_data": "Invalid data",
  "message.invalid_item_name": "Invalid item name",
  "message.item-active-in-another-tab": "This item is active in another tab. You will be redirected to the home page.",
  "message.item_shared_successfully": "Item shared successfully",
  "message.loading_completed": "Loading completed",
  "message.loading_data": "Loading data",
  "message.loading_data_item": "Loading data item",
  "message.loading_item_completed": "Loading item completed",
  "message.loading_items": "Loading items",
  "message.loading_new_item_data": "Loading new item data...",
  "message.loading_results": "Loading results...",
  "message.locking_unit": "Locking unit...",
  "message.locking_unit_error": "Error locking unit",
  "message.mechanical_configuration_form_not_valid": "Mechanical configuration form is not valid, please check",
  "message.no_result_found_check_thermal_configuration": "No result found, please check thermal configuration and press calculate",
  "message.operation_failed": "Operation failed",
  "message.operation_failed_try_later": "Operation failed. Please retry later",
  "message.operation_still_in_progress": "This operation is still in progress",
  "message.operation_success": "Operation success",
  "message.pdf_is_ready": "PDF is ready",
  "message.please_wait": "Please wait",
  "message.please_wait_few_seconds": "Just a few seconds, please wait",
  "message.please_wait_still_loading": "Please wait, still loading",
  "message.project_created": "Project created",
  "message.project_shared_successfully": "Project shared successfully",
  "message.removing_share": "Removing share",
  "message.results_loaded": "Results loaded",
  "message.saved": "Saved",
  "message.saving_completed": "Saving completed",
  "message.saving_data": "Saving data",
  "message.saving_item_in_project": "Saving item in project",
  "message.select_at_least_one_element": "Select at least one element",
  "message.share_removed": "Share removed",
  "message.still_loading_results_please_wait": "Still loading results, please wait",
  "message.switching_duty_mode": "Switching duty mode",
  "message.thermal_configuration_form_not_valid": "Thermal configuration form is not valid, please check",
  "message.unit_locked": "Unit locked",
  "message.unit_unlocked": "Unit unlocked",
  "message.unlocking_all_units": "Unlocking all unit...",
  "message.unlocking_all_units_error": "Error unlocking all unit",
  "message.unlocking_unit": "Unlocking unit...",
  "message.unlocking_unit_error": "Error unlocking unit",
  "message.updating_completed": "Updating completed",
  "message.updating_data": "Updating data",
  "message.updating_item_name": "Updating item name",
  "message.updating_the_project": "Updating the project",
  "message.uploading_error": "Uploading error",
  "message.you_have_new_item": "You have a new item!!!",
  "minFluidPressureDrop": "Min Fluid Pressure Drop",
  "minimumCapacityTolerance": "Min Capacity Tolerance",
  "missing results export information": "To view the data of all units, export the results in CSV format.",
  "missing results information warning": "The calculation returned {N} results but only {M} are displayed.",
  "modal confirm delete project": "Are you sure you want to delete the project?",
  "modal.subtitle.clear.item": "All your inputs will be deleted. Would you like to continue?",
  "modal.subtitle.delete.item": "Would you like to delete the item? You will lose all the work done till now",
  "modal.subtitle.delete.project": "Would you like to delete the project? You will lose all the work done till now",
  "modal.subtitle.duplicate.item": "Would you like to duplicate the item? A new item will be created with the same inputs",
  "modal.subtitle.duplicate.project": "Would you like to duplicate the project? A new project will be created with the same inputs",
  "modal.subtitle.import.item": "Import item into a project",
  "modal.subtitle.save.item": "Connect your item to a project",
  "modal.title.clear": "Clear item",
  "modal.title.delete": "Delete item",
  "modal.title.deleteProject": "Delete project",
  "modal.title.duplicate": "Duplicate item",
  "modal.title.duplicateProject": "Duplicate project",
  "modal.title.importItem": "Import item into a project",
  "modal.title.note": "Note",
  "modal.title.noteProject": "Note",
  "modal.title.save": "Save item into another project",
  "modalItemSaveAndFinish.customerReference": "Ref. customer",
  "modalItemSaveAndFinish.position": "Position",
  "modalItemSaveAndFinish.quantity": "Quantity",
  "modalPreviewTS.DISCOUNT": "DISCOUNT",
  "modalPreviewTS.NET VALUE": "NET VALUE",
  "modalPreviewTS.QUANTITY": "QUANTITY",
  "modalPreviewTS.QUANTITY x VALUE": "QUANTITY x VALUE",
  "modalPreviewTS.VERSION": "VERSION",
  "modalPreviewTS.customerReference": "RIF.CLIENTE",
  "modalPreviewTS.customizeTechnicalSpecification": "Customize Technical Specification",
  "modalPreviewTS.downloadTechnicalSpecification": "DOWNLOAD",
  "modalPreviewTS.name": "NAME",
  "modalPreviewTS.position": "POSITION",
  "modalPreviewTS.projectDiscount": "PROJECT DISCOUNT",
  "modalPreviewTS.totalItemNetValue": "TOTAL ITEM NET VALUE",
  "modalPreviewTS.totalProjectValue": "TOTAL PROJECT VALUE",
  "modalQuote.create.submitLabel": "Create quote",
  "modalQuote.create.title": "Create quote",
  "modalQuote.update.submitLabel": "Update quote",
  "modalQuote.update.title": "Update quote",
  "most sustainable green choice": "MOST SUSTAINABLE GREEN CHOICE",
  "my projects": "My projects",
  "name": "NAME",
  "new e-mail": "new e-mail",
  "new password": "new password",
  "new project": "New Project",
  "new tab": "(new tab)",
  "new-config-prefix": "New configuration",
  "new.item.title": "New Item",
  "new.password.placeholder": "Insert your new password here",
  "new.password.required": "Please input your new password!",
  "no confirmation": "Didn't receive confirmation istructions",
  "no doc available": "No documents available",
  "no results": "No result found,<br/> please check thermal configuration and press 'Calculate'",
  "no.other.logo": "No other logo in use",
  "noCustomPdf": "No custom PDF",
  "noValue": "-",
  "noValue.connectionBoxType": "Not selected",
  "noValue.tubePattern": "Not selected",
  "note": "Note",
  "note placeholder": "Type text here. Max 40 words.",
  "note subtitle": "Type your note below. Maximum lenght 40 words",
  "of": "of",
  "offerDate": "Offer date",
  "offerDiscount": "Offer discount",
  "offerNumber": "Offer N°",
  "ok": "Ok",
  "old password": "old password",
  "old.password.placeholder": "Insert your old password here",
  "old.password.required": "Please input your old password!",
  "open project": "Open Project",
  "open quote": "OPEN QUOTE",
  "option list": "Option List",
  "option price": "Option Price",
  "optionprice.cablingCover": "Cabling Cover",
  "optionprice.casingMaterial": "Casing Material",
  "optionprice.coilFrame": "Coil frame",
  "optionprice.connectionBox": "Connection box",
  "optionprice.connectionBoxType": "Connection Box",
  "optionprice.cover": "Cover",
  "optionprice.defrostSystem": "Defrost System",
  "optionprice.dripTray": "Drip tray",
  "optionprice.expansionTank": "Expansion Tank",
  "optionprice.fan": "Fan",
  "optionprice.feetType": "Feet type",
  "optionprice.finPack": "Fin pack",
  "optionprice.flanged": "Flanged",
  "optionprice.header": "Header",
  "optionprice.included": "Included",
  "optionprice.insulationType": "Insulated Drip Tray",
  "optionprice.kit": "Kit",
  "optionprice.kitForFan": "Kit for fan",
  "optionprice.packingType": "Packing",
  "optionprice.predispositionFeet": "Predisposition Feet",
  "optionprice.safetySwitchType": "Safety Switch",
  "optionprice.sprayWaterType": "Spray Water",
  "optionprice.switchboardType": "Switchboard",
  "optionprice.wiring": "Wiring",
  "optionprice.wiringConfigType": "Wiring Config Type",
  "or": "or",
  "other information": "Other Information",
  "otherLogo": "Other logo",
  "outdoor": "Outdoor",
  "output": "OUTPUT",
  "password": "password",
  "password empty": "Please insert your password",
  "password inconsistent": "The two passwords are inconsistent",
  "password too weak": "Password too weak, it must be greater than 4 characters",
  "password.invalid": "Please input your current password!",
  "password.placeholder": "Insert your current password here",
  "password.required": "Please input your password!",
  "percentage": "PERCENTAGE",
  "personal data": "Personal Data",
  "personal settings": "Personal settings",
  "placeholder.input.new.project": "Type your project title here",
  "placeholder.input.password": "Type your password here",
  "placeholder.input.passwordAgain": "Type your password again",
  "placeholder.input.search": "input search text",
  "placeholder.input.username": "me",
  "placeholder.inputNumber.ExamplePerc": "i.e. 10%",
  "placeholder.report.problem": "Type text here. Max 300 words.",
  "platformInfo.AirThinker": "AirThinker",
  "platformInfo.FrontEnd": "FrontEnd",
  "platformInfo.HSP": "HSP",
  "platformInfo.apiVersion": "Api",
  "platformInfo.backendVersion": "BackEnd",
  "position": "POSITION",
  "positionPlaceholder": "Position",
  "price": "PRICE",
  "priceListInfo": "The prices refer to the \"{name}\" price list of {date}.",
  "priceListInfoExpired": "The prices refer to the \"{name}\" price list of {date}. The new price list \"{new_name}\" is available from {expired}. Update prices pressing \"Calculate results\" button.",
  "price_not_loaded": "Price not loaded",
  "privacyPolicy": "Privacy Policy",
  "privacyPolicyClaim": "I have read and understood the {1}",
  "privacyPolicyUrl": "https://www.luvegroup.com/en/privacy/",
  "processing request": "Processing request, please wait..",
  "project details": "Project details",
  "project id": "PROJECT ID",
  "project settings": "Project settings",
  "project title": "PROJECT TITLE",
  "project title empty": "Project title can't be empty",
  "projects shared with me": "Projects shared with me",
  "quantity.maxQuantity": "Max. {max}",
  "quantity.minQuantity": "Min. {min}",
  "quantity.required": "A value is required",
  "quantityPlaceholder": "Quantity",
  "quick calculation": "Quick Calculation",
  "quote present": "Quote present",
  "rating": "Rating",
  "recent works": "Recent works",
  "reference": "Reference",
  "refresh page modal content": "You need to refresh the page. Please click the OK button",
  "refresh page modal title": "Refresh page warning.",
  "register": "Register",
  "registration": "Registration",
  "registration error": "Error with registration, please retry later",
  "registration ok": "Registration process completed successfully",
  "registration process ok": "Registration request processed, an e-mail was sent to you",
  "registration.email.placeholder": "johansson.erik@luvegroup.com",
  "registration.required.email": "Please input your email!",
  "registration.select.placeholder": "Select your country",
  "registration.select.required": "Please input your country!",
  "remember me": "Remember",
  "repeat operation": "Repeat the operation in View Profile page",
  "report a problem": "Report a problem",
  "report sent successfully": "Report has been sent successfully",
  "request_canceled_by_the_user": "Request canceled by the user",
  "resend": "Resend",
  "resend error": "Error with resend instructions request, please retry later",
  "resend instructions": "Resend instructions",
  "resend instructions ok": "Resend instructions request processed, an e-mail with the instructions to register was sent to you",
  "reset email error": "Error with reset e-mail request, please retry later",
  "reset error": "Error with reset password, please retry later",
  "reset ok with mail": "Reset password request processed, an e-mail was sent to you",
  "reset password": "Reset password",
  "results table view": "Results table view",
  "results-table.missing-options": "Some of the selected options aren't present in this unit",
  "results-table.note": "NOTE",
  "retry": "Retry",
  "save": "Save",
  "save and finish": "Save and finish",
  "save and finish modal desc": "Please, do you want to save the Unit: {unitId} as your best choice?",
  "save personal data": "Save personal data",
  "saved at": "saved at",
  "search existing project": "Search an existing project",
  "second name": "SECOND NAME",
  "select a project": "Select a project",
  "select-portfolio": "select product portfolio",
  "select.city.placeholder": "Select your city",
  "select.lang": "Select your language",
  "select.metric.sys": "Select your metric system",
  "select.project.title": "Type the title of an existing project",
  "select_application type": "Select application type",
  "send": "Send",
  "serviceUnavailableSubTitle": "The application is currently not available probably due to an update.<br>Please wait a few minutes or log in again later.<br>The application will restart automatically.",
  "serviceUnavailableTitle": "Service unavailable",
  "setToDefaults": "Set default values",
  "settings": "Settings",
  "settings.measurement system tip in user scope": "This setting applies only to new projects and not to existing projects. Modify the project configuration if you want to change the measurement system on the item.",
  "share": "share",
  "share item": "Share Item",
  "share project": "Share Project",
  "shared selected": "shared selected",
  "sharedBy": "Shared by",
  "short": "Short",
  "show sound power level": "SHOW SOUND POWER LEVEL",
  "showSoundPowerLevel": "SHOW SOUND POWER LEVEL",
  "soundLevelTolerance": "Sound Level Tolerance",
  "spare parts": "SPARE PARTS",
  "step 1": "STEP 1",
  "step 2": "STEP 2",
  "stepper.calculation_results": "Calculation results",
  "stepper.options": "Options",
  "stepper.thermal_configuration": "Thermal Configuration",
  "subject": "Subject",
  "summary table": "SUMMARY TABLE",
  "surname": "Surname",
  "technical specification": "TECHNICAL SPECIFICATION",
  "text.insert.note": "Insert your note here...",
  "textarea.required": "A text is mandatory",
  "thermalConfigurationSidebarTitle": "Thermal Configuration",
  "tooltip-add-note-item": "Add a note",
  "tooltip-clear-item": "Clear Item",
  "tooltip-delete-item": "Delete Item",
  "tooltip-duplicate-item": "Duplicate Item",
  "tooltip-new-item": "Create a new Item",
  "tooltip-save-item": "Save item to another project",
  "tooltip-share-item": "Share Item",
  "tooltip-view-note-item": "View note",
  "total": "Total",
  "total price": "Total price",
  "type.password.again.placeholder": "Type your password again here",
  "type.password.placeholder": "Type your password here",
  "unit price": "Unit Price",
  "unlock": "Unlock",
  "unlockAll": "Unlock all",
  "update quote": "UPDATE QUOTE",
  "upload another logo": "UPLOAD ANOTHER LOGO",
  "upload custom pdf": "UPLOAD CUSTOM PDF",
  "upload file type": "File Type: .doc, .docx, .pdf, .jpg, .png, .gif",
  "upload files": "Upload one or more file",
  "user exists": "User already exists in the system",
  "userNotFound": "User not found in the system",
  "username": "Username",
  "utilities": "Utilities",
  "validation.equal": "Must be equal to {1}",
  "validation.gt": "Must be greater than {1}",
  "validation.gte": "Must be at least greater than {1}",
  "validation.in": "Must be equal to one of the defined options",
  "validation.lt": "Must be lower than {1}",
  "validation.lte": "Must be a maximum of {1}",
  "validation.max": "Must be lower than {1}",
  "validation.max.exTempDifference": "Difference must be lower than {1}",
  "validation.min": "Must be greater than {1}",
  "validation.min.exTempDifference": "Difference must be greater than {1}",
  "validation.range": "Must be between {1} and {2}",
  "validation.range.evaporatingTemperature": "Evaporating temperature must be between {1} and {2}",
  "validation.regexp": "Must match {1}",
  "validation.required": "{1} is required",
  "validation.required.capacity": "{1} is required",
  "validation.requiredIfEmpty": "At least one of these fields is required",
  "verify email": "Verify email",
  "version length": "Version length",
  "versionPlaceholder": "Version",
  "vertical": "Vertical",
  "view": "View",
  "view details": "View Details",
  "view drawing": "View Drawing",
  "view partial results": "View partial results",
  "view profile": "View Profile",
  "view/change settings": "View/change settings",
  "wait for the download": "Wait for the download",
  "warning": "Warning",
  "zip code": "Zip Code",
  "zip.placeholder": "Insert your zip code here",
  "{n} units": "{n} units"
}