export const ITEM_EXP_ALL_TECH_SPEC = "item.export_all_technical_specifications";
export const ITEM_EXP_CSV_STANDARD_VERSION = "item.export_csv_standard_version";
export const ITEM_EXP_TECH_SPEC = "item.export_technical_specification";
export const ITEM_EXP_TECH_SPEC_WORD = "item.export_technical_specification_word";
export const ITEM_HIDE_EUROVENT_LOGO = "item.hide_eurovent_logo";
export const ITEM_SHOW_ADDITIONAL_DETAILS = "item.show_additional_details";
export const SETTINGS_RES_TABLE_CUSTOMIZE_FIELDS = "settings.results_table.customize_fields";
export const SETTINGS_TECH_SPEC_CUSTOMIZE_LOGO = "settings.technical_specification.customize_logo";
export const SETTINGS_TECH_SPEC_CUSTOMIZE_FIELDS = "settings.technical_specification.customize_fields";
export const SETTINGS_TECH_SPEC_DOWNLOAD_FULL_OFFER = "settings.technical_specification.download_full_offer";
export const SETTINGS_TECH_SPEC_SHOW_EXT_VERSION = "settings.technical_specification.show_extended_version";
export const SETTINGS_BOUNDS_ENABLE = "settings.bounds.enable";
export const USER_SHARE_ITEM = "user.share_item";
export const USER_SHARE_PROJECT = "user.share_project";
export const USER_SHOW_PRICES = "user.show_prices";
export const USER_SSO = "user.sso";
export const USER_SHOW_OPTIONS_PRICES = "user.show_options_prices";
export const USER_SHOW_DETAILED_OPTIONS_PRICES = "user.show_detailed_options_prices";
export const ITEM_QUOTE = "item.quote";


// NOT YET USED IN APP
// export const ITEM_EXP_CSV_DESIGN = "item.export_csv_design";
// export const ITEM_EXP_CSV_RATING = "item.export_csv_rating";
// export const ITEM_EXP_CSV_EUROVENT_VERSION = "item.export_csv_eurovent_version";

// not from remote, see store.capability for usage
export const ITEM_READONLY = "item.readonly";
export const PROJECT_READONLY = "project.readonly";

export const ITEM_DOWNLOAD_ALL_UNIT_DXF = "item.download_all_units_dxf";
export const ITEM_DOWNLOAD_SINGLE_UNIT_DXF = "item.download_single_unit_dxf";