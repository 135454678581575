import * as React from "react";
import { memo, SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    fill="none"
    {...props}
  >
    <rect width={20} height={20} fill="#000" rx={2} />
    <path
      fill="#fff"
      d="M10 4.59a.636.636 0 0 0-.37 1.156L7.675 8.482 4.239 7.338a.636.636 0 0 0-.153-.652.636.636 0 1 0-.312 1.07l1.135 5.108v2.545h10.182v-2.545l1.135-5.106a.636.636 0 1 0-.465-.42l-3.435 1.144-1.957-2.738A.635.635 0 0 0 10 4.591Zm0 2.827 1.289 1.805.547.766.89-.297 1.907-.636-.563 2.536H5.93l-.564-2.537 1.906.635.891.297.547-.764L10 7.417Zm-3.818 5.447h7.636v1.272H6.182v-1.272Z"
    />
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
