import { Col, Row } from "antd";
import React, { Fragment, ReactNode } from "react";
import { FormFieldWithValue } from "../../../../shared/store/configurator/types";
import { RenderOptions } from "../../containers/Field";

interface Props {
  fields: FormFieldWithValue[] | undefined;
  children: (
    field: FormFieldWithValue,
    renderOptions?: RenderOptions
  ) => ReactNode;
}

const Fields: React.FC<Props> = ({ fields, children }: Props) => {
  if (fields?.length) {
    return (
      <Fragment>
        {fields.map((item) => {
          if (item.fieldId.endsWith(".lt")) {
            const lt = item.fieldId;
            const ht = lt.replace(/\.lt$/, ".ht");
            const htItem = fields.find((item) => item.fieldId === ht);
            return (
              <Row key={lt} gutter={8}>
                <Col xs={htItem ? 12 : 24}>{children(item)}</Col>
                {htItem ? <Col xs={12}>{children(htItem)}</Col> : null}
              </Row>
            );
          } else if (item.enabledBy) {
            const fieldId = item.fieldId;
            const enablesField = fields.find(
              (item) => item.enables?.fieldId === fieldId
            );
            if (enablesField?.___isVisible)
              return children(item, {
                span: 8,
                leftField: children(enablesField),
                forceDisabled: item.enabledBy.when && enablesField.value === false || !item.enabledBy.when && enablesField.value === true,
              });
            else return children(item);
          } else if (item.fieldId.endsWith(".ht") || item.enables) {
            return null;
          } else return children(item);
        })}
      </Fragment>
    );
  }
  return null;
};

export default Fields;
