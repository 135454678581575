import * as React from "react";
import { memo, SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    fill="none"
    {...props}
  >
    <rect width={20} height={20} fill="#000" rx={2} />
    <path
      fill="#fff"
      d="m13.243 3-.398.082s-1.522.316-3.32 1.054c-1.798.739-3.92 1.887-5.151 3.73l-.01.015-.01.015c-.564.947-.646 1.858-.646 2.556 0 1.334.627 2.462 1.438 3.24a5 5 0 0 0 1.118.805 9.353 9.353 0 0 1-2.117 1.206L4.667 17c3.758-1.503 6.484-5.173 7.406-8.879l-1.357-.337c-.508 2.04-1.676 4.107-3.265 5.689a3.388 3.388 0 0 1-1.336-.788c-.587-.565-1.009-1.338-1.009-2.233 0-.6.052-1.157.445-1.826.958-1.421 2.84-2.513 4.505-3.197 1.446-.594 2.348-.775 2.658-.845 1.509 1.898 2.159 4.231 2.18 5.748.02 1.572-.386 2.71-.99 3.455-.603.742-1.406 1.124-2.336 1.163-.416-.005-.89.014-1.58-.516l-.852 1.109c1.022.785 2.06.802 2.44.808h.038a4.54 4.54 0 0 0 3.375-1.683c.85-1.048 1.327-2.53 1.302-4.354-.026-1.971-.795-4.756-2.779-7.01L13.243 3Z"
    />
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
