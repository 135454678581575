import * as React from "react";
import { memo, SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    fill="none"
    {...props}
  >
    <rect width={20} height={20} fill="#000" rx={2} />
    <path
      fill="#fff"
      d="M4.556 3C3.706 3 3 3.705 3 4.556v10.888C3 16.294 3.705 17 4.556 17h10.888c.85 0 1.556-.705 1.556-1.556V4.556C17 3.706 16.295 3 15.444 3H4.556Zm0 1.556h10.888v10.888H4.556V4.556ZM7.278 6.11a1.167 1.167 0 1 0 0 2.333 1.167 1.167 0 0 0 0-2.333Zm5.283.228L6.34 12.561l1.1 1.1 6.222-6.222-1.1-1.1Zm.161 5.217a1.167 1.167 0 1 0 0 2.333 1.167 1.167 0 0 0 0-2.333Z"
    />
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
