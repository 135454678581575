import { Button } from "antd";
import classNames from "classnames";
import { BaseField, ResultsTableSettings } from "generated/axios/api";
import { get } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { EqualHeightsElement } from "../../../../shared/components/EqualHeights";
import { ItemResultExt } from "../../../../shared/store/item/types";
import { Any } from "../../../../types/Any";
import CellNote from "./CellNote";
import Cells from "./Cells";
import { getBulletColorCssClass } from "./lib";
import { StickyDiv } from "./StickyDiv";
import TableCellButtonsList from "./TableCellButtonsList";
import TableCellTechButtons from "./TableCellTechButtons";
import TableCellUnit from "./TableCellUnit";
import "./tableColumn.scss";

interface Props {
  handleLockingClick: (lock: boolean, unitId?: string) => void;
  handleRatingButtonClick: (unitId?: string) => void;
  handleViewButtonClick: (unitId?: string) => void;
  printPdf: (unitId?: string) => void;
  index: number;
  itemResult: ItemResultExt;
  isSelected: boolean;
  isSticky: boolean;
  resultFieldConfig: BaseField[]; // API /results/field-config --> all
  orderField?: string;
  orderAsSettings: NonNullable<ResultsTableSettings["fields"]>;
  lastLocked: boolean;
  waitingPrices?: boolean;
}

export default class TableColumn extends React.Component<Props> {
  handleViewButtonClick = (): void => {
    this.props.handleViewButtonClick(this.props.itemResult.unitId);
  };

  /**
   *  resultFieldConfig is the Header array, already filtered with just the items have to be shown
   *  looping over each of its item and try to find the corresponding value for the n-th fieldId
   *  so automatically even the results will be filtered as well
   */
  getData = (): Record<string, Any> => {
    const calculationValues = get(this.props, "itemResult.calculationValues");
    if (!calculationValues) {
      return {};
    }
    const { resultFieldConfig } = this.props;
    const x = {};
    resultFieldConfig.forEach((el) => {
      // sometimes the resultFieldConfig comes with undefined elements inside the array
      if (!el) return;
      const fieldId = el.fieldId;
      if (calculationValues[fieldId]) {
        x[fieldId] = calculationValues[fieldId];
      } else {
        x[fieldId] = null;
      }
    });
    return x;
  };

  hasWarnings = () => {
    const { coreCalculationMessages, optionsData } = this.props.itemResult;
    return Boolean(
      (optionsData?.missingOptions && optionsData.missingOptions.length > 0) ||
        (coreCalculationMessages && coreCalculationMessages.length > 0)
    );
  };

  render(): React.ReactNode {
    const {
      index,
      isSelected,
      isSticky,
      itemResult,
      orderAsSettings,
      orderField,
      printPdf,
      waitingPrices,
    } = this.props;
    const {
      bestChoice,
      bestRatio,
      mostSustainable,
      coreCalculationMessages,
      drawingUrl,
      dxfDrawingUrl,
      unitId,
      unitName,
    } = itemResult;

    const isWarning = this.hasWarnings();

    // prop to be filled in case of 'BEST CHOICE' / 'BEST RATIO' --> es bulletColor: "red" (default: "" --> light-blue)
    const bulletColorCssClass = getBulletColorCssClass(itemResult);

    return (
      <div
        className={classNames("table__column-container", {
          "lock-changing-unit": itemResult.lockChanging,
          "lock-changed-unit": itemResult.lockChanged,
          "last-locked-unit": this.props.lastLocked,
        })}
      >
        <div
          className={classNames("table__column", {
            "selected-unit": isSelected,
          })}
        >
          {/* Column header */}
          <div className="table__column">
            <StickyDiv
              isSticky={isSticky}
              index={index}
              className={"table__column--affix"}
            >
              <EqualHeightsElement group={"__table_headers__"}>
                <TableCellUnit
                  bestChoice={bestChoice}
                  bestRatio={bestRatio}
                  mostSustainable={mostSustainable}
                  bulletColorCssClass={bulletColorCssClass}
                  className={"cell-unit__vertical"}
                  isSelected={isSelected}
                  unitName={unitName}
                />
              </EqualHeightsElement>
            </StickyDiv>

            <TableCellButtonsList
              className={"vertical"}
              handleLock={this.props.handleLockingClick}
              handleRate={this.props.handleRatingButtonClick}
              handleView={this.props.handleViewButtonClick}
              lockChanging={itemResult.lockChanging}
              locked={itemResult.locked}
              unitId={itemResult.unitId}
            />
          </div>

          {/* Image */}
          <div className={`table-cell table-cell__no-padding`}>
            <CellNote
              isWarning={isWarning}
              messages={coreCalculationMessages}
              whisperer={itemResult.calculationValues?.whisperer}
            />
          </div>

          {/* Values */}
          <Cells
            data={this.getData()}
            missingOptionsMap={itemResult.missingOptionsMap}
            orderAsSettings={orderAsSettings}
            orderField={orderField}
            waitingPrices={waitingPrices}
          />

          {/* Attachments */}
          <TableCellTechButtons
            className={"download-cell-buttons vertical"}
            drawingUrl={drawingUrl}
            dxfDrawingUrl={dxfDrawingUrl}
            printPdf={printPdf}
            unitId={unitId}
          />

          {/* Footer */}
          <div className="table-cell table-cell__button">
            {/* View button */}
            <Button type="primary" ghost onClick={this.handleViewButtonClick}>
              <FormattedMessage id="view" defaultMessage="View" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
