import { Checkbox, Form } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import React from "react";
import { useSelector } from "react-redux";
import {
  ApplicationType,
  TechnicalSpecificationConfig,
} from "../../../../generated/axios";
import { applicationTypeSelector } from "../../../../shared/store/applicationType/selectors";

interface Props {
  excludedFields?: TechnicalSpecificationConfig["excludedFields"];
  getFieldDecorator: WrappedFormUtils["getFieldDecorator"];
}

export function HiddenFields({
  excludedFields,
  getFieldDecorator,
}: Readonly<Props>) {
  const applicationTypes = useSelector(applicationTypeSelector);

  return (
    <>
      {Object.keys(applicationTypes).map(
        (group) =>
          applicationTypes[group]?.map((applicationType: ApplicationType) => (
            <Form.Item
              key={applicationType.id}
              style={{ visibility: "hidden", margin: 0, height: 0 }}
            >
              {/* Use of "at" prefix is for avoiding that antd consider the field as an array */}
              {getFieldDecorator(`excludedFields.at${applicationType.id}`, {
                initialValue: excludedFields?.[String(applicationType.id)],
              })(<Checkbox.Group />)}
            </Form.Item>
          ))
      )}
    </>
  );
}
