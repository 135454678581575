import { Icon } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Callback } from "../../../../types/Callback";
import { CapabilityCheck, capabilityMap } from "../../../capability";
import OpenTechSpecButton from "../ItemDetails/OpenTechSpecButton";

interface IProps {
  drawingsUrl?: string;
  dxfDrawingsUrl?: string;
  exportCsvHandler: Callback;
  openSettingsModal: (sectionsToView: string[]) => void;
  printAllPdf: Callback;
}

export const TableResultsFooter: React.FC<IProps> = ({
  drawingsUrl,
  dxfDrawingsUrl,
  exportCsvHandler,
  openSettingsModal,
  printAllPdf,
}) => {
  return (
    <div className="table-results-footer">
      <div className="table-results-footer-left-container">
        <CapabilityCheck showIfHas={capabilityMap.ITEM_EXP_ALL_TECH_SPEC}>
          <button className="table-results-footer-button" onClick={printAllPdf}>
            <Icon
              type="download"
              theme="outlined"
              className="icon--blue icon--pointer margin-left--0"
            />
            <span>
              <FormattedMessage
                id="download all"
                defaultMessage="Download all"
              />{" "}
              <FormattedMessage
                id="technical specification"
                defaultMessage="technical specification"
              />
            </span>
          </button>
        </CapabilityCheck>

        <CapabilityCheck
          showIfHas={capabilityMap.ITEM_EXP_CSV_STANDARD_VERSION}
        >
          <button
            className="table-results-footer-button"
            onClick={exportCsvHandler}
          >
            <Icon type="download" theme="outlined" className="icon--blue" />
            <FormattedMessage
              id="export csv"
              defaultMessage="Export results in CSV"
            />
          </button>
        </CapabilityCheck>

        {drawingsUrl && (
          <a
            href={drawingsUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="table-results-footer-button"
          >
            <Icon type="download" theme="outlined" className="icon--blue" />
            <FormattedMessage
              id="export all drawings"
              defaultMessage="Export all drawings"
            />
          </a>
        )}

        {dxfDrawingsUrl && (
          <CapabilityCheck showIfHas={capabilityMap.ITEM_DOWNLOAD_ALL_UNIT_DXF}>
            <a
              href={dxfDrawingsUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="table-results-footer-button"
            >
              <Icon type="download" theme="outlined" className="icon--blue" />
              <FormattedMessage
                id="export all dxf"
                defaultMessage="Export all DXF"
              />
            </a>
          </CapabilityCheck>
        )}
      </div>

      <CapabilityCheck showIfHasNot={capabilityMap.ITEM_READONLY}>
        <OpenTechSpecButton openSettingsModal={openSettingsModal} />
      </CapabilityCheck>
    </div>
  );
};
